import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule, USE_EMULATOR as AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { AngularFirestoreModule, USE_EMULATOR as FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';

import { environment } from '../../environments/environment';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase.options),
    AngularFirestoreModule,
    AngularFireAuthModule,
  ],
  providers: [
    {
      provide: AUTH_EMULATOR,
      useValue: environment.firebase.useEmulator ? ['http://localhost:8084'] : undefined,
    },
    {
      provide: FIRESTORE_EMULATOR,
      useValue: environment.firebase.useEmulator ? ['localhost', 8083] : undefined,
    },
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: false }) },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
})
export class CoreModule {}
