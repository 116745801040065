import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { GraphQLModule } from './graphql.module';
import { MainWindowComponent } from './main-window/main-window.component';
import { UIModule } from './ui/ui.module';
import { VideoContainerModule } from './video-container/video-container.module';

@NgModule({
  declarations: [AppComponent, MainWindowComponent],
  imports: [AppRoutingModule, BrowserModule, CoreModule, GraphQLModule, UIModule, VideoContainerModule],
  providers: [Title],
  bootstrap: [AppComponent],
})
export class AppModule {}
