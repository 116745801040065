import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AppointmentCommon": [
      "Appointment"
    ],
    "AppointmentTypeCommon": [
      "AppointmentType"
    ],
    "Assignee": [
      "InternalUser",
      "RoleType"
    ],
    "CareTeamMember": [
      "InternalUser"
    ],
    "CareTeamReason": [
      "Appointment",
      "Panel",
      "ProgramEnrollment",
      "ProgramEnrollmentCareTeam"
    ],
    "MassAppointmentChangeRequest": [
      "MassAppointmentCancellationRequest"
    ],
    "MassAppointmentChangeRequestItem": [
      "MassAppointmentCancellationRequestItem"
    ],
    "MeasurementValidationRule": [
      "FloatMeasurementValidationRule",
      "IntegerMeasurementValidationRule"
    ],
    "MessageRecipient": [
      "InternalUser",
      "Patient",
      "RoleType"
    ],
    "MessageSender": [
      "InternalUser",
      "Patient"
    ],
    "OriginatingObject": [
      "Appointment",
      "TimelineComment",
      "TimelinePost"
    ],
    "OutreachManagerBatch": [
      "LabOrderOutreach",
      "SurveyOrderOutreach",
      "UserEngagementMessageOutreach",
      "VisitFollowUpOrderOutreach"
    ],
    "Plan": [
      "AmazonPlan",
      "B2bPlan",
      "GuestPlan",
      "StripePlan"
    ],
    "PrioritizedTaskLocation": [
      "LicensingBody",
      "ServiceArea"
    ],
    "ProblemAttachableInterface": [
      "Document"
    ],
    "ProcedureOrigin": [
      "ProcedureInteraction",
      "ProcedureOrder"
    ],
    "Remindable": [
      "Appointment",
      "TimelineComment",
      "TimelinePost"
    ],
    "SearchResult": [
      "BookableProviderSearchResult",
      "ContactSearchResult",
      "LabOrderTypeResult",
      "PatientSearchResult",
      "ProblemTypeResult"
    ],
    "TaskAssociation": [
      "Document",
      "Insurance",
      "LabOrder",
      "Note",
      "ProcedureOrder",
      "TimelinePost",
      "VoluntaryAlignmentOffer"
    ],
    "TemplatedResponse": [
      "ErrorResponse",
      "Response"
    ]
  }
};
      export default result;
    
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  HTML: any;
  JSON: any;
  Markdown: any;
  /** Timezone formatted per the IANA Time Zone database (https://en.wikipedia.org/wiki/List_of_tz_database_time_zones) */
  TZInfo: any;
  URL: any;
};

/** Autogenerated input type of AcquireDocumentLock */
export type AcquireDocumentLockInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  documentId: Scalars['ID'];
};

/** Autogenerated return type of AcquireDocumentLock. */
export type AcquireDocumentLockPayload = {
  __typename?: 'AcquireDocumentLockPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Document locked */
  document?: Maybe<Document>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

/** Active pharmacy coverage details for a patient */
export type ActivePharmacyCoverageDetails = {
  __typename?: 'ActivePharmacyCoverageDetails';
  alternativesId?: Maybe<Scalars['String']>;
  copayNumber?: Maybe<Scalars['String']>;
  coverageId?: Maybe<Scalars['String']>;
  coverageStatus: Scalars['String'];
  coverageType: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  demographicDifferences?: Maybe<DemographicDifferencesResult>;
  entityIdentifierCode?: Maybe<Scalars['String']>;
  formularyNumber: Scalars['String'];
  groupName?: Maybe<Scalars['String']>;
  groupNumber: Scalars['String'];
  healthPlanName: Scalars['String'];
  id: Scalars['ID'];
  iin: Scalars['String'];
  insuranceTypeCode?: Maybe<Scalars['String']>;
  interchangeControlNumber?: Maybe<Scalars['String']>;
  monetaryAmount?: Maybe<Scalars['String']>;
  payerName: Scalars['String'];
  pcn: Scalars['String'];
  planNumber: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** Autogenerated input type of AddExternalDocumentToChart */
export type AddExternalDocumentToChartInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  type: Scalars['String'];
};

/** Autogenerated return type of AddExternalDocumentToChart. */
export type AddExternalDocumentToChartPayload = {
  __typename?: 'AddExternalDocumentToChartPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** @deprecated Use `mutationErrors` instead */
  errors?: Maybe<Scalars['JSON']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  note?: Maybe<Note>;
  success: Scalars['Boolean'];
};

export type Address = {
  __typename?: 'Address';
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  crossStreet?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  cross_street?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isPreferred: Scalars['Boolean'];
  kind: AddressKind;
  latitude?: Maybe<Scalars['Float']>;
  location?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<AddressStatus>;
  zip?: Maybe<Scalars['String']>;
};

export type AddressAttributes = {
  _destroy?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  crossStreet?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  kind?: InputMaybe<AddressKind>;
  stateCode?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export enum AddressKind {
  Billing = 'billing',
  Home = 'home',
  Other = 'other',
  Work = 'work'
}

export enum AddressStatus {
  Current = 'CURRENT',
  Previous = 'PREVIOUS'
}

export type Admission = {
  __typename?: 'Admission';
  admitReason: Scalars['String'];
  admittedAt?: Maybe<Scalars['Date']>;
  author?: Maybe<Scalars['String']>;
  dischargeType?: Maybe<AdmissionDischargeType>;
  dischargedAt?: Maybe<Scalars['Date']>;
  facilityLocation: Scalars['String'];
  facilityType: AdmissionFacilityType;
  hasDischargeSummary: Scalars['Boolean'];
  id: Scalars['ID'];
  monitoring: AdmissionMonitoring;
  nextCheckInDate: Scalars['Date'];
  nextSteps?: Maybe<Scalars['String']>;
  patient: Patient;
  readmissionRisk?: Maybe<ReadmissionRisk>;
  status: AdmissionStatus;
  story?: Maybe<Scalars['HTML']>;
  subject?: Maybe<Scalars['String']>;
  type: AdmissionType;
};

export enum AdmissionDischargeType {
  AssistedLivingFacility = 'ASSISTED_LIVING_FACILITY',
  Deceased = 'DECEASED',
  Home = 'HOME',
  InpatientHospitalization = 'INPATIENT_HOSPITALIZATION',
  Other = 'OTHER',
  SkilledNursingFacility = 'SKILLED_NURSING_FACILITY'
}

export enum AdmissionFacilityType {
  EmergencyRoom = 'EMERGENCY_ROOM',
  InpatientHospitalization = 'INPATIENT_HOSPITALIZATION',
  Observation = 'OBSERVATION',
  Other = 'OTHER',
  SkilledNursingFacility = 'SKILLED_NURSING_FACILITY'
}

export enum AdmissionMonitoring {
  Active = 'ACTIVE',
  Passive = 'PASSIVE'
}

export type AdmissionSearchEdge = {
  __typename?: 'AdmissionSearchEdge';
  cursor: Scalars['String'];
  node?: Maybe<Admission>;
};

export type AdmissionSearchPage = {
  __typename?: 'AdmissionSearchPage';
  edges: Array<AdmissionSearchEdge>;
  nodes: Array<Admission>;
  pageInfo: PageInfo;
};

export type AdmissionSearchQuery = {
  facilityType?: InputMaybe<Array<AdmissionFacilityType>>;
  monitoring?: InputMaybe<AdmissionMonitoring>;
  patientIds?: InputMaybe<Array<Scalars['ID']>>;
  providerIds?: InputMaybe<Array<Scalars['ID']>>;
  query?: InputMaybe<Scalars['String']>;
  readmissionRisk?: InputMaybe<Array<ReadmissionRisk>>;
  serviceAreaIds?: InputMaybe<Array<Scalars['ID']>>;
  sortBy?: InputMaybe<AdmissionsSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  status?: InputMaybe<AdmissionStatus>;
};

export enum AdmissionStatus {
  Cancelled = 'CANCELLED',
  Current = 'CURRENT',
  Discharged = 'DISCHARGED',
  Pending = 'PENDING'
}

export enum AdmissionType {
  Planned = 'PLANNED',
  Unplanned = 'UNPLANNED'
}

export enum AdmissionsSortBy {
  AdmittedAt = 'ADMITTED_AT',
  DischargedAt = 'DISCHARGED_AT',
  NextCheckInDate = 'NEXT_CHECK_IN_DATE',
  Patient = 'PATIENT'
}

export type AmazonPlan = Plan & {
  __typename?: 'AmazonPlan';
  id: Scalars['ID'];
  kind: Scalars['String'];
  name: Scalars['String'];
};

/** Autogenerated input type of ApplyTemplateAppointmentInventory */
export type ApplyTemplateAppointmentInventoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  providerId?: InputMaybe<Scalars['Int']>;
  startTime: Scalars['DateTime'];
  templateVariantId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of ApplyTemplateAppointmentInventory. */
export type ApplyTemplateAppointmentInventoryPayload = {
  __typename?: 'ApplyTemplateAppointmentInventoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  inventories?: Maybe<Array<AppointmentInventory>>;
  success: Scalars['Boolean'];
};

export type Appointment = AppointmentCommon & Remindable & {
  __typename?: 'Appointment';
  /** The kind of appointment */
  appointmentType: AppointmentType;
  /** The user who approved the appointment */
  approvedBy?: Maybe<InternalUser>;
  /** The time at which the patient arrived for the appointment */
  arrivedAt?: Maybe<Scalars['DateTime']>;
  /** The time at which the appointment was cancelled */
  cancelledAt?: Maybe<Scalars['DateTime']>;
  /** The zero charge details associated with this appointment */
  chargeExemptionDetails?: Maybe<ChargeExemptionDetails>;
  /** Comments about the appointment */
  comments?: Maybe<Scalars['String']>;
  /** The date time when the patient confirmed the appointment on their mobile phone */
  confirmedAt?: Maybe<Scalars['DateTime']>;
  /** The time at which the appointment record was created */
  createdAt: Scalars['DateTime'];
  /** The time at which the patient departed the office */
  departedAt?: Maybe<Scalars['DateTime']>;
  /** The duration of the appointment in minutes */
  duration?: Maybe<Scalars['Int']>;
  /** The time at which the appointment is scheduled to conclude */
  endAt: Scalars['DateTime'];
  /** id of appointment object */
  id: Scalars['ID'];
  /** If the visit charges have been reviewed */
  isBillingReviewed: Scalars['Boolean'];
  /** If the appointment timezone does not match the patient timezone */
  isDifferentOnsiteTimezone: Scalars['Boolean'];
  /** Whether or not the appointment type is a remote kind */
  isRemote: Scalars['Boolean'];
  /** The URL for the video visit join page */
  joinLink?: Maybe<Scalars['String']>;
  /** The most recent covid lab order if any associated with this appointment */
  mostRecentOpenCovidLabOrder?: Maybe<LabOrder>;
  /**
   * name for Remindable interface
   * @deprecated Field is redundant, use reminder.type instead
   */
  name: Scalars['String'];
  /** start of appointment time */
  notifiableStatus?: Maybe<Scalars['String']>;
  /**
   * start of appointment time
   * @deprecated Please use the camelCase version.
   */
  notifiable_status?: Maybe<Scalars['String']>;
  /** title for Remindable object */
  notificationSubtitle?: Maybe<Scalars['String']>;
  /** title for Remindable object */
  notificationTitle: Scalars['String'];
  /**
   * title for Remindable object
   * @deprecated Please use the camelCase version.
   */
  notification_subtitle?: Maybe<Scalars['String']>;
  /**
   * title for Remindable object
   * @deprecated Please use the camelCase version.
   */
  notification_title: Scalars['String'];
  office?: Maybe<Office>;
  openPracticeMessages: Array<OpenPracticeMessage>;
  patient?: Maybe<Patient>;
  /** The date time when the patient notified us they would be late for their appointment */
  patientRunningLateAt?: Maybe<Scalars['DateTime']>;
  /** A list of lab orders associated with the patient of this appointment that a phlebs can close in a quick visit. */
  phlebQuickVisitLabOrders?: Maybe<Array<LabOrder>>;
  /** A program enrollment associated with this appointment */
  programEnrollment?: Maybe<ProgramEnrollment>;
  provider?: Maybe<Provider>;
  /** The patient-reported reason for the appointment */
  reason: Scalars['String'];
  recording?: Maybe<AppointmentRecording>;
  /** Long-form description of this object */
  reminderDescription?: Maybe<Scalars['String']>;
  /** The id for the remote video visit */
  remoteMeetingId?: Maybe<Scalars['ID']>;
  /**
   * The URL for the remote video visit
   * @deprecated This field only provides the direct Zoom link. Use join_link instead, which provides the in-app remote visit URL or the Zoom link depending on the feature flag's setting.
   */
  remoteMeetingLink?: Maybe<Scalars['String']>;
  /** The time at which the provider saw the patient */
  seenAt?: Maybe<Scalars['DateTime']>;
  /** Whether or not we should send SMS appointment reminders */
  sendSms?: Maybe<Scalars['Boolean']>;
  /** The service ticket associated with this appointment */
  serviceTicket?: Maybe<ServiceTicket>;
  /** The ID of the Service Ticket for a given appointment. */
  serviceTicketId?: Maybe<Scalars['Int']>;
  /** The number to which we will send SMS appointment reminders */
  smsNumber?: Maybe<Scalars['String']>;
  /** weight for appointment notifications */
  sortWeight: Scalars['Int'];
  /**
   * weight for appointment notifications
   * @deprecated Please use the camelCase version.
   */
  sort_weight: Scalars['Int'];
  /** The time at which the appointment is scheduled to start */
  startAt: Scalars['DateTime'];
  /** The state of the appointment (created, booked, confirmed, arrived, started, cancelled, seen, or no_show) */
  state?: Maybe<Scalars['String']>;
  /** The local timezone in which the appointment will take place */
  timezone?: Maybe<Scalars['String']>;
  /** The local IANA-formatted timezone in which the appointment will take place */
  timezoneTzinfo?: Maybe<Scalars['TZInfo']>;
  /** Google Maps link with directions to the office. Null if the visit is remote */
  travelDirectionsLink?: Maybe<Scalars['String']>;
  /** description for Remindable interface */
  universalLink: Scalars['String'];
  /**
   * description for Remindable interface
   * @deprecated Please use the camelCase version.
   */
  universal_link: Scalars['String'];
  /** The time at which the appointment record was last updated */
  updatedAt: Scalars['DateTime'];
  /** The visit summary for the appointment */
  visitSummary?: Maybe<Summary>;
  /** The visit summary for the appointment */
  visitSummaryId?: Maybe<Scalars['ID']>;
};

export type AppointmentCancellationReason = {
  __typename?: 'AppointmentCancellationReason';
  displayReason?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type AppointmentCommon = {
  /** id of appointment object */
  id: Scalars['ID'];
};

/** The connection type for Appointment. */
export type AppointmentConnection = {
  __typename?: 'AppointmentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AppointmentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Appointment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of objects in query result */
  totalCount: Scalars['Int'];
  /** Total number of pages based on 'first' argument or max_page_size */
  totalPageCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AppointmentEdge = {
  __typename?: 'AppointmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Appointment>;
};

export type AppointmentInventory = {
  __typename?: 'AppointmentInventory';
  /** Whether this is a blocked appointment slot */
  blocked: Scalars['Boolean'];
  bookedAppointments?: Maybe<AppointmentConnection>;
  cancelledAppointments?: Maybe<AppointmentConnection>;
  /** How many people can book this inventory */
  capacity: Scalars['Int'];
  /** A comments about why the appointment slot is blocked */
  comments: Scalars['String'];
  /** How long the appointment will last in seconds */
  duration: Scalars['Int'];
  /** The time the appointment will end */
  endAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** A description of the appointment slot */
  label?: Maybe<Scalars['String']>;
  /** Licensing bodies where the inventory is available (e.g. for cross-region booking) */
  licensingBodies: Array<LicensingBody>;
  numBookedAppointments: Scalars['Int'];
  office: AppointmentInventorySearchResultsOffice;
  officeId: Scalars['Int'];
  /** Whether this is an appointment slot reserved for patients in the provider's panel */
  preferredBooking: Scalars['Boolean'];
  provider: AppointmentInventoryProviderSearchResultsProvider;
  providerId: Scalars['Int'];
  /** The URL for the remote video visit */
  remoteMeetingLink?: Maybe<Scalars['String']>;
  /** Whether this is a reserved appointment slot */
  reserved: Scalars['Boolean'];
  /** Whether this is a restricted appointment slot */
  restricted: Scalars['Boolean'];
  restrictedAppointmentTypes?: Maybe<Array<Scalars['String']>>;
  /** The time the appointment will start */
  startAt: Scalars['DateTime'];
};


export type AppointmentInventoryBookedAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type AppointmentInventoryCancelledAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type AppointmentInventoryBlock = {
  __typename?: 'AppointmentInventoryBlock';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** The connection type for AppointmentInventory. */
export type AppointmentInventoryConnection = {
  __typename?: 'AppointmentInventoryConnection';
  /** A list of edges. */
  edges: Array<AppointmentInventoryEdge>;
  /** A list of nodes. */
  nodes: Array<AppointmentInventory>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AppointmentInventoryEdge = {
  __typename?: 'AppointmentInventoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AppointmentInventory>;
};

export type AppointmentInventoryOfficeSearchResults = {
  __typename?: 'AppointmentInventoryOfficeSearchResults';
  office: Office;
  providers: Array<AppointmentInventoryProviderSearchResults>;
};

export type AppointmentInventoryProviderSearchResults = {
  __typename?: 'AppointmentInventoryProviderSearchResults';
  appointmentInventories: Array<AppointmentInventorySearchResultsDay>;
  provider: AppointmentInventoryProviderSearchResultsProvider;
};

export type AppointmentInventoryProviderSearchResultsProvider = {
  __typename?: 'AppointmentInventoryProviderSearchResultsProvider';
  displayName: Scalars['String'];
  excludeClosedPanels: Scalars['Boolean'];
  id: Scalars['ID'];
  preferredRole?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
};

export type AppointmentInventorySearchResults = {
  __typename?: 'AppointmentInventorySearchResults';
  count: Scalars['Int'];
  inventories?: Maybe<Array<AppointmentInventory>>;
  offices?: Maybe<Array<AppointmentInventoryOfficeSearchResults>>;
  serviceArea: ServiceArea;
};

export type AppointmentInventorySearchResultsDay = {
  __typename?: 'AppointmentInventorySearchResultsDay';
  appointmentInventories: Array<AppointmentInventory>;
  date: Scalars['Date'];
};

export type AppointmentInventorySearchResultsOffice = {
  __typename?: 'AppointmentInventorySearchResultsOffice';
  address?: Maybe<Address>;
  b2bCompanyId?: Maybe<Scalars['ID']>;
  b2bCompanyIds?: Maybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
  mapLink?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
};

export enum AppointmentInventoryView {
  /** Results in chronological order */
  Day = 'DAY',
  /** Results aggregated by office and provider */
  Week = 'WEEK'
}

export type AppointmentLength = {
  __typename?: 'AppointmentLength';
  id: Scalars['ID'];
  length: Scalars['Int'];
};

/** various queryable relationships between patient and appointment */
export enum AppointmentQueryStrategy {
  ConnectedDevice = 'CONNECTED_DEVICE',
  /** For an appointment to be current, it must not have the state `no_show` or `cancelled`. The `end_at` must be in the future. It must _either_ have the `start_at` in the past _or_ be in the state `arrived` or `started`. */
  Current = 'CURRENT',
  /** Only includes appointments with state 'no show' or 'cancelled' */
  NotUtilized = 'NOT_UTILIZED',
  Past = 'PAST',
  /** appointments with a state of seen */
  Seen = 'SEEN',
  Upcoming = 'UPCOMING'
}

export type AppointmentRecording = {
  __typename?: 'AppointmentRecording';
  appointment?: Maybe<Appointment>;
  appointmentRecordingFeedback: Array<AppointmentRecordingFeedback>;
  createdAt: Scalars['DateTime'];
  detailedLaunchFeedback?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  internalUser?: Maybe<InternalUser>;
  label?: Maybe<Scalars['String']>;
  /** pre-signed URL to post binary audio to. will fail if recording is already complete. */
  presignedUploadUrl?: Maybe<Scalars['URL']>;
  state: AppointmentRecordingState;
  summaryForSection?: Maybe<AppointmentRecordingSummary>;
  transcript: Array<TranscriptSegment>;
};


export type AppointmentRecordingSummaryForSectionArgs = {
  name: SummarySection;
};

/** The connection type for AppointmentRecording. */
export type AppointmentRecordingConnection = {
  __typename?: 'AppointmentRecordingConnection';
  /** A list of edges. */
  edges: Array<AppointmentRecordingEdge>;
  /** A list of nodes. */
  nodes: Array<AppointmentRecording>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AppointmentRecordingEdge = {
  __typename?: 'AppointmentRecordingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AppointmentRecording>;
};

export type AppointmentRecordingFeedback = {
  __typename?: 'AppointmentRecordingFeedback';
  rating: Scalars['Int'];
  summarySection: SummarySection;
};

export type AppointmentRecordingFeedbackInput = {
  lineId?: InputMaybe<Scalars['ID']>;
  rating: Scalars['Int'];
  summarySection: SummarySection;
};

export enum AppointmentRecordingState {
  Archived = 'archived',
  ReadyToRecord = 'ready_to_record',
  Recording = 'recording',
  Transcribed = 'transcribed',
  Transcribing = 'transcribing',
  TranscribingFailed = 'transcribing_failed'
}

export type AppointmentRecordingSummary = {
  __typename?: 'AppointmentRecordingSummary';
  lines: Array<SummaryLine>;
  name: SummarySection;
  rating?: Maybe<Scalars['Int']>;
};

/** How appointments in a series relate to each other */
export type AppointmentSeries = {
  __typename?: 'AppointmentSeries';
  /** the status of the series */
  active: Scalars['Boolean'];
  /** the type and order of appointments in series */
  appointmentTypes?: Maybe<AppointmentTypeConnection>;
  /** the id of the series */
  id: Scalars['ID'];
  /** the default number of weeks between appointments in series */
  interval: Scalars['Int'];
  /** the number of appointments in the series */
  length: Scalars['Int'];
  /** the name of the series */
  name: Scalars['String'];
};


/** How appointments in a series relate to each other */
export type AppointmentSeriesAppointmentTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type AppointmentType = AppointmentTypeCommon & {
  __typename?: 'AppointmentType';
  appointmentSeries?: Maybe<AppointmentSeries>;
  /** Used to display insurance capture during the appointment booking flow */
  billable: Scalars['Boolean'];
  /** The color associated with this appointment type */
  color: Scalars['String'];
  /** A description of this appointment type */
  description?: Maybe<Scalars['String']>;
  /** The name of the appointment type */
  displayName?: Maybe<Scalars['String']>;
  /** Whether this appointment type should display provider bios */
  displayProviderBios: Scalars['Boolean'];
  /** Length of appointment */
  displayedDuration?: Maybe<Scalars['Int']>;
  firstVisitAppointmentType?: Maybe<AppointmentType>;
  /** id of the AppointmentType object */
  id: Scalars['ID'];
  /** Whether this is a brief appointment */
  isBrief: Scalars['Boolean'];
  /** Whether this appointment is for new patients */
  isNewPatient?: Maybe<Scalars['Boolean']>;
  /** Whether this is a primary care appointment */
  isPrimary: Scalars['Boolean'];
  /** Whether this is a specialty care appointment */
  isSpecialty: Scalars['Boolean'];
  /** Whether this is an urgent care appointment */
  isUrgent: Scalars['Boolean'];
  /** Whether the appointment type is a lab service */
  labService?: Maybe<Scalars['Boolean']>;
  /** A longer description of this appointment type */
  longDescription?: Maybe<Scalars['String']>;
  /** The internal name of this appointment type */
  name: Scalars['String'];
  position: Scalars['Int'];
  /** Whether the appointment type requires a provider */
  providerlessSearch?: Maybe<Scalars['Boolean']>;
  /** Whether this is a remote appointment */
  remote?: Maybe<Scalars['Boolean']>;
  remoteAppointmentType?: Maybe<AppointmentType>;
  /**
   * The corresponding remote appointment type id if it exists
   * @deprecated Use remoteAppointmentType instead
   */
  remoteAppointmentTypeId?: Maybe<Scalars['ID']>;
  /** Whether this appointment type requires a HIR authorization from the current member */
  requiresHirAuthorization: Scalars['Boolean'];
  /** uuid of the AppointmentType object */
  uuid: Scalars['ID'];
  /** Whether this appointment type has the waitlist feature enabled (in the given service area, if provided) */
  waitlistEnabled: Scalars['Boolean'];
};


export type AppointmentTypeWaitlistEnabledArgs = {
  serviceAreaId?: InputMaybe<Scalars['ID']>;
};

/** The connection type for AppointmentType. */
export type AppointmentTypeAlternative = {
  __typename?: 'AppointmentTypeAlternative';
  /** A list of edges. */
  edges: Array<AppointmentTypeAlternativeEdge>;
  /** A list of nodes. */
  nodes: Array<AppointmentType>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Extra metadata about an appointment type */
export type AppointmentTypeAlternativeEdge = {
  __typename?: 'AppointmentTypeAlternativeEdge';
  /** Labels for 'try searching for...' recommendations */
  alternativeLabel: Label;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AppointmentType>;
};

export type AppointmentTypeCommon = {
  /** id of the AppointmentType object */
  id: Scalars['ID'];
  /** uuid of the AppointmentType object */
  uuid: Scalars['ID'];
};

/** The connection type for AppointmentType. */
export type AppointmentTypeConnection = {
  __typename?: 'AppointmentTypeConnection';
  /** A list of edges. */
  edges: Array<AppointmentTypeEdge>;
  /** A list of nodes. */
  nodes: Array<AppointmentType>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AppointmentTypeEdge = {
  __typename?: 'AppointmentTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AppointmentType>;
};

export type AppointmentTypeGroup = {
  __typename?: 'AppointmentTypeGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Autogenerated input type of ArchiveInsurance */
export type ArchiveInsuranceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of ArchiveInsurance. */
export type ArchiveInsurancePayload = {
  __typename?: 'ArchiveInsurancePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  insurance?: Maybe<Insurance>;
  success: Scalars['Boolean'];
};

/** Interface for different assignees, typically a role or internal user */
export type Assignee = {
  displayName: Scalars['String'];
  normalizedId: Scalars['ID'];
};

/** Autogenerated input type of AttachDocumentToProcedure */
export type AttachDocumentToProcedureInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  documentId: Scalars['ID'];
  procedureOriginId: Scalars['ID'];
  procedureOriginType: ProcedureOriginType;
};

/** Autogenerated return type of AttachDocumentToProcedure. */
export type AttachDocumentToProcedurePayload = {
  __typename?: 'AttachDocumentToProcedurePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  procedureOrigin?: Maybe<ProcedureOrigin>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of AttachServiceTicketImproperlyClosedOrder */
export type AttachServiceTicketImproperlyClosedOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of AttachServiceTicketImproperlyClosedOrder. */
export type AttachServiceTicketImproperlyClosedOrderPayload = {
  __typename?: 'AttachServiceTicketImproperlyClosedOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  serviceTicketImproperlyClosedOrder?: Maybe<ServiceTicketImproperlyClosedOrder>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of AuthorizeConnectedDevices */
export type AuthorizeConnectedDevicesInput = {
  authorized?: InputMaybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of AuthorizeConnectedDevices. */
export type AuthorizeConnectedDevicesPayload = {
  __typename?: 'AuthorizeConnectedDevicesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of AutoVerifyInsuranceOcrResult */
export type AutoVerifyInsuranceOcrResultInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  patientId: Scalars['ID'];
  uuid: Scalars['ID'];
};

/** Autogenerated return type of AutoVerifyInsuranceOcrResult. */
export type AutoVerifyInsuranceOcrResultPayload = {
  __typename?: 'AutoVerifyInsuranceOcrResultPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Insurance plan object */
  insurancePlan?: Maybe<InsurancePlan>;
  mutationErrors?: Maybe<Array<MutationError>>;
  /** Subscriber number */
  subscriberNumber?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  /** Waystar status object */
  waystarEligibilityStatus?: Maybe<WaystarEligibilityStatus>;
  /** Waystar error - waystar text response */
  waystarError?: Maybe<Scalars['String']>;
};

export type B2bCompany = {
  __typename?: 'B2bCompany';
  activationCodeRetrievableViaEmail: Scalars['Boolean'];
  b2bEmailDomains?: Maybe<Array<Scalars['String']>>;
  customElistError?: Maybe<Scalars['String']>;
  customNetworks?: Maybe<Array<CustomNetwork>>;
  /** Finds a B2B discount type by its code */
  discountType?: Maybe<DiscountType>;
  displayElistError: Scalars['Boolean'];
  displayName: Scalars['String'];
  enterprisePedsRegistrationEnabled: Scalars['Boolean'];
  /** @deprecated Use verifyDependentInfo instead to determine if dependents should be verified against the e-list */
  hasEligibleDependents: Scalars['Boolean'];
  /** @deprecated Never used */
  hasEligibleEmployees: Scalars['Boolean'];
  id: Scalars['ID'];
  includesDependent: Scalars['Boolean'];
  name: Scalars['String'];
  verifyDependentInfo: Scalars['Boolean'];
};


export type B2bCompanyDiscountTypeArgs = {
  code: Scalars['String'];
};

export type B2bPlan = Plan & {
  __typename?: 'B2bPlan';
  company?: Maybe<B2bCompany>;
  hasActiveDiscountTypes: Scalars['Boolean'];
  id: Scalars['ID'];
  isCore: Scalars['Boolean'];
  kind: Scalars['String'];
  name: Scalars['String'];
};

export type BillingDistrict = {
  __typename?: 'BillingDistrict';
  id: Scalars['ID'];
  insuranceNetworks?: Maybe<Array<InsuranceNetwork>>;
  insuranceTaxId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  serviceArea?: Maybe<ServiceArea>;
};

export type BookAppointmentAttributes = {
  appointmentTypeId: Scalars['ID'];
  /** ID of the appointment inventory that will be booked as a part of rescheduling. */
  inventoryId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
  sendSms?: InputMaybe<Scalars['Boolean']>;
  smsNumber?: InputMaybe<Scalars['String']>;
  visitReasonCategories?: InputMaybe<Array<VisitReasonAttributes>>;
};

/** Autogenerated input type of BookAppointment */
export type BookAppointmentInput = {
  appointmentAttributes: BookAppointmentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  internalBookingAttributes?: InputMaybe<InternalBookingAttributes>;
  patientId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of BookAppointment. */
export type BookAppointmentPayload = {
  __typename?: 'BookAppointmentPayload';
  /** The new appointment that was booked. */
  appointment?: Maybe<Appointment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  patient: Patient;
  success: Scalars['Boolean'];
};

export type BookableProviderSearchResult = SearchResult & {
  __typename?: 'BookableProviderSearchResult';
  displayName: Scalars['String'];
  id: Scalars['ID'];
  label: Scalars['String'];
  node: Provider;
};

/** Autogenerated input type of BulkUpdateInsurancePlan */
export type BulkUpdateInsurancePlanInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  insurancePlanIds: Array<Scalars['ID']>;
  insuranceTypeId: Scalars['ID'];
};

/** Autogenerated return type of BulkUpdateInsurancePlan. */
export type BulkUpdateInsurancePlanPayload = {
  __typename?: 'BulkUpdateInsurancePlanPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  insurancePlans?: Maybe<Array<InsurancePlan>>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CancelServiceTicketTransmission */
export type CancelServiceTicketTransmissionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of CancelServiceTicketTransmission. */
export type CancelServiceTicketTransmissionPayload = {
  __typename?: 'CancelServiceTicketTransmissionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  serviceTicketTransmission?: Maybe<ServiceTicketTransmission>;
  success: Scalars['Boolean'];
};

/** The connection type for CareTeamMember. */
export type CareTeam = {
  __typename?: 'CareTeam';
  /** A list of edges. */
  edges: Array<CareTeamEdge>;
  /** A list of nodes. */
  nodes: Array<CareTeamMember>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  pcp?: Maybe<Provider>;
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type CareTeamEdge = {
  __typename?: 'CareTeamEdge';
  /** @deprecated this resource does not yet use cursor based pagination but hopefully will soon */
  cursor?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  /** The item at the end of the edge. */
  node?: Maybe<CareTeamMember>;
  reasons?: Maybe<Array<CareTeamReason>>;
};

/** The members of a patient's team. Currently only internal users, but we have had conversations about including specalists and medical powers of attorney, so this API allows for that */
export type CareTeamMember = InternalUser;

/** why is this individual on this patient's team */
export type CareTeamReason = Appointment | Panel | ProgramEnrollment | ProgramEnrollmentCareTeam;

export enum CareTeamType {
  /** PCP + mindset providers with domain specific labels (e.g. Therapist, Care Manager, Unknown) instead of default program enrollment label */
  BehavioralHealth = 'BEHAVIORAL_HEALTH',
  /** Everything in LONGITUDINAL plus appointments */
  Comprehensive = 'COMPREHENSIVE',
  /** PCP, then any program-based care */
  Longitudinal = 'LONGITUDINAL'
}

export type CareTeamsCareGiver = {
  __typename?: 'CareTeamsCareGiver';
  careTeamId: Scalars['ID'];
  provider: Provider;
  role: Scalars['String'];
};

export type CervicalCancerReportContent = {
  __typename?: 'CervicalCancerReportContent';
  /** Result report and any additional detail */
  description: Scalars['HTML'];
};

export type CervicalCancerReportContents = {
  __typename?: 'CervicalCancerReportContents';
  /** Cytology result report */
  cytologyResult?: Maybe<CervicalCancerReportContent>;
  ending: Scalars['String'];
  /** HPV result report */
  hpvResult?: Maybe<CervicalCancerReportContent>;
  intro: Scalars['String'];
  /** List of other infections to report */
  otherInfections?: Maybe<CervicalCancerReportSection>;
  /** Indicates whether patient needs to provide previous records */
  previousRecordsNeeded?: Maybe<CervicalCancerReportContent>;
  /** Recommendation for next steps, based on results */
  recommendation?: Maybe<CervicalCancerReportContent>;
  title: Scalars['String'];
};

export enum CervicalCancerReportCytologyResult {
  Agc = 'AGC',
  AgcAndOver = 'AGC_AND_OVER',
  Ascus = 'ASCUS',
  AscH = 'ASC_H',
  Hsil = 'HSIL',
  Inflammation = 'INFLAMMATION',
  Lsil = 'LSIL',
  Nilm = 'NILM',
  Unsatisfactory = 'UNSATISFACTORY',
  UnsatisfactorySecond = 'UNSATISFACTORY_SECOND'
}

export enum CervicalCancerReportHpvResult {
  Negative_16Hpv = 'NEGATIVE_16_HPV',
  NegativeHpv = 'NEGATIVE_HPV',
  Positive_16Hpv = 'POSITIVE_16_HPV',
  PositiveHpvUntyped = 'POSITIVE_HPV_UNTYPED'
}

export enum CervicalCancerReportOtherInfection {
  Cocco = 'COCCO',
  NegativeGcct = 'NEGATIVE_GCCT',
  PositiveActinomyces = 'POSITIVE_ACTINOMYCES',
  PositiveHsvAsymp = 'POSITIVE_HSV_ASYMP',
  PositiveHsvHistory = 'POSITIVE_HSV_HISTORY',
  PositiveTrichomonas = 'POSITIVE_TRICHOMONAS',
  PositiveYeast = 'POSITIVE_YEAST',
  PositiveYeastAndCocco = 'POSITIVE_YEAST_AND_COCCO'
}

export enum CervicalCancerReportRecommendation {
  Colposcopy = 'COLPOSCOPY',
  ColpoEmb = 'COLPO_EMB',
  FiveYears = 'FIVE_YEARS',
  OneYear = 'ONE_YEAR',
  RepeatCcs = 'REPEAT_CCS',
  ThreeYears = 'THREE_YEARS'
}

export type CervicalCancerReportSection = {
  __typename?: 'CervicalCancerReportSection';
  /** List of results reports and additional details */
  descriptions: Array<Scalars['HTML']>;
  /** Section header */
  header: Scalars['String'];
};

export type CervicalCancerResultOption = {
  __typename?: 'CervicalCancerResultOption';
  label: Scalars['String'];
  name: Scalars['String'];
};

export type CervicalCancerResultOptions = {
  __typename?: 'CervicalCancerResultOptions';
  /** Options according to different cytology results */
  cytologyResults: Array<CervicalCancerResultOption>;
  /** Options according to different hpv results */
  hpvResults: Array<CervicalCancerResultOption>;
  /** Options for infections beyond pap and hpv tests */
  otherInfections: Array<CervicalCancerResultOption>;
  /** Option for action required */
  previousRecordsNeeded: CervicalCancerResultOption;
  /** Options for follow-up recommendations */
  recommendations: Array<CervicalCancerResultOption>;
};

export type CervicalCancerScreeningReportInput = {
  cytologyResult?: InputMaybe<CervicalCancerReportCytologyResult>;
  hpvResult?: InputMaybe<CervicalCancerReportHpvResult>;
  otherInfections?: InputMaybe<Array<CervicalCancerReportOtherInfection>>;
  previousRecordsNeeded?: InputMaybe<Scalars['Boolean']>;
  recommendation?: InputMaybe<CervicalCancerReportRecommendation>;
};

export type ChargeExemptionDetails = {
  __typename?: 'ChargeExemptionDetails';
  chargeExempt: Scalars['Boolean'];
  factors?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated input type of ChargePatientForCopay */
export type ChargePatientForCopayInput = {
  amountCents: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  serviceTicketId: Scalars['ID'];
  stripePaymentMethodId: Scalars['String'];
};

/** Autogenerated return type of ChargePatientForCopay. */
export type ChargePatientForCopayPayload = {
  __typename?: 'ChargePatientForCopayPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  serviceTicket?: Maybe<ServiceTicket>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of ChargePatientForServiceTicket */
export type ChargePatientForServiceTicketInput = {
  amountCents: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  serviceTicketId: Scalars['ID'];
  stripePaymentMethodId: Scalars['String'];
};

/** Autogenerated return type of ChargePatientForServiceTicket. */
export type ChargePatientForServiceTicketPayload = {
  __typename?: 'ChargePatientForServiceTicketPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  serviceTicket?: Maybe<ServiceTicket>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CheckInsuranceEligibility */
export type CheckInsuranceEligibilityInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of CheckInsuranceEligibility. */
export type CheckInsuranceEligibilityPayload = {
  __typename?: 'CheckInsuranceEligibilityPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  insurances?: Maybe<Array<Insurance>>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

export type CheckVersion = {
  __typename?: 'CheckVersion';
  message?: Maybe<Scalars['String']>;
  minimumSupportedVersion?: Maybe<Scalars['String']>;
  updateRequired: Scalars['Boolean'];
};

export type ClaimCodeValidation = {
  __typename?: 'ClaimCodeValidation';
  billingCycle?: Maybe<Scalars['String']>;
  error?: Maybe<ClaimCodeValidationError>;
  isMembershipManager?: Maybe<Scalars['Boolean']>;
  isValidClaimCode: Scalars['Boolean'];
  seatsAvailable?: Maybe<Scalars['Int']>;
  subscriptionId?: Maybe<Scalars['ID']>;
};

export type ClaimCodeValidationError = {
  __typename?: 'ClaimCodeValidationError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type CollectionInstruction = {
  __typename?: 'CollectionInstruction';
  imageUrl: Scalars['String'];
  order: Scalars['Int'];
  quantity: Scalars['Int'];
  sampleDescription?: Maybe<Scalars['String']>;
  sampleType?: Maybe<Scalars['String']>;
  sampleTypeDescription?: Maybe<Scalars['String']>;
  shipContainers?: Maybe<Array<ShipContainer>>;
  sizeDescription?: Maybe<Scalars['String']>;
  specialInstructions?: Maybe<Array<SpecialInstruction>>;
  yieldVolume: Scalars['Float'];
  yieldVolumeUnits?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CompleteOrdersAndSignNoteForAppointment */
export type CompleteOrdersAndSignNoteForAppointmentInput = {
  allowClosedTicket?: InputMaybe<Scalars['Boolean']>;
  appointmentId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  labOrderIds?: InputMaybe<Array<Scalars['ID']>>;
};

/** Autogenerated return type of CompleteOrdersAndSignNoteForAppointment. */
export type CompleteOrdersAndSignNoteForAppointmentPayload = {
  __typename?: 'CompleteOrdersAndSignNoteForAppointmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  labOrders?: Maybe<Array<LabOrder>>;
  success: Scalars['Boolean'];
  summary?: Maybe<Summary>;
};

/** Autogenerated input type of CompleteProcedureInteraction */
export type CompleteProcedureInteractionInput = {
  allowClosedTicket?: InputMaybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of CompleteProcedureInteraction. */
export type CompleteProcedureInteractionPayload = {
  __typename?: 'CompleteProcedureInteractionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  detailedErrors?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
  procedureInteraction?: Maybe<ProcedureInteraction>;
  success: Scalars['Boolean'];
};

export type Contact = {
  __typename?: 'Contact';
  acceptingNewPatients: Scalars['Boolean'];
  acceptsInsurance: Scalars['Boolean'];
  address?: Maybe<Address>;
  availability: Rating;
  companyName?: Maybe<Scalars['String']>;
  contactInsurances: Array<ContactInsurance>;
  createdAt: Scalars['DateTime'];
  customNetworks: Array<CustomNetwork>;
  customWarning?: Maybe<Scalars['String']>;
  degrees?: Maybe<Scalars['String']>;
  descendants: Array<Contact>;
  diagnosticGroups: Array<DiagnosticGroup>;
  displayName?: Maybe<Scalars['String']>;
  emails: Array<Email>;
  expertise: Rating;
  firstName?: Maybe<Scalars['String']>;
  formerName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  hasPreferredAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  incompleteReason?: Maybe<Scalars['String']>;
  institutions: Array<Institution>;
  insuranceNetworks: Array<InsuranceNetwork>;
  insuranceNotes?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isComplete: Scalars['Boolean'];
  isCustom: Scalars['Boolean'];
  isEditable: Scalars['Boolean'];
  isElectronicallyIntegrated: Scalars['Boolean'];
  isInternal: Scalars['Boolean'];
  isPartner: Scalars['Boolean'];
  isQuality: Scalars['Boolean'];
  kind: ContactKind;
  languagesSpoken?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  noPhysicalLocation: Scalars['Boolean'];
  nonAcceptedContactInsurances: Array<ContactInsurance>;
  npi?: Maybe<Scalars['String']>;
  officeHours?: Maybe<Scalars['String']>;
  otherNotes?: Maybe<Scalars['String']>;
  parent?: Maybe<Contact>;
  personality: Rating;
  phoneNumbers: Array<PhoneNumber>;
  specialties: Array<Specialty>;
  /** @deprecated Please use `Contact.systemStatus`, an enum that encodes the same information */
  status?: Maybe<ContactsStatus>;
  suffix?: Maybe<Scalars['String']>;
  systemStatus?: Maybe<ContactStatus>;
  taxId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<InternalUser>;
  value: Rating;
  warnings: Array<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};


export type ContactAddressArgs = {
  withInheritedData?: InputMaybe<Scalars['Boolean']>;
};


export type ContactContactInsurancesArgs = {
  withInheritedData?: InputMaybe<Scalars['Boolean']>;
};


export type ContactCustomNetworksArgs = {
  withInheritedData?: InputMaybe<Scalars['Boolean']>;
};


export type ContactEmailsArgs = {
  withInheritedData?: InputMaybe<Scalars['Boolean']>;
};


export type ContactHasPreferredAccessArgs = {
  withInheritedData?: InputMaybe<Scalars['Boolean']>;
};


export type ContactInsuranceNetworksArgs = {
  withInheritedData?: InputMaybe<Scalars['Boolean']>;
};


export type ContactInsuranceNotesArgs = {
  withInheritedData?: InputMaybe<Scalars['Boolean']>;
};


export type ContactIsElectronicallyIntegratedArgs = {
  withInheritedData?: InputMaybe<Scalars['Boolean']>;
};


export type ContactIsPartnerArgs = {
  withInheritedData?: InputMaybe<Scalars['Boolean']>;
};


export type ContactIsQualityArgs = {
  withInheritedData?: InputMaybe<Scalars['Boolean']>;
};


export type ContactNoPhysicalLocationArgs = {
  withInheritedData?: InputMaybe<Scalars['Boolean']>;
};


export type ContactNonAcceptedContactInsurancesArgs = {
  withInheritedData?: InputMaybe<Scalars['Boolean']>;
};


export type ContactPhoneNumbersArgs = {
  withInheritedData?: InputMaybe<Scalars['Boolean']>;
};

export type ContactInsurance = {
  __typename?: 'ContactInsurance';
  id: Scalars['ID'];
  insuranceCarrier: InsuranceCarrier;
  insuranceTypes: Array<InsuranceType>;
};

export type ContactInsuranceAttributes = {
  _destroy?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  id?: InputMaybe<Scalars['ID']>;
  insuranceCarrierId?: InputMaybe<Scalars['ID']>;
  insuranceTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export enum ContactKind {
  Consultant = 'consultant',
  FromEmail = 'from_email',
  Generic = 'generic',
  Group = 'group',
  LabProvider = 'lab_provider',
  Practice = 'practice',
  Specialist = 'specialist'
}

export type ContactPageInfo = {
  cursor?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
};

export type ContactSearchEdge = {
  __typename?: 'ContactSearchEdge';
  cursor: Scalars['String'];
  /** Distance in miles between the searched location and the contact's address. */
  distance?: Maybe<Scalars['Float']>;
  node: Contact;
};

export type ContactSearchPage = {
  __typename?: 'ContactSearchPage';
  edges: Array<ContactSearchEdge>;
  /** @deprecated Use relay based `pageInfo` instead */
  hasNextPage: Scalars['Boolean'];
  /** @deprecated Use relay based `pageInfo` instead */
  nextCursor?: Maybe<Scalars['String']>;
  nodes: Array<Contact>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type ContactSearchQuery = {
  /** Only used when sortBy is RECOMMENDED */
  diagnosticGroupId?: InputMaybe<Scalars['ID']>;
  /** Only used when sortBy is RECOMMENDED */
  filterInNetworkOnly?: InputMaybe<Scalars['Boolean']>;
  insuranceCarrierIds?: InputMaybe<Array<Scalars['Int']>>;
  insuranceTypeIds?: InputMaybe<Array<Scalars['Int']>>;
  location?: InputMaybe<Geolocation>;
  /** Only used when sortBy is RECOMMENDED */
  patientId?: InputMaybe<Scalars['ID']>;
  query?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ContactSearchSortBy>;
};

export type ContactSearchResult = SearchResult & {
  __typename?: 'ContactSearchResult';
  id: Scalars['ID'];
  kind: Scalars['String'];
  label: Scalars['String'];
};

export enum ContactSearchSortBy {
  /** Search with match fields and location, without filters */
  Distance = 'DISTANCE',
  /** Search with match fields and location, with filters */
  Recommended = 'RECOMMENDED',
  /** Recommended search, but sorting by contact value ratings first */
  Value = 'VALUE'
}

export enum ContactStatus {
  Active = 'ACTIVE',
  Banned = 'BANNED',
  Deactivated = 'DEACTIVATED',
  Deceased = 'DECEASED',
  Duplicate = 'DUPLICATE',
  Moved = 'MOVED',
  OutOfBusiness = 'OUT_OF_BUSINESS',
  Retired = 'RETIRED'
}

export type ContactsStatus = {
  __typename?: 'ContactsStatus';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Autogenerated input type of CreateAdmission */
export type CreateAdmissionInput = {
  admitReason: Scalars['String'];
  admittedAt?: InputMaybe<Scalars['Date']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  dischargeType?: InputMaybe<AdmissionDischargeType>;
  dischargedAt?: InputMaybe<Scalars['Date']>;
  facilityLocation: Scalars['String'];
  facilityType: AdmissionFacilityType;
  hasDischargeSummary?: InputMaybe<Scalars['Boolean']>;
  hrcmEnrollment?: InputMaybe<HrcmEnrollment>;
  monitoring: AdmissionMonitoring;
  nextCheckInDate?: InputMaybe<Scalars['Date']>;
  nextSteps?: InputMaybe<Scalars['String']>;
  patientId: Scalars['ID'];
  readmissionRisk?: InputMaybe<ReadmissionRisk>;
  story?: InputMaybe<Scalars['HTML']>;
  type: AdmissionType;
};

/** Autogenerated return type of CreateAdmission. */
export type CreateAdmissionPayload = {
  __typename?: 'CreateAdmissionPayload';
  admission?: Maybe<Admission>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateAppointmentInventory */
export type CreateAppointmentInventoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  duration: Scalars['Int'];
  providerId?: InputMaybe<Scalars['Int']>;
  startTime: Scalars['DateTime'];
};

/** Autogenerated return type of CreateAppointmentInventory. */
export type CreateAppointmentInventoryPayload = {
  __typename?: 'CreateAppointmentInventoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  inventory?: Maybe<AppointmentInventory>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateAppointmentRecording */
export type CreateAppointmentRecordingInput = {
  appointmentId?: InputMaybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateAppointmentRecording. */
export type CreateAppointmentRecordingPayload = {
  __typename?: 'CreateAppointmentRecordingPayload';
  appointmentRecording?: Maybe<AppointmentRecording>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateBillingDistrictInsuranceNetwork */
export type CreateBillingDistrictInsuranceNetworkInput = {
  billingDistrictId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  insuranceNetworkId: Scalars['ID'];
};

/** Autogenerated return type of CreateBillingDistrictInsuranceNetwork. */
export type CreateBillingDistrictInsuranceNetworkPayload = {
  __typename?: 'CreateBillingDistrictInsuranceNetworkPayload';
  billingDistrict?: Maybe<BillingDistrict>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateContact */
export type CreateContactInput = {
  acceptingNewPatients?: InputMaybe<Scalars['Boolean']>;
  acceptsInsurance?: InputMaybe<Scalars['Boolean']>;
  addressAttributes?: InputMaybe<AddressAttributes>;
  availability?: InputMaybe<Rating>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactInsurancesAttributes?: InputMaybe<Array<InputMaybe<ContactInsuranceAttributes>>>;
  customNetworkIds?: InputMaybe<Array<Scalars['ID']>>;
  customWarning?: InputMaybe<Scalars['String']>;
  degrees?: InputMaybe<Scalars['String']>;
  diagnosticGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  emailsAttributes?: InputMaybe<Array<InputMaybe<EmailAttributes>>>;
  expertise?: InputMaybe<Rating>;
  firstName?: InputMaybe<Scalars['String']>;
  formerName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  hasPreferredAccess?: InputMaybe<Scalars['Boolean']>;
  incompleteReason?: InputMaybe<Scalars['String']>;
  institutionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  insuranceNetworkIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  insuranceNotes?: InputMaybe<Scalars['String']>;
  isCompany?: InputMaybe<Scalars['Boolean']>;
  isComplete?: InputMaybe<Scalars['Boolean']>;
  isCustom?: InputMaybe<Scalars['Boolean']>;
  isElectronicallyIntegrated?: InputMaybe<Scalars['Boolean']>;
  isInternal?: InputMaybe<Scalars['Boolean']>;
  isPartner?: InputMaybe<Scalars['Boolean']>;
  isQuality?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<ContactKind>;
  languagesSpoken?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  noPhysicalLocation?: InputMaybe<Scalars['Boolean']>;
  nonAcceptedContactInsurancesAttributes?: InputMaybe<Array<InputMaybe<ContactInsuranceAttributes>>>;
  npi?: InputMaybe<Scalars['String']>;
  officeHours?: InputMaybe<Scalars['String']>;
  otherNotes?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['ID']>;
  personality?: InputMaybe<Rating>;
  phoneNumbersAttributes?: InputMaybe<Array<InputMaybe<PhoneNumberAttributes>>>;
  specialtyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  status?: InputMaybe<ContactStatus>;
  suffix?: InputMaybe<Scalars['String']>;
  taxId?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Rating>;
  website?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateContact. */
export type CreateContactPayload = {
  __typename?: 'CreateContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contact?: Maybe<Contact>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateHelpRequest */
export type CreateHelpRequestInput = {
  attributes?: InputMaybe<HelpRequestAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  helpflowType: Scalars['String'];
};

/** Autogenerated return type of CreateHelpRequest. */
export type CreateHelpRequestPayload = {
  __typename?: 'CreateHelpRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  helpRequest?: Maybe<HelpRequest>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateInsurance */
export type CreateInsuranceInput = {
  cardImageS3KeyBack?: InputMaybe<Scalars['String']>;
  cardImageS3KeyFront?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  coinsurance?: InputMaybe<Scalars['Int']>;
  comment?: InputMaybe<Scalars['String']>;
  copay?: InputMaybe<Scalars['Int']>;
  effectiveAt?: InputMaybe<Scalars['String']>;
  groupno: Scalars['String'];
  holderAddress1?: InputMaybe<Scalars['String']>;
  holderAddress2?: InputMaybe<Scalars['String']>;
  holderCity?: InputMaybe<Scalars['String']>;
  holderDob?: InputMaybe<Scalars['String']>;
  holderFirstName?: InputMaybe<Scalars['String']>;
  holderLastName?: InputMaybe<Scalars['String']>;
  holderPhoneNumber?: InputMaybe<Scalars['String']>;
  holderState?: InputMaybe<Scalars['String']>;
  holderZipCode?: InputMaybe<Scalars['String']>;
  insuranceCarrierId?: InputMaybe<Scalars['ID']>;
  insuranceImage?: InputMaybe<S3Attributes>;
  insuranceNetworkId?: InputMaybe<Scalars['ID']>;
  insurancePlanId?: InputMaybe<Scalars['ID']>;
  insuranceRelationshipTypeId?: InputMaybe<Scalars['ID']>;
  insuranceTypeId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  patientId?: InputMaybe<Scalars['ID']>;
  primary?: InputMaybe<Scalars['Boolean']>;
  subscriberNumber?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateInsuranceNetworksProviders */
export type CreateInsuranceNetworksProvidersInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  insuranceNetworkId: Scalars['ID'];
  providerId: Scalars['ID'];
};

/** Autogenerated return type of CreateInsuranceNetworksProviders. */
export type CreateInsuranceNetworksProvidersPayload = {
  __typename?: 'CreateInsuranceNetworksProvidersPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  insuranceNetworksProviders?: Maybe<InsuranceNetworksProviders>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of CreateInsurance. */
export type CreateInsurancePayload = {
  __typename?: 'CreateInsurancePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  insurance?: Maybe<Insurance>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateInsurancePlan */
export type CreateInsurancePlanInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  insuranceCarrierId: Scalars['ID'];
  insuranceTypeId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

/** Autogenerated return type of CreateInsurancePlan. */
export type CreateInsurancePlanPayload = {
  __typename?: 'CreateInsurancePlanPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  insurancePlan?: Maybe<InsurancePlan>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateLabOrderOutreach */
export type CreateLabOrderOutreachInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Boolean indicating whether the lab order outreach should create a patient task. */
  createPatientTask?: InputMaybe<Scalars['Boolean']>;
  /** An internal note describing why this outreach is being run. */
  description?: InputMaybe<Scalars['String']>;
  /** Boolean determining whether a lab should be taken fasted or not. */
  fasting?: InputMaybe<Scalars['Boolean']>;
  /** Lab order type IDs to be associated with a lab order. */
  labOrderTypeIds: Array<Scalars['ID']>;
  /** The ID of the Measurement Order Type Source for the lab order. */
  measurementOrderTypeSourceId: Scalars['ID'];
  /** Boolean indicating whether the lab order's review task should be auto finished if results are normal. */
  normalResultAutoFinishesTask: Scalars['Boolean'];
  /** A note describing the actual instructions for a lab order action item. */
  note?: InputMaybe<Scalars['String']>;
  /** An internal note to the phlebotomist collecting the lab. */
  noteToPhleb?: InputMaybe<Scalars['String']>;
  /** Pairs of Patient and Signer IDs. */
  patientAndProviderIds: Array<PatientProviderInput>;
  /** Problem type IDs to be associated with a lab order. */
  problemTypeId: Scalars['ID'];
  /** The date for the lab order to be scheduled. */
  scheduledDate: Scalars['DateTime'];
  /** Boolean determining whether a lab should be collected stat or not. */
  stat?: InputMaybe<Scalars['Boolean']>;
  /** The title of the Lab Order outreach */
  title?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateLabOrderOutreach. */
export type CreateLabOrderOutreachPayload = {
  __typename?: 'CreateLabOrderOutreachPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  /** The successfully created outreach manager batch. */
  outreachManagerBatch?: Maybe<OutreachManagerBatch>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateMassAppointmentCancellationRequest */
export type CreateMassAppointmentCancellationRequestInput = {
  appointmentCancellationReasonId: Scalars['ID'];
  appointmentIds: Array<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  secureMessageBody?: InputMaybe<Scalars['String']>;
  secureMessageTitle?: InputMaybe<Scalars['String']>;
  sendEmailNotification?: InputMaybe<Scalars['Boolean']>;
  sendSecureMessage?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CreateMassAppointmentCancellationRequest. */
export type CreateMassAppointmentCancellationRequestPayload = {
  __typename?: 'CreateMassAppointmentCancellationRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  massAppointmentChangeRequest?: Maybe<MassAppointmentChangeRequest>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateMiscClericalNote */
export type CreateMiscClericalNoteInput = {
  appointmentId?: InputMaybe<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  emailSubject?: InputMaybe<Scalars['String']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  noteBodyText?: InputMaybe<Scalars['String']>;
  patientId: Scalars['Int'];
  processingInstructions?: InputMaybe<Scalars['String']>;
  signature?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateMiscClericalNote. */
export type CreateMiscClericalNotePayload = {
  __typename?: 'CreateMiscClericalNotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  note?: Maybe<Note>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateMlFeedbackProcedureSuggestion */
export type CreateMlFeedbackProcedureSuggestionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  feedbackCategory: Scalars['String'];
  feedbackText?: InputMaybe<Scalars['String']>;
  mlBillingProcedureSuggestionId: Scalars['ID'];
};

/** Autogenerated return type of CreateMlFeedbackProcedureSuggestion. */
export type CreateMlFeedbackProcedureSuggestionPayload = {
  __typename?: 'CreateMlFeedbackProcedureSuggestionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  procedureSuggestionFeedback?: Maybe<MlFeedbackProcedureSuggestion>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateOrUpdateInsurancePlanInNetworkStatus */
export type CreateOrUpdateInsurancePlanInNetworkStatusInput = {
  billingDistrictId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  insurancePlanIds: Array<Scalars['ID']>;
  status: Scalars['String'];
};

/** Autogenerated return type of CreateOrUpdateInsurancePlanInNetworkStatus. */
export type CreateOrUpdateInsurancePlanInNetworkStatusPayload = {
  __typename?: 'CreateOrUpdateInsurancePlanInNetworkStatusPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  insurancePlanIds?: Maybe<Array<Scalars['ID']>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateOrUpdateMeasurementForProcedure */
export type CreateOrUpdateMeasurementForProcedureInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  measurements: Array<ProcedureMeasurementInputObject>;
  procedureOriginId: Scalars['ID'];
  procedureOriginType: ProcedureOriginType;
};

/** Autogenerated return type of CreateOrUpdateMeasurementForProcedure. */
export type CreateOrUpdateMeasurementForProcedurePayload = {
  __typename?: 'CreateOrUpdateMeasurementForProcedurePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  procedureOrigin?: Maybe<ProcedureOrigin>;
  success: Scalars['Boolean'];
};

export type CreatePatientAddressAddressInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  isPreferred?: InputMaybe<Scalars['Boolean']>;
  kind: AddressKind;
  state: Scalars['String'];
  status: AddressStatus;
  zipCode: Scalars['String'];
};

/** Autogenerated input type of CreatePatientAddress */
export type CreatePatientAddressInput = {
  address: CreatePatientAddressAddressInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  patientId: Scalars['ID'];
};

/** Autogenerated return type of CreatePatientAddress. */
export type CreatePatientAddressPayload = {
  __typename?: 'CreatePatientAddressPayload';
  address?: Maybe<Address>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreatePatientEmail */
export type CreatePatientEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: PatientEmailInput;
  patientId: Scalars['ID'];
};

/** Autogenerated return type of CreatePatientEmail. */
export type CreatePatientEmailPayload = {
  __typename?: 'CreatePatientEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  email?: Maybe<Email>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreatePatientEmergencyContact */
export type CreatePatientEmergencyContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  emergencyContact: EmergencyContactInputObject;
  patientId: Scalars['ID'];
};

/** Autogenerated return type of CreatePatientEmergencyContact. */
export type CreatePatientEmergencyContactPayload = {
  __typename?: 'CreatePatientEmergencyContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContact>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreatePatientPhoneNumber */
export type CreatePatientPhoneNumberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  patientId: Scalars['ID'];
  phoneNumber: PatientPhoneNumberInput;
};

/** Autogenerated return type of CreatePatientPhoneNumber. */
export type CreatePatientPhoneNumberPayload = {
  __typename?: 'CreatePatientPhoneNumberPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  phoneNumber?: Maybe<PhoneNumber>;
  success: Scalars['Boolean'];
};

export type CreatePrescriptionChangeRequestAttributes = {
  medicationOptions?: InputMaybe<Array<Scalars['JSON']>>;
  messageRequestCode: Scalars['String'];
  messageRequestSubcodes?: InputMaybe<Array<Scalars['String']>>;
};

/** Autogenerated input type of CreatePrescriptionChangeRequests */
export type CreatePrescriptionChangeRequestsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  createPrescriptionChangeRequestAttributes: CreatePrescriptionChangeRequestAttributes;
  patientMedicationId: Scalars['ID'];
};

/** Autogenerated return type of CreatePrescriptionChangeRequests. */
export type CreatePrescriptionChangeRequestsPayload = {
  __typename?: 'CreatePrescriptionChangeRequestsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

export type CreatePrescriptionRenewalRequestAttributes = {
  comment?: InputMaybe<Scalars['String']>;
  patientMedicationId: Scalars['ID'];
};

/** Autogenerated input type of CreatePrescriptionRenewalRequests */
export type CreatePrescriptionRenewalRequestsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  createPrescriptionRenewalRequestAttributes: Array<CreatePrescriptionRenewalRequestAttributes>;
  patientId: Scalars['ID'];
  patientPharmacyId: Scalars['ID'];
};

/** Autogenerated return type of CreatePrescriptionRenewalRequests. */
export type CreatePrescriptionRenewalRequestsPayload = {
  __typename?: 'CreatePrescriptionRenewalRequestsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of patient medications for which the renewal request was created. */
  createdRenewalsForPatientMedications?: Maybe<Array<PatientMedication>>;
  errors?: Maybe<Scalars['JSON']>;
  /** @deprecated This field is not yet implemented. It is included for forward-compatibility reasons */
  mutationErrors?: Maybe<Array<MutationError>>;
  /** Full list of a patient's medications. */
  patient_medications?: Maybe<Array<PatientMedication>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateProblemAttachment */
export type CreateProblemAttachmentInput = {
  attachableId: Scalars['ID'];
  attachableType: ProblemAttachmentAttachmentEnum;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  problemId: Scalars['ID'];
};

/** Autogenerated return type of CreateProblemAttachment. */
export type CreateProblemAttachmentPayload = {
  __typename?: 'CreateProblemAttachmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  problem?: Maybe<Problem>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateProcedureInteractionIndication */
export type CreateProcedureInteractionIndicationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  problemCodeExtensionId?: InputMaybe<Scalars['ID']>;
  problemCodeLocationId?: InputMaybe<Scalars['ID']>;
  problemTypeId: Scalars['ID'];
};

/** Autogenerated return type of CreateProcedureInteractionIndication. */
export type CreateProcedureInteractionIndicationPayload = {
  __typename?: 'CreateProcedureInteractionIndicationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  indication?: Maybe<Indication>;
  procedureInteraction?: Maybe<ProcedureInteraction>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateProcedureInteraction */
export type CreateProcedureInteractionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  patientId: Scalars['ID'];
  problemId?: InputMaybe<Scalars['ID']>;
  procedureSuggestionId?: InputMaybe<Scalars['ID']>;
  procedureTypeId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of CreateProcedureInteraction. */
export type CreateProcedureInteractionPayload = {
  __typename?: 'CreateProcedureInteractionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  procedureInteraction?: Maybe<ProcedureInteraction>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateProgramEnrollmentCareGiver */
export type CreateProgramEnrollmentCareGiverInput = {
  careGiverIds: Array<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of CreateProgramEnrollmentCareGiver. */
export type CreateProgramEnrollmentCareGiverPayload = {
  __typename?: 'CreateProgramEnrollmentCareGiverPayload';
  careTeam?: Maybe<ProgramEnrollmentCareTeam>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateScheduleTemplate */
export type CreateScheduleTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Autogenerated return type of CreateScheduleTemplate. */
export type CreateScheduleTemplatePayload = {
  __typename?: 'CreateScheduleTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  scheduleTemplate?: Maybe<ScheduleTemplate>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateScheduleTemplateVariant */
export type CreateScheduleTemplateVariantInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  variant: CreateVariantInput;
};

/** Autogenerated return type of CreateScheduleTemplateVariant. */
export type CreateScheduleTemplateVariantPayload = {
  __typename?: 'CreateScheduleTemplateVariantPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
  variant: ScheduleTemplateVariant;
};

/** Autogenerated input type of CreateSmartDraft */
export type CreateSmartDraftInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  replyInstructions: Scalars['String'];
  threadId: Scalars['ID'];
};

/** Autogenerated return type of CreateSmartDraft. */
export type CreateSmartDraftPayload = {
  __typename?: 'CreateSmartDraftPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  smartDraft?: Maybe<SmartDraft>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateStripeSetupIntent */
export type CreateStripeSetupIntentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  stripeCustomerId: Scalars['String'];
};

/** Autogenerated return type of CreateStripeSetupIntent. */
export type CreateStripeSetupIntentPayload = {
  __typename?: 'CreateStripeSetupIntentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  setupIntentId?: Maybe<Scalars['ID']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateSummary */
export type CreateSummaryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  noteTypeId?: InputMaybe<Scalars['ID']>;
  noteTypeSystemName?: InputMaybe<Scalars['String']>;
  patientId: Scalars['ID'];
};

/** Autogenerated return type of CreateSummary. */
export type CreateSummaryPayload = {
  __typename?: 'CreateSummaryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
  summary?: Maybe<Summary>;
};

/** Autogenerated input type of CreateSurveyOrderOutreach */
export type CreateSurveyOrderOutreachInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  dueDate: Scalars['DateTime'];
  note?: InputMaybe<Scalars['String']>;
  patientIds: Array<Scalars['ID']>;
  surveyId: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateSurveyOrderOutreach. */
export type CreateSurveyOrderOutreachPayload = {
  __typename?: 'CreateSurveyOrderOutreachPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  /** return object */
  outreachManagerBatch?: Maybe<OutreachManagerBatch>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateTask */
export type CreateTaskInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['ID']>;
  taskTemplateId: Scalars['ID'];
};

/** Autogenerated return type of CreateTask. */
export type CreateTaskPayload = {
  __typename?: 'CreateTaskPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
  task?: Maybe<Task>;
};

/** Autogenerated input type of CreateToken */
export type CreateTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  grantType?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  username: Scalars['String'];
};

/** Autogenerated return type of CreateToken. */
export type CreateTokenPayload = {
  __typename?: 'CreateTokenPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
  token?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateUserEngagementMessageOutreach */
export type CreateUserEngagementMessageOutreachInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An internal note describing why this outreach is being run. */
  description?: InputMaybe<Scalars['String']>;
  /** The Patients to deliver the message to. */
  patientIds: Array<Scalars['ID']>;
  /** The User Engagement Message ID that should be used. */
  userEngagementMessageId: Scalars['ID'];
};

/** Autogenerated return type of CreateUserEngagementMessageOutreach. */
export type CreateUserEngagementMessageOutreachPayload = {
  __typename?: 'CreateUserEngagementMessageOutreachPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  /** The successfully created outreach manager batch. */
  outreachManagerBatch?: Maybe<OutreachManagerBatch>;
  success: Scalars['Boolean'];
};

export type CreateVariantInput = {
  name: Scalars['String'];
  scheduleTemplateId: Scalars['ID'];
};

/** Autogenerated input type of CreateVideoCall */
export type CreateVideoCallInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateVideoCall. */
export type CreateVideoCallPayload = {
  __typename?: 'CreateVideoCallPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  meetingId?: Maybe<Scalars['ID']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateVisitFollowUpOrderOutreach */
export type CreateVisitFollowUpOrderOutreachInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  dueDate: Scalars['DateTime'];
  note: Scalars['String'];
  outreachManagerAppointmentTypeGroupId: Scalars['ID'];
  patientIds: Array<Scalars['ID']>;
  problemTypeId?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateVisitFollowUpOrderOutreach. */
export type CreateVisitFollowUpOrderOutreachPayload = {
  __typename?: 'CreateVisitFollowUpOrderOutreachPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  /** When successful, this is the Outreach Manager Batch. */
  outreachManagerBatch?: Maybe<OutreachManagerBatch>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateVoluntaryAlignmentOffer */
export type CreateVoluntaryAlignmentOfferInput = {
  alignmentDocument?: InputMaybe<S3Attributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  documentIds?: InputMaybe<Array<Scalars['ID']>>;
  followUpAt?: InputMaybe<Scalars['DateTime']>;
  notes?: InputMaybe<Scalars['String']>;
  offerMethod?: InputMaybe<Scalars['String']>;
  offeredAt?: InputMaybe<Scalars['DateTime']>;
  offeredById?: InputMaybe<Scalars['ID']>;
  patientId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
  signedAt?: InputMaybe<Scalars['DateTime']>;
  state?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateVoluntaryAlignmentOffer. */
export type CreateVoluntaryAlignmentOfferPayload = {
  __typename?: 'CreateVoluntaryAlignmentOfferPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
  voluntaryAlignmentOffer?: Maybe<VoluntaryAlignmentOffer>;
};

export type CustomNetwork = {
  __typename?: 'CustomNetwork';
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isRestricted: Scalars['Boolean'];
  name: Scalars['String'];
  slug: Scalars['ID'];
};

/** Autogenerated input type of DebugServiceTicketTransmission */
export type DebugServiceTicketTransmissionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DebugServiceTicketTransmission. */
export type DebugServiceTicketTransmissionPayload = {
  __typename?: 'DebugServiceTicketTransmissionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  serviceTicketTransmission?: Maybe<ServiceTicketTransmission>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of DeleteAdmission */
export type DeleteAdmissionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteAdmission. */
export type DeleteAdmissionPayload = {
  __typename?: 'DeleteAdmissionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of DeleteBillingDistrictInsuranceNetwork */
export type DeleteBillingDistrictInsuranceNetworkInput = {
  billingDistrictId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  insuranceNetworkId: Scalars['ID'];
};

/** Autogenerated return type of DeleteBillingDistrictInsuranceNetwork. */
export type DeleteBillingDistrictInsuranceNetworkPayload = {
  __typename?: 'DeleteBillingDistrictInsuranceNetworkPayload';
  billingDistrict?: Maybe<BillingDistrict>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of DeleteDocumentFromProcedure */
export type DeleteDocumentFromProcedureInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  documentId: Scalars['ID'];
  procedureOriginId: Scalars['ID'];
  procedureOriginType: ProcedureOriginType;
};

/** Autogenerated return type of DeleteDocumentFromProcedure. */
export type DeleteDocumentFromProcedurePayload = {
  __typename?: 'DeleteDocumentFromProcedurePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  procedureOrigin?: Maybe<ProcedureOrigin>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of DeleteInsuranceCard */
export type DeleteInsuranceCardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteInsuranceCard. */
export type DeleteInsuranceCardPayload = {
  __typename?: 'DeleteInsuranceCardPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  insurance?: Maybe<Insurance>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of DeleteInsuranceNetworksProviders */
export type DeleteInsuranceNetworksProvidersInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  insuranceNetworkId: Scalars['ID'];
  providerId: Scalars['ID'];
};

/** Autogenerated return type of DeleteInsuranceNetworksProviders. */
export type DeleteInsuranceNetworksProvidersPayload = {
  __typename?: 'DeleteInsuranceNetworksProvidersPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  insuranceNetworksProviders?: Maybe<InsuranceNetworksProviders>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of DeletePatientAddress */
export type DeletePatientAddressInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** id of address to delete */
  id: Scalars['ID'];
};

/** Autogenerated return type of DeletePatientAddress. */
export type DeletePatientAddressPayload = {
  __typename?: 'DeletePatientAddressPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of DeletePatientEmail */
export type DeletePatientEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** id of email to delete */
  id: Scalars['ID'];
};

/** Autogenerated return type of DeletePatientEmail. */
export type DeletePatientEmailPayload = {
  __typename?: 'DeletePatientEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of DeletePatientEmergencyContact */
export type DeletePatientEmergencyContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** id of emergency contact to delete */
  id: Scalars['ID'];
};

/** Autogenerated return type of DeletePatientEmergencyContact. */
export type DeletePatientEmergencyContactPayload = {
  __typename?: 'DeletePatientEmergencyContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of DeletePatientPhoneNumber */
export type DeletePatientPhoneNumberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** id of phone number to delete */
  id: Scalars['ID'];
};

/** Autogenerated return type of DeletePatientPhoneNumber. */
export type DeletePatientPhoneNumberPayload = {
  __typename?: 'DeletePatientPhoneNumberPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of DeleteProblemAttachment */
export type DeleteProblemAttachmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  problemAttachmentId: Scalars['ID'];
};

/** Autogenerated return type of DeleteProblemAttachment. */
export type DeleteProblemAttachmentPayload = {
  __typename?: 'DeleteProblemAttachmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  problem?: Maybe<Problem>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of DeleteProcedureInteractionIndication */
export type DeleteProcedureInteractionIndicationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  procedureInteractionId: Scalars['ID'];
};

/** Autogenerated return type of DeleteProcedureInteractionIndication. */
export type DeleteProcedureInteractionIndicationPayload = {
  __typename?: 'DeleteProcedureInteractionIndicationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  procedureInteraction?: Maybe<ProcedureInteraction>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of DeleteProcedureInteraction */
export type DeleteProcedureInteractionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteProcedureInteraction. */
export type DeleteProcedureInteractionPayload = {
  __typename?: 'DeleteProcedureInteractionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of DeleteProgramEnrollmentCareGiver */
export type DeleteProgramEnrollmentCareGiverInput = {
  careGiverId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteProgramEnrollmentCareGiver. */
export type DeleteProgramEnrollmentCareGiverPayload = {
  __typename?: 'DeleteProgramEnrollmentCareGiverPayload';
  careTeam?: Maybe<ProgramEnrollmentCareTeam>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of DeleteScheduleTemplate */
export type DeleteScheduleTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteScheduleTemplate. */
export type DeleteScheduleTemplatePayload = {
  __typename?: 'DeleteScheduleTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of DeleteScheduleTemplateVariant */
export type DeleteScheduleTemplateVariantInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteScheduleTemplateVariant. */
export type DeleteScheduleTemplateVariantPayload = {
  __typename?: 'DeleteScheduleTemplateVariantPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of DeleteServiceTicketItem */
export type DeleteServiceTicketItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteServiceTicketItem. */
export type DeleteServiceTicketItemPayload = {
  __typename?: 'DeleteServiceTicketItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of DeleteVoluntaryAlignmentOffer */
export type DeleteVoluntaryAlignmentOfferInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteVoluntaryAlignmentOffer. */
export type DeleteVoluntaryAlignmentOfferPayload = {
  __typename?: 'DeleteVoluntaryAlignmentOfferPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

/** Result Set for the differences between 1life's patient demographics and the external demographics */
export type DemographicDifferencesResult = {
  __typename?: 'DemographicDifferencesResult';
  differences: Array<Scalars['String']>;
  externalDemographics: EligibilityDemographics;
  onelifeDemographics: EligibilityDemographics;
};

/** Autogenerated input type of DetachStripePaymentMethod */
export type DetachStripePaymentMethodInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  stripePaymentMethodId: Scalars['String'];
};

/** Autogenerated return type of DetachStripePaymentMethod. */
export type DetachStripePaymentMethodPayload = {
  __typename?: 'DetachStripePaymentMethodPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

export type DiagnosticGroup = {
  __typename?: 'DiagnosticGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DirectSignupError = {
  __typename?: 'DirectSignupError';
  directSignupToken?: Maybe<DirectSignupToken>;
  message?: Maybe<Scalars['String']>;
};

export type DirectSignupToken = {
  __typename?: 'DirectSignupToken';
  email?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  signupComplete: Scalars['Boolean'];
};

export type DiscountType = {
  __typename?: 'DiscountType';
  /** A fixed discount amount in dollars */
  amount?: Maybe<Scalars['Float']>;
  /** The ID of a B2bPlan this discount code applies to */
  b2bPlanId?: Maybe<Scalars['ID']>;
  /** The discount code description */
  description: Scalars['String'];
  id: Scalars['ID'];
  /** The type of discount */
  kind?: Maybe<Scalars['String']>;
  /** The discount code */
  name: Scalars['String'];
  /** A percentage-based discount amount */
  percentage?: Maybe<Scalars['Float']>;
  /** The referral code associated with this discount type, if it is a referral */
  referralCode?: Maybe<Referral>;
  /** Whether this discount code can be used for new signups */
  validForNew: Scalars['Boolean'];
  /** Whether this discount code can be used for renewals */
  validForRenewal: Scalars['Boolean'];
  /** Whether this discount code can be stacked with other codes */
  validWithOtherDiscounts: Scalars['Boolean'];
};

/** Autogenerated input type of DismissMlBillingProcedureSuggestion */
export type DismissMlBillingProcedureSuggestionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DismissMlBillingProcedureSuggestion. */
export type DismissMlBillingProcedureSuggestionPayload = {
  __typename?: 'DismissMlBillingProcedureSuggestionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  procedureSuggestion?: Maybe<MlBillingProcedureSuggestion>;
  success: Scalars['Boolean'];
};

export type Document = ProblemAttachableInterface & {
  __typename?: 'Document';
  availableStatusEvents: Array<DocumentStatusEvent>;
  awsBucket: Scalars['String'];
  awsKey: Scalars['String'];
  createdAt: Scalars['DateTime'];
  ext: Scalars['String'];
  id: Scalars['ID'];
  isLocked: Scalars['Boolean'];
  lockedAt?: Maybe<Scalars['DateTime']>;
  lockedBy?: Maybe<InternalUser>;
  mimeType: Scalars['String'];
  note?: Maybe<Note>;
  originalFilename: Scalars['String'];
  parentType?: Maybe<Scalars['String']>;
  /**
   * If the document has been filed to a patient
   *
   */
  patient?: Maybe<Patient>;
  pdfBucket?: Maybe<Scalars['String']>;
  pdfKey?: Maybe<Scalars['String']>;
  pdfUrl?: Maybe<Scalars['String']>;
  serviceArea?: Maybe<ServiceArea>;
  source?: Maybe<Scalars['String']>;
  status: DocumentStatus;
  summary?: Maybe<DocumentSummary>;
  updatedAt: Scalars['DateTime'];
};

/** The connection type for Document. */
export type DocumentConnection = {
  __typename?: 'DocumentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DocumentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Document>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of objects in query result */
  totalCount: Scalars['Int'];
  /** Total number of pages based on 'first' argument or max_page_size */
  totalPageCount: Scalars['Int'];
};

/** An edge in a connection. */
export type DocumentEdge = {
  __typename?: 'DocumentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Document>;
};

export type DocumentSource = {
  __typename?: 'DocumentSource';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum DocumentStatus {
  Deleted = 'deleted',
  Filed = 'filed',
  Flagged = 'flagged',
  Unfiled = 'unfiled'
}

export enum DocumentStatusEvent {
  Delete = 'delete',
  File = 'file',
  Flag = 'flag',
  Undelete = 'undelete',
  Unfile = 'unfile'
}

export type DocumentSummary = {
  __typename?: 'DocumentSummary';
  createdAt: Scalars['DateTime'];
  data: Scalars['JSON'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type DocusignEnvelope = {
  __typename?: 'DocusignEnvelope';
  completedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<InternalUser>;
  docusignEnvelopeId?: Maybe<Scalars['ID']>;
  docusignTemplateId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  patient: Patient;
  sentAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DuplicateScheduleTemplateVariant */
export type DuplicateScheduleTemplateVariantInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DuplicateScheduleTemplateVariant. */
export type DuplicateScheduleTemplateVariantPayload = {
  __typename?: 'DuplicateScheduleTemplateVariantPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
  variant?: Maybe<ScheduleTemplateVariant>;
};

export type Education = {
  __typename?: 'Education';
  text?: Maybe<Scalars['String']>;
};

export type ElectronicHealthInformationExport = {
  __typename?: 'ElectronicHealthInformationExport';
  createdAt: Scalars['DateTime'];
  downloadUrl?: Maybe<Scalars['String']>;
  /** UUID */
  id: Scalars['ID'];
  patient: Patient;
  recordType: ElectronicHealthInformationExportFormat;
  requestedAt: Scalars['DateTime'];
  state: ElectronicHealthInformationExportState;
  updatedAt: Scalars['DateTime'];
};

/** The connection type for ElectronicHealthInformationExport. */
export type ElectronicHealthInformationExportConnection = {
  __typename?: 'ElectronicHealthInformationExportConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ElectronicHealthInformationExportEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ElectronicHealthInformationExport>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of objects in query result */
  totalCount: Scalars['Int'];
  /** Total number of pages based on 'first' argument or max_page_size */
  totalPageCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ElectronicHealthInformationExportEdge = {
  __typename?: 'ElectronicHealthInformationExportEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ElectronicHealthInformationExport>;
};

export enum ElectronicHealthInformationExportFormat {
  Pdf = 'PDF',
  Zip = 'ZIP'
}

export enum ElectronicHealthInformationExportState {
  Available = 'available',
  Failed = 'failed',
  InProgress = 'in_progress',
  Requested = 'requested',
  Retrying = 'retrying'
}

export type EligibilityDemographics = {
  __typename?: 'EligibilityDemographics';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type EligibilityRule = {
  __typename?: 'EligibilityRule';
  displayString?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type Email = {
  __typename?: 'Email';
  displayEmail: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['ID'];
  isPreferred: Scalars['Boolean'];
  kind: EmailKind;
  updatedAt: Scalars['DateTime'];
};

export type EmailAttributes = {
  _destroy?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  kind?: InputMaybe<EmailKind>;
};

export enum EmailKind {
  B2bEmployeeWork = 'b2b_employee_work',
  DirectMessage = 'direct_message',
  Other = 'other',
  Personal = 'personal',
  Shared = 'shared',
  Work = 'work'
}

export enum EmailVerificationStatus {
  LockedOut = 'locked_out',
  Unverified = 'unverified',
  Verified = 'verified'
}

export type EmergencyContact = {
  __typename?: 'EmergencyContact';
  address?: Maybe<Address>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  isPreferred: Scalars['Boolean'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<PatientPhoneNumber>;
  relationship: Scalars['String'];
};

export type EmergencyContactInputObject = {
  _destroy?: InputMaybe<Scalars['Boolean']>;
  addressAttributes?: InputMaybe<PatientAddressInputObject>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isPreferred?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumberAttributes?: InputMaybe<PatientPhoneNumberInputObject>;
  relationship?: InputMaybe<Scalars['String']>;
};

export type Encounter = {
  __typename?: 'Encounter';
  id?: Maybe<Scalars['ID']>;
};

/** Autogenerated input type of EndVideoCall */
export type EndVideoCallInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  meetingId: Scalars['ID'];
};

/** Autogenerated return type of EndVideoCall. */
export type EndVideoCallPayload = {
  __typename?: 'EndVideoCallPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

export type ErrorResponse = {
  __typename?: 'ErrorResponse';
  error: Scalars['String'];
};

export type Ethnicity = {
  __typename?: 'Ethnicity';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type Expertise = {
  __typename?: 'Expertise';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Autogenerated input type of ExportOrphanedOrders */
export type ExportOrphanedOrdersInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  dosFilter?: InputMaybe<Scalars['String']>;
  marketFilter?: InputMaybe<Scalars['String']>;
  officeFilter?: InputMaybe<Scalars['String']>;
  patientIdFilter?: InputMaybe<Scalars['String']>;
  procedureTypeFilter?: InputMaybe<Scalars['String']>;
  providerIdFilter?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of ExportOrphanedOrders. */
export type ExportOrphanedOrdersPayload = {
  __typename?: 'ExportOrphanedOrdersPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

export type ExternalDocument = {
  __typename?: 'ExternalDocument';
  dateOfServiceEnd?: Maybe<Scalars['DateTime']>;
  dateOfServiceStart?: Maybe<Scalars['DateTime']>;
  directMessageId?: Maybe<Scalars['ID']>;
  documentName?: Maybe<Scalars['String']>;
  documentSource?: Maybe<DocumentSource>;
  fromAddress?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  institution?: Maybe<Institution>;
  noteId?: Maybe<Scalars['ID']>;
  patientId: Scalars['ID'];
  receivedAt?: Maybe<Scalars['DateTime']>;
  type: ExternalDocumentTypeEnum;
};

export enum ExternalDocumentTypeEnum {
  InboundDirectMessageItem = 'inbound_direct_message_item',
  InboundExternalDocument = 'inbound_external_document'
}

export type FileDocumentInput = {
  assigneeIdent: Scalars['ID'];
  filingTemplateId: Scalars['ID'];
  patientId: Scalars['ID'];
  priority?: InputMaybe<Priority>;
};

export type FilingTemplate = {
  __typename?: 'FilingTemplate';
  defaultAssigneeIdent?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  taskTemplate?: Maybe<TaskTemplate>;
};

export type Firebase = {
  __typename?: 'Firebase';
  namespace: Scalars['String'];
  token: Scalars['String'];
};

export type FloatMeasurementValidationRule = MeasurementValidationRule & {
  __typename?: 'FloatMeasurementValidationRule';
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  valueType: Scalars['String'];
};

/** Autogenerated input type of ForceSignSummary */
export type ForceSignSummaryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  summaryId: Scalars['ID'];
};

/** Autogenerated return type of ForceSignSummary. */
export type ForceSignSummaryPayload = {
  __typename?: 'ForceSignSummaryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

export enum Gender {
  Female = 'female',
  Male = 'male',
  NonBinary = 'non_binary',
  Unknown = 'unknown'
}

export type GenderIdentity = {
  __typename?: 'GenderIdentity';
  description?: Maybe<Scalars['String']>;
  snomedCode?: Maybe<Scalars['String']>;
};

/** Strongly-typed lat/long input. */
export type Geolocation = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type GuestPlan = Plan & {
  __typename?: 'GuestPlan';
  id: Scalars['ID'];
  kind: Scalars['String'];
  name: Scalars['String'];
};

export type Hearabout = {
  __typename?: 'Hearabout';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** The connection type for Measurement. */
export type HeartRateMeasurementConnection = {
  __typename?: 'HeartRateMeasurementConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MeasurementEdge>>>;
  education: Education;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Measurement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  validationRules?: Maybe<IntegerMeasurementValidationRule>;
};

/** The connection type for Measurement. */
export type HeightMeasurementConnection = {
  __typename?: 'HeightMeasurementConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MeasurementEdge>>>;
  education: Education;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Measurement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type HelpFlow = {
  __typename?: 'HelpFlow';
  displayName: Scalars['String'];
  id: Scalars['ID'];
};

export type HelpRequest = {
  __typename?: 'HelpRequest';
  createdAt: Scalars['DateTime'];
  createdBy: InternalUser;
  description: Scalars['String'];
  id: Scalars['ID'];
  linkedNoteId: Scalars['Int'];
  patientId: Scalars['ID'];
  slackChannel: Scalars['String'];
  state: Scalars['String'];
};

export type HelpRequestAttributes = {
  actionUrl?: InputMaybe<Scalars['String']>;
  callbackName?: InputMaybe<Scalars['String']>;
  callbackNumber?: InputMaybe<Scalars['String']>;
  callerOnTheLine?: InputMaybe<Scalars['Boolean']>;
  currentUrl?: InputMaybe<Scalars['String']>;
  details?: InputMaybe<Scalars['String']>;
  facility?: InputMaybe<Scalars['String']>;
  licensingBodyId?: InputMaybe<Scalars['ID']>;
  linkedNoteId?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['ID']>;
  triageNoteId?: InputMaybe<Scalars['Int']>;
  urgency?: InputMaybe<Scalars['String']>;
};

export type HrcmEnrollment = {
  /** ID for internal user RN who will be assigned to the care team of the HRCM program */
  assignedRn: Scalars['ID'];
  destroy?: InputMaybe<Scalars['Boolean']>;
};

export type InboundFhirItem = {
  __typename?: 'InboundFhirItem';
  cdpIdentifier?: Maybe<Scalars['ID']>;
  documentSource?: Maybe<Scalars['String']>;
  fhirBundle?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  sourceType?: Maybe<Scalars['String']>;
};

export type Indication = {
  __typename?: 'Indication';
  clinicalDescription?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  problemCode?: Maybe<ProblemCode>;
  problemCodeExtension?: Maybe<ProblemCodeExtension>;
  problemCodeLocation?: Maybe<ProblemCodeLocation>;
  problemType?: Maybe<ProblemType>;
  updatedAt: Scalars['DateTime'];
};

export type Institution = {
  __typename?: 'Institution';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Insurance = {
  __typename?: 'Insurance';
  /** back card image */
  cardImageS3KeyBack?: Maybe<Scalars['String']>;
  /** front card image */
  cardImageS3KeyFront?: Maybe<Scalars['String']>;
  cardUrlBack?: Maybe<Scalars['String']>;
  cardUrlFront?: Maybe<Scalars['String']>;
  /** coinsurance */
  coinsurance?: Maybe<Scalars['Int']>;
  /** extra comments on this insurance */
  comment?: Maybe<Scalars['String']>;
  /** copay */
  copay?: Maybe<Scalars['Int']>;
  /** if the insurance was created by ml ocr */
  createdByMlOcr: Scalars['Boolean'];
  editedBy?: Maybe<InternalUser>;
  /** effective at date */
  effectiveAt?: Maybe<Scalars['DateTime']>;
  eligibilityRules?: Maybe<Array<EligibilityRule>>;
  /** The insurance coverage data in FHIR */
  fhir?: Maybe<Scalars['JSON']>;
  /** group number */
  groupno?: Maybe<Scalars['String']>;
  /** holder's address line 1 */
  holderAddress1?: Maybe<Scalars['String']>;
  /** holder's address line 2 */
  holderAddress2?: Maybe<Scalars['String']>;
  /** holder's city */
  holderCity?: Maybe<Scalars['String']>;
  /** holder's date of birth */
  holderDob?: Maybe<Scalars['String']>;
  /** holder's first name */
  holderFirstName?: Maybe<Scalars['String']>;
  /** holder's last name */
  holderLastName?: Maybe<Scalars['String']>;
  /** holder's phone number */
  holderPhoneNumber?: Maybe<Scalars['String']>;
  /** holder's state */
  holderState?: Maybe<Scalars['String']>;
  /** holder's zip code */
  holderZipCode?: Maybe<Scalars['String']>;
  homeOfficeNetworkStatus?: Maybe<Scalars['String']>;
  /** insurance id */
  id: Scalars['ID'];
  inNetworkStatus?: Maybe<Scalars['String']>;
  insuranceCarrier?: Maybe<InsuranceCarrier>;
  insuranceNetwork?: Maybe<InsuranceNetwork>;
  /** insurance plan */
  insurancePlan?: Maybe<InsurancePlan>;
  insuranceRelationshipType: InsuranceRelationshipType;
  insuranceType?: Maybe<InsuranceType>;
  /** if the insurance is archived */
  isArchived: Scalars['Boolean'];
  /** if insurance populated by waystar */
  isAutopopulatedByWaystar: Scalars['Boolean'];
  /** if the ml ocr needs human review */
  mlOcrNeedsHumanReview: Scalars['Boolean'];
  /** insurance name */
  name?: Maybe<Scalars['String']>;
  opmEligibilityStatus?: Maybe<OpmEligibilityStatus>;
  opmEligibilityUpdatedAt?: Maybe<Scalars['DateTime']>;
  patient: Patient;
  planType?: Maybe<Scalars['String']>;
  /** insurance position */
  position?: Maybe<Scalars['Int']>;
  /** if the insurance is primary */
  primary: Scalars['Boolean'];
  relatedPerson?: Maybe<RelatedPerson>;
  /** insurance subscriber */
  subscriberNumber?: Maybe<Scalars['String']>;
  /** updated at date */
  updatedAt?: Maybe<Scalars['DateTime']>;
  waystarEligibility?: Maybe<WaystarEligibilityStatus>;
};


export type InsuranceInNetworkStatusArgs = {
  officeId: Scalars['ID'];
};

export type InsuranceCarrier = {
  __typename?: 'InsuranceCarrier';
  /** The insurance carrier data in FHIR */
  fhir: Scalars['JSON'];
  id: Scalars['ID'];
  name: Scalars['String'];
  serviceAreas?: Maybe<Array<ServiceArea>>;
  uuid: Scalars['ID'];
};

export type InsuranceNetwork = {
  __typename?: 'InsuranceNetwork';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type InsuranceNetworksProviders = {
  __typename?: 'InsuranceNetworksProviders';
  id: Scalars['ID'];
  insuranceNetwork?: Maybe<InsuranceNetwork>;
  provider?: Maybe<Provider>;
};

export type InsurancePlan = {
  __typename?: 'InsurancePlan';
  id: Scalars['ID'];
  inNetworkStatus?: Maybe<InsurancePlanInNetworkStatus>;
  insuranceCarrier?: Maybe<InsuranceCarrier>;
  insuranceType?: Maybe<InsuranceType>;
  name?: Maybe<Scalars['String']>;
  uuid: Scalars['ID'];
};


export type InsurancePlanInNetworkStatusArgs = {
  billingDistrictId: Scalars['ID'];
};

export type InsurancePlanInNetworkStatus = {
  __typename?: 'InsurancePlanInNetworkStatus';
  billingDistrict?: Maybe<BillingDistrict>;
  id: Scalars['ID'];
  insurancePlan?: Maybe<InsurancePlan>;
  status?: Maybe<Scalars['String']>;
};

export type InsuranceRelationshipType = {
  __typename?: 'InsuranceRelationshipType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type InsuranceType = {
  __typename?: 'InsuranceType';
  id: Scalars['ID'];
  isMedicare: Scalars['Boolean'];
  isSelfPay: Scalars['Boolean'];
  name: Scalars['String'];
  networkRequired: Scalars['Boolean'];
};

export type IntegerMeasurementValidationRule = MeasurementValidationRule & {
  __typename?: 'IntegerMeasurementValidationRule';
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
  valueType: Scalars['String'];
};

export type InternalBookingAttributes = {
  comments?: InputMaybe<Scalars['String']>;
  unregisteredPatientAttributes?: InputMaybe<UnregisteredPatientAttributes>;
};

export type InternalUser = Assignee & MessageRecipient & MessageSender & {
  __typename?: 'InternalUser';
  additionalServiceAreas?: Maybe<Array<ServiceArea>>;
  appointmentRecordings?: Maybe<AppointmentRecordingConnection>;
  canFilterTasksByLocation: Scalars['Boolean'];
  canPaginateAndFilterTasks: Scalars['Boolean'];
  dayTaskCount: Scalars['Int'];
  dayVideoEncountersCount: Scalars['Int'];
  defaultOffice?: Maybe<Office>;
  displayName: Scalars['String'];
  /** @deprecated Please use the camelCase version. */
  display_name: Scalars['String'];
  draftMessages: OriginatingObjectConnection;
  email: Scalars['String'];
  firstName: Scalars['String'];
  globalId: Scalars['String'];
  homeOffice?: Maybe<Office>;
  id: Scalars['ID'];
  initials: Scalars['String'];
  lastName: Scalars['String'];
  /** All of the user's roles that are not the primary roles (admin, provider, phleb, or clubhouse). */
  nonPreferredRoles: Array<RoleType>;
  normalizedId: Scalars['ID'];
  panels: Array<Panel>;
  preferences: InternalUserPreferences;
  /** Can only be admin, provider, phleb, or clubhouse. Defaults to admin. */
  preferredRole: RoleType;
  prescribingCredentials?: Maybe<Array<Maybe<PrescribingCredential>>>;
  /**
   * Default locations are different among providers and Ops roles. Because of licensing, we return LicensingBodyType for providers.
   *  We return ServiceAreaType for non-provider roles. Within Ops, mostly front desk admins support specific service areas. So default locations for Ops depends on whether user cares about specific geography and if front desk admin. An empty list indicates user supports all locations (aka markets).
   */
  prioritizedTasksDefaultLocations: Array<PrioritizedTaskLocation>;
  profileImageUrl?: Maybe<Scalars['String']>;
  providerPronoun?: Maybe<ProviderPronoun>;
  roles: Array<RoleType>;
  serviceArea?: Maybe<ServiceArea>;
  suffix?: Maybe<Scalars['String']>;
  supportedAgeRange?: Maybe<SupportedAgeRange>;
  supportedB2bCompanies?: Maybe<Array<B2bCompany>>;
  weekTaskCount: Scalars['Int'];
  weekVideoEncountersCount: Scalars['Int'];
  workSchedule?: Maybe<WorkSchedule>;
};


export type InternalUserAppointmentRecordingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type InternalUserDisplayNameArgs = {
  options?: InputMaybe<InternalUserDisplayNameOptions>;
};


export type InternalUserDisplay_NameArgs = {
  options?: InputMaybe<InternalUserDisplayNameOptions>;
};


export type InternalUserDraftMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** The connection type for InternalUser. */
export type InternalUserConnection = {
  __typename?: 'InternalUserConnection';
  /** A list of edges. */
  edges: Array<InternalUserEdge>;
  /** A list of nodes. */
  nodes: Array<InternalUser>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type InternalUserDisplayNameOptions = {
  lastInitial?: InputMaybe<Scalars['Boolean']>;
  showSuffix?: InputMaybe<Scalars['Boolean']>;
};

/** An edge in a connection. */
export type InternalUserEdge = {
  __typename?: 'InternalUserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<InternalUser>;
};

export type InternalUserPreferences = {
  __typename?: 'InternalUserPreferences';
  id: Scalars['ID'];
  licensingBodies?: Maybe<Array<LicensingBody>>;
  videoNotificationEnabled?: Maybe<Scalars['Boolean']>;
};

export type LabOrder = {
  __typename?: 'LabOrder';
  accudrawResponse?: Maybe<TemplatedResponse>;
  /** whether or not the patient needs to book an appointment for this lab */
  appointmentNeeded: Scalars['Boolean'];
  /** The date the lab was collected. */
  collectionDate?: Maybe<Scalars['DateTime']>;
  /**
   * The date the lab was collected.
   * @deprecated Please use the camelCase version.
   */
  collection_date?: Maybe<Scalars['DateTime']>;
  dueOn: Scalars['DateTime'];
  fasting: Scalars['Boolean'];
  id: Scalars['ID'];
  measurementOrderTypeSourceId?: Maybe<Scalars['ID']>;
  /** The provider who ordered the labs. */
  orderingProvider: Provider;
  /**
   * The provider who ordered the labs.
   * @deprecated Please use the camelCase version.
   */
  ordering_provider: Provider;
  subtitle?: Maybe<Scalars['String']>;
  /** names of all tests ordered */
  testsOrdered: Array<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Lab Order Outreach Type */
export type LabOrderOutreach = OutreachManagerBatch & {
  __typename?: 'LabOrderOutreach';
  /** The type of order that this batch represents. */
  batchOrderType: OutreachManagerBatchOrderType;
  /** Boolean indicating whether the lab order outreach should create a patient task */
  createPatientTask?: Maybe<Scalars['Boolean']>;
  /** A timestamp noting when the record was created. */
  createdAt: Scalars['DateTime'];
  /** The internal user that created this batch. */
  createdBy: InternalUser;
  /** optional information about this batch of outreach  */
  description?: Maybe<Scalars['String']>;
  /** Boolean indicating whether lab order should be taken fasting or not */
  fasting?: Maybe<Scalars['Boolean']>;
  /** A unique identifier for this record. */
  id: Scalars['ID'];
  /** Lab order types associated with a lab order outreach */
  labOrderTypes: Array<LabOrderType>;
  /** Measurement order type source for a lab order */
  measurementOrderTypeSource?: Maybe<MeasurementOrderTypeSource>;
  /** Boolean indicating whether the lab order's review task should be auto finished if results are normal */
  normalResultAutoFinishesTask: Scalars['Boolean'];
  /** Note on a lab order outreach task */
  note?: Maybe<Scalars['String']>;
  /** Include a note to phlebotomist on the lab order */
  noteToPhleb?: Maybe<Scalars['String']>;
  /** The Patients related to this batch. */
  outreachBatchPatients: OutreachBatchPatientConnection;
  /** Problem type association with a lab order outreach */
  problemType?: Maybe<ProblemType>;
  s3Pointers: Array<S3Pointers>;
  /** Date of lab order outreach to be scheduled */
  scheduledDate: Scalars['DateTime'];
  /** Boolean indicating whether the lab order should be stat or not */
  stat?: Maybe<Scalars['Boolean']>;
  /** Current batch status. */
  status: OutreachManagerStatus;
  /** Title of a lab order outreach */
  title?: Maybe<Scalars['String']>;
  /** A timestamp noting when the record was last modified. */
  updatedAt: Scalars['DateTime'];
};


/** Lab Order Outreach Type */
export type LabOrderOutreachOutreachBatchPatientsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<OutreachBatchPatientStatus>;
};

export type LabOrderType = {
  __typename?: 'LabOrderType';
  id: Scalars['ID'];
  layDescription: Scalars['String'];
  longName?: Maybe<Scalars['String']>;
  shortName: Scalars['String'];
};

/** The connection type for LabOrderType. */
export type LabOrderTypeConnection = {
  __typename?: 'LabOrderTypeConnection';
  /** A list of edges. */
  edges: Array<LabOrderTypeEdge>;
  /** A list of nodes. */
  nodes: Array<LabOrderType>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type LabOrderTypeEdge = {
  __typename?: 'LabOrderTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<LabOrderType>;
};

export type LabOrderTypeResult = SearchResult & {
  __typename?: 'LabOrderTypeResult';
  clinicalAbbreviation: Scalars['String'];
  id: Scalars['ID'];
  label: Scalars['String'];
  layDescription: Scalars['String'];
  node: LabOrderType;
};

export type Label = {
  __typename?: 'Label';
  displayName: Scalars['String'];
  prefix: Scalars['String'];
};

/**
 * Language preferred by a patient, and also used to return a list of languages (AR model: Language).
 * Note that languages spoken by internal users is represented by Types::UserLanguageType.
 *
 */
export type Language = {
  __typename?: 'Language';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  preferred?: Maybe<Scalars['Boolean']>;
};

export type LegalDocument = {
  __typename?: 'LegalDocument';
  buttonHint?: Maybe<Scalars['String']>;
  detailHint?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The text of the document */
  text: Scalars['String'];
  /** The document type */
  type: Scalars['String'];
  /** The document version */
  version: Scalars['String'];
};

export type LicensingBody = {
  __typename?: 'LicensingBody';
  id: Scalars['ID'];
  name: Scalars['String'];
  shortName: Scalars['String'];
  /** @deprecated Please use the camelCase version. */
  short_name: Scalars['String'];
};

export type Link = {
  __typename?: 'Link';
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type LoginWithAmazon = {
  __typename?: 'LoginWithAmazon';
  enabled: Scalars['Boolean'];
  enabledAt?: Maybe<Scalars['DateTime']>;
};

/** Autogenerated input type of MarkPdmpReportAsViewed */
export type MarkPdmpReportAsViewedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  correlationId: Scalars['ID'];
};

/** Autogenerated return type of MarkPdmpReportAsViewed. */
export type MarkPdmpReportAsViewedPayload = {
  __typename?: 'MarkPdmpReportAsViewedPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of MarkRxCartReadyToSign */
export type MarkRxCartReadyToSignInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  rxCartId: Scalars['ID'];
};

/** Autogenerated return type of MarkRxCartReadyToSign. */
export type MarkRxCartReadyToSignPayload = {
  __typename?: 'MarkRxCartReadyToSignPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

export type MassAppointmentCancellationRequest = MassAppointmentChangeRequest & {
  __typename?: 'MassAppointmentCancellationRequest';
  appointmentCancellationReason?: Maybe<AppointmentCancellationReason>;
  appointments: AppointmentConnection;
  createdAt: Scalars['DateTime'];
  displayStatus: Scalars['String'];
  id: Scalars['ID'];
  massAppointmentChangeRequestItems: MassAppointmentChangeRequestItemConnection;
};


export type MassAppointmentCancellationRequestAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type MassAppointmentCancellationRequestMassAppointmentChangeRequestItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type MassAppointmentCancellationRequestItem = MassAppointmentChangeRequestItem & {
  __typename?: 'MassAppointmentCancellationRequestItem';
  appointment: Appointment;
  failureMessage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  status: MassAppointmentChangeRequestItemStatus;
};

export type MassAppointmentChangeRequest = {
  appointments: AppointmentConnection;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  massAppointmentChangeRequestItems: MassAppointmentChangeRequestItemConnection;
};


export type MassAppointmentChangeRequestAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type MassAppointmentChangeRequestMassAppointmentChangeRequestItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export enum MassAppointmentChangeRequestChangeType {
  Cancellation = 'cancellation'
}

/** The connection type for MassAppointmentChangeRequest. */
export type MassAppointmentChangeRequestConnection = {
  __typename?: 'MassAppointmentChangeRequestConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MassAppointmentChangeRequestEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<MassAppointmentChangeRequest>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type MassAppointmentChangeRequestEdge = {
  __typename?: 'MassAppointmentChangeRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<MassAppointmentChangeRequest>;
};

export type MassAppointmentChangeRequestItem = {
  appointment: Appointment;
  id: Scalars['ID'];
  status: MassAppointmentChangeRequestItemStatus;
};

/** The connection type for MassAppointmentChangeRequestItem. */
export type MassAppointmentChangeRequestItemConnection = {
  __typename?: 'MassAppointmentChangeRequestItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MassAppointmentChangeRequestItemEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<MassAppointmentChangeRequestItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type MassAppointmentChangeRequestItemEdge = {
  __typename?: 'MassAppointmentChangeRequestItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<MassAppointmentChangeRequestItem>;
};

export enum MassAppointmentChangeRequestItemStatus {
  Failed = 'failed',
  InProgress = 'in_progress',
  Succeeded = 'succeeded'
}

export type Measurement = {
  __typename?: 'Measurement';
  collectedAt?: Maybe<Scalars['DateTime']>;
  facilityNotes?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The expected value for the result of the test. Either a value france for numerical results or a string indicating an expected result (i.e. 'non reactive' or 'not detected') */
  referenceRange?: Maybe<Scalars['String']>;
  /** The type of measurement. */
  type?: Maybe<Scalars['String']>;
  /** The id of the measurement type */
  typeId?: Maybe<Scalars['Int']>;
  /** The unit of measurement, either in metric or imperial, whichever applies. */
  unit: Scalars['String'];
  /** The result value of the test. Can be string or number. For example 'non reactive' or 78 */
  value: Scalars['String'];
  /** The type of the result of the test. */
  valueType: Scalars['String'];
};

/** An edge in a connection. */
export type MeasurementEdge = {
  __typename?: 'MeasurementEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Measurement>;
};

export type MeasurementOrderTypeSource = {
  __typename?: 'MeasurementOrderTypeSource';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type MeasurementType = {
  __typename?: 'MeasurementType';
  defaultMeasurementUnit?: Maybe<MeasurementUnit>;
  defaultMetric: Scalars['Boolean'];
  id: Scalars['ID'];
  loincCode?: Maybe<Scalars['String']>;
  measurementTypeValueOptions?: Maybe<Array<MeasurementTypeValueOption>>;
  measurementValidationRange?: Maybe<MeasurementValidationRange>;
  name?: Maybe<Scalars['String']>;
};

/** The connection type for MeasurementType. */
export type MeasurementTypeConnection = {
  __typename?: 'MeasurementTypeConnection';
  /** A list of edges. */
  edges: Array<MeasurementTypeEdge>;
  /** A list of nodes. */
  nodes: Array<MeasurementType>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type MeasurementTypeEdge = {
  __typename?: 'MeasurementTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<MeasurementType>;
};

export type MeasurementTypeValueOption = {
  __typename?: 'MeasurementTypeValueOption';
  displayOrder: Scalars['Int'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  label: Scalars['String'];
  sublabel?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type MeasurementUnit = {
  __typename?: 'MeasurementUnit';
  id: Scalars['ID'];
  imperial?: Maybe<Scalars['String']>;
  metric: Scalars['String'];
};

export type MeasurementValidationRange = {
  __typename?: 'MeasurementValidationRange';
  id: Scalars['ID'];
  maxValue?: Maybe<Scalars['Float']>;
  minValue?: Maybe<Scalars['Float']>;
};

/** Interface for common measurement validation rule fields */
export type MeasurementValidationRule = {
  valueType: Scalars['String'];
};

export type MeasurementsValidationRules = {
  __typename?: 'MeasurementsValidationRules';
  diastolicPressure: IntegerMeasurementValidationRule;
  heartRate: IntegerMeasurementValidationRule;
  respiratoryRate: IntegerMeasurementValidationRule;
  systolicPressure: IntegerMeasurementValidationRule;
  temperature: FloatMeasurementValidationRule;
};

export type MedicationUnitOfMeasure = {
  __typename?: 'MedicationUnitOfMeasure';
  abbr: Scalars['String'];
  abbrPlural: Scalars['String'];
  desc: Scalars['String'];
  descPlural: Scalars['String'];
  descPluralPref: Scalars['String'];
  descPref: Scalars['String'];
  id: Scalars['ID'];
};

export type Membership = {
  __typename?: 'Membership';
  canAccessHintPatientPortal: Scalars['Boolean'];
  canCancel: Scalars['Boolean'];
  canReactivate: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  /** Custom Network for B2B Company associated with B2B Plan */
  customNetworks?: Maybe<Array<CustomNetwork>>;
  id: Scalars['ID'];
  isB2b: Scalars['Boolean'];
  /** patient plan's dpc status */
  isDpc: Scalars['Boolean'];
  /** This returns the latest membership event */
  latestEvent?: Maybe<MembershipEvent>;
  membershipGroupSubscription?: Maybe<MembershipGroupSubscription>;
  patientId: Scalars['ID'];
  patientStatus?: Maybe<Scalars['String']>;
  plan?: Maybe<Plan>;
  planId?: Maybe<Scalars['ID']>;
  planType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  trialUntil?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  validUntil?: Maybe<Scalars['DateTime']>;
};

export type MembershipEvent = {
  __typename?: 'MembershipEvent';
  /** The name of the event type */
  eventTypeName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
};

export type MembershipGroupInvite = {
  __typename?: 'MembershipGroupInvite';
  claimCode: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  membershipGroupSubscriptionId: Scalars['ID'];
  status: MembershipGroupInviteStatus;
};

export enum MembershipGroupInviteStatus {
  Claimed = 'claimed',
  Created = 'created',
  FailedToSend = 'failed_to_send',
  InProgress = 'in_progress',
  Rejected = 'rejected',
  Revoked = 'revoked',
  Sent = 'sent'
}

export type MembershipGroupSeat = {
  __typename?: 'MembershipGroupSeat';
  id: Scalars['ID'];
  isMembershipManager: Scalars['Boolean'];
  membershipGroupSubscription?: Maybe<MembershipGroupSubscription>;
  status: MembershipGroupSeatStatus;
  stoppedBy?: Maybe<MembershipGroupSeatStoppedBy>;
};

export enum MembershipGroupSeatStatus {
  Active = 'active',
  Cancelled = 'cancelled',
  Open = 'open',
  Pending = 'pending'
}

export enum MembershipGroupSeatStoppedBy {
  External = 'external',
  OmMember = 'om_member'
}

export type MembershipGroupSubscription = {
  __typename?: 'MembershipGroupSubscription';
  billingCycle?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  maxNumSeats?: Maybe<Scalars['Int']>;
  membershipGroupSeats?: Maybe<Array<MembershipGroupSeat>>;
  occupiedMembershipGroupSeats?: Maybe<Array<MembershipGroupSeat>>;
  redeemableMembershipGroupInvites?: Maybe<Array<MembershipGroupInvite>>;
};

export type Message = {
  __typename?: 'Message';
  code: Scalars['String'];
  text: Scalars['String'];
};

export type MessageRecipient = {
  displayName: Scalars['String'];
  globalId: Scalars['String'];
};

export type MessageSender = {
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
};

export type MessageTemplate = {
  __typename?: 'MessageTemplate';
  body: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** The connection type for MessageTemplate. */
export type MessageTemplateConnection = {
  __typename?: 'MessageTemplateConnection';
  /** A list of edges. */
  edges: Array<MessageTemplateEdge>;
  /** A list of nodes. */
  nodes: Array<MessageTemplate>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type MessageTemplateEdge = {
  __typename?: 'MessageTemplateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<MessageTemplate>;
};

/** Form data from MTS removing MFA */
export type MfaDisableData = {
  /** Free text comments around why MFA is being disabled */
  comments?: InputMaybe<Scalars['String']>;
};

/** Form data from MTS adding an MFA Exception */
export type MfaExceptionData = {
  /** Free text comments around why MFA is being disabled */
  comments?: InputMaybe<Scalars['String']>;
  /** Whenever the exception expires */
  expirationDate: Scalars['String'];
};

export enum MfaFactor {
  Sms = 'sms',
  Voice = 'voice'
}

export type MlBillingProcedureSuggestion = {
  __typename?: 'MlBillingProcedureSuggestion';
  cleanedInputText?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isDismissed: Scalars['Boolean'];
  itemId?: Maybe<Scalars['Int']>;
  itemType?: Maybe<Scalars['String']>;
  predictiveWords?: Maybe<Scalars['String']>;
  probability?: Maybe<Scalars['Float']>;
  problemHistory: ProblemHistory;
  procedureType: Array<Maybe<ProcedureType>>;
  summaryId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type MlFeedbackProcedureSuggestion = {
  __typename?: 'MlFeedbackProcedureSuggestion';
  createdAt: Scalars['DateTime'];
  feedbackCategory: Scalars['String'];
  feedbackText?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mlBillingProcedureSuggestionId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type MultiFactorAuthentication = {
  __typename?: 'MultiFactorAuthentication';
  /** The email address used as a backup factor. */
  email?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  /**
   * If the patient is enrolled in multi-factor authentication, returns the phone number used for MFA. Note that the phone number will be redacted, showing only the last four digits. If the patient is not registered for MFA, then null is returned.
   * @deprecated The word enrolled is superfluous; use phoneNumber instead.
   */
  enrolledPhoneNumber?: Maybe<Scalars['String']>;
  exceptionUntil?: Maybe<Scalars['String']>;
  /** If the patient is enrolled in multi-factor authentication, returns the phone number used for MFA. Note that the phone number will be redacted, showing only the last four digits. If the patient is not registered for MFA, then null is returned. */
  phoneNumber?: Maybe<Scalars['String']>;
  /** The preferred delivery method. Can be sms or voice. */
  preferredDeliveryMethod?: Maybe<MfaFactor>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Lock document to an internal user. */
  acquireDocumentLock?: Maybe<AcquireDocumentLockPayload>;
  /** Triggers the doc processing process to create a PDF from the C-CDA and make it available in the Chart */
  addExternalDocumentToChart?: Maybe<AddExternalDocumentToChartPayload>;
  applyTemplateAppointmentInventory?: Maybe<ApplyTemplateAppointmentInventoryPayload>;
  archiveInsurance?: Maybe<ArchiveInsurancePayload>;
  attachDocumentToProcedure?: Maybe<AttachDocumentToProcedurePayload>;
  /** Attaches ServiceTicketImproperlyClosedOrder to a service ticket. */
  attachServiceTicketImproperlyClosedOrder?: Maybe<AttachServiceTicketImproperlyClosedOrderPayload>;
  authorizeConnectedDevices?: Maybe<AuthorizeConnectedDevicesPayload>;
  /** attempt to populate insurance values from ocr */
  autoVerifyInsuranceOcrResult?: Maybe<AutoVerifyInsuranceOcrResultPayload>;
  bookAppointment?: Maybe<BookAppointmentPayload>;
  bulkUpdateInsurancePlanType?: Maybe<BulkUpdateInsurancePlanPayload>;
  cancelServiceTicketTransmission?: Maybe<CancelServiceTicketTransmissionPayload>;
  chargePatientForCopay?: Maybe<ChargePatientForCopayPayload>;
  chargePatientForServiceTicket?: Maybe<ChargePatientForServiceTicketPayload>;
  checkInsuranceEligibility?: Maybe<CheckInsuranceEligibilityPayload>;
  completeOrdersAndSignNoteForAppointment?: Maybe<CompleteOrdersAndSignNoteForAppointmentPayload>;
  completeProcedureInteraction?: Maybe<CompleteProcedureInteractionPayload>;
  createAdmission?: Maybe<CreateAdmissionPayload>;
  createAppointmentInventory?: Maybe<CreateAppointmentInventoryPayload>;
  createAppointmentRecording?: Maybe<CreateAppointmentRecordingPayload>;
  createBillingDistrictInsuranceNetwork?: Maybe<CreateBillingDistrictInsuranceNetworkPayload>;
  createContact?: Maybe<CreateContactPayload>;
  createHelpRequest?: Maybe<CreateHelpRequestPayload>;
  createInsurance?: Maybe<CreateInsurancePayload>;
  createInsuranceNetworksProviders?: Maybe<CreateInsuranceNetworksProvidersPayload>;
  createInsurancePlan?: Maybe<CreateInsurancePlanPayload>;
  /** Creates a Lab Order outreach manager batch */
  createLabOrderOutreach?: Maybe<CreateLabOrderOutreachPayload>;
  createMassAppointmentCancellationRequest?: Maybe<CreateMassAppointmentCancellationRequestPayload>;
  createMiscClericalNote?: Maybe<CreateMiscClericalNotePayload>;
  createMlFeedbackProcedureSuggestion?: Maybe<CreateMlFeedbackProcedureSuggestionPayload>;
  createOrUpdateInsurancePlanInNetworkStatus?: Maybe<CreateOrUpdateInsurancePlanInNetworkStatusPayload>;
  createOrUpdateMeasurementForProcedure?: Maybe<CreateOrUpdateMeasurementForProcedurePayload>;
  /** Create patient address */
  createPatientAddress?: Maybe<CreatePatientAddressPayload>;
  /** Create patient email */
  createPatientEmail?: Maybe<CreatePatientEmailPayload>;
  /** Create patient emergency contact */
  createPatientEmergencyContact?: Maybe<CreatePatientEmergencyContactPayload>;
  /** Create patient phone number */
  createPatientPhoneNumber?: Maybe<CreatePatientPhoneNumberPayload>;
  createPrescriptionChangeRequests?: Maybe<CreatePrescriptionChangeRequestsPayload>;
  createPrescriptionRenewalRequests?: Maybe<CreatePrescriptionRenewalRequestsPayload>;
  createProblemAttachment?: Maybe<CreateProblemAttachmentPayload>;
  createProcedureInteraction?: Maybe<CreateProcedureInteractionPayload>;
  createProcedureInteractionIndication?: Maybe<CreateProcedureInteractionIndicationPayload>;
  createProgramEnrollmentCareGiver?: Maybe<CreateProgramEnrollmentCareGiverPayload>;
  createScheduleTemplate?: Maybe<CreateScheduleTemplatePayload>;
  createScheduleTemplateVariant?: Maybe<CreateScheduleTemplateVariantPayload>;
  createSmartDraft?: Maybe<CreateSmartDraftPayload>;
  createStripeSetupIntent?: Maybe<CreateStripeSetupIntentPayload>;
  createSummary?: Maybe<CreateSummaryPayload>;
  /** To create survey orders via Outreach Manager */
  createSurveyOrderOutreach?: Maybe<CreateSurveyOrderOutreachPayload>;
  createTask?: Maybe<CreateTaskPayload>;
  createToken?: Maybe<CreateTokenPayload>;
  /** Create User Engagement Message outreach batches. */
  createUserEngagementMessageOutreach?: Maybe<CreateUserEngagementMessageOutreachPayload>;
  createVideoCall?: Maybe<CreateVideoCallPayload>;
  /** Create Visit Follow-Up Order outreach batches. */
  createVisitFollowUpOrderOutreach?: Maybe<CreateVisitFollowUpOrderOutreachPayload>;
  createVoluntaryAlignmentOffer?: Maybe<CreateVoluntaryAlignmentOfferPayload>;
  /** @deprecated Please use the camelCase version. */
  create_prescription_renewal_requests?: Maybe<CreatePrescriptionRenewalRequestsPayload>;
  debugServiceTicketTransmission?: Maybe<DebugServiceTicketTransmissionPayload>;
  deleteAdmission?: Maybe<DeleteAdmissionPayload>;
  deleteBillingDistrictInsuranceNetwork?: Maybe<DeleteBillingDistrictInsuranceNetworkPayload>;
  deleteDocumentFromProcedure?: Maybe<DeleteDocumentFromProcedurePayload>;
  deleteInsuranceCard?: Maybe<DeleteInsuranceCardPayload>;
  deleteInsuranceNetworksProviders?: Maybe<DeleteInsuranceNetworksProvidersPayload>;
  /** Delete patient address by id */
  deletePatientAddress?: Maybe<DeletePatientAddressPayload>;
  /** Delete patient email by id */
  deletePatientEmail?: Maybe<DeletePatientEmailPayload>;
  /** Delete patient emergency contact */
  deletePatientEmergencyContact?: Maybe<DeletePatientEmergencyContactPayload>;
  /** Delete patient phone number by id */
  deletePatientPhoneNumber?: Maybe<DeletePatientPhoneNumberPayload>;
  deleteProblemAttachment?: Maybe<DeleteProblemAttachmentPayload>;
  deleteProcedureInteraction?: Maybe<DeleteProcedureInteractionPayload>;
  deleteProcedureInteractionIndication?: Maybe<DeleteProcedureInteractionIndicationPayload>;
  deleteProgramEnrollmentCareGiver?: Maybe<DeleteProgramEnrollmentCareGiverPayload>;
  /** Delete a schedule template */
  deleteScheduleTemplate?: Maybe<DeleteScheduleTemplatePayload>;
  /** Delete a schedule template variant */
  deleteScheduleTemplateVariant?: Maybe<DeleteScheduleTemplateVariantPayload>;
  deleteServiceTicketItem?: Maybe<DeleteServiceTicketItemPayload>;
  deleteVoluntaryAlignmentOffer?: Maybe<DeleteVoluntaryAlignmentOfferPayload>;
  detachStripePaymentMethod?: Maybe<DetachStripePaymentMethodPayload>;
  dismissMlBillingProcedureSuggestion?: Maybe<DismissMlBillingProcedureSuggestionPayload>;
  duplicateScheduleTemplateVariant?: Maybe<DuplicateScheduleTemplateVariantPayload>;
  endVideoCall?: Maybe<EndVideoCallPayload>;
  exportOrphanedOrders?: Maybe<ExportOrphanedOrdersPayload>;
  forceSignSummary?: Maybe<ForceSignSummaryPayload>;
  /** Mark Prescription Drug Monitoring Program Report as viewed */
  markPdmpReportAsViewed?: Maybe<MarkPdmpReportAsViewedPayload>;
  /** Mark all CS Rxs in cart as ready to sign */
  markRxCartReadyToSign?: Maybe<MarkRxCartReadyToSignPayload>;
  /** Used to let patients not be required to setup MFA */
  patientAddMfaException?: Maybe<PatientAddMfaExceptionPayload>;
  /** Used to let patients delete their existing MFA factors */
  patientDisableMfa?: Maybe<PatientDisableMfaPayload>;
  /** Promotes insurance to the top of the list */
  promoteInsurance?: Maybe<PromoteInsurancePayload>;
  refreshTaskInIndex?: Maybe<RefreshTaskInIndexPayload>;
  rejectSuspectProblem?: Maybe<RejectSuspectProblemPayload>;
  /** Unlock document locked to an internal user. */
  releaseDocumentLock?: Maybe<ReleaseDocumentLockPayload>;
  removeAppointmentInventories?: Maybe<RemoveAppointmentInventoriesPayload>;
  removeAppointmentInventory?: Maybe<RemoveAppointmentInventoryPayload>;
  removePatientPcpAndUpdateOffice?: Maybe<RemovePatientPcpAndUpdateOfficePayload>;
  requestDocusignVoluntaryAlignment?: Maybe<RequestDocusignVoluntaryAlignmentPayload>;
  /** Request a Prescription Drug Monitoring Program Report */
  requestPdmpReport?: Maybe<RequestPdmpReportPayload>;
  rescheduleAppointment?: Maybe<RescheduleAppointmentPayload>;
  resendDirectSignupEmail?: Maybe<ResendDirectSignupEmailPayload>;
  resendVoluntaryAlignmentDocusignRequest?: Maybe<ResendVoluntaryAlignmentDocusignRequestPayload>;
  /** Transitions a membership group invite status to revoked */
  revokeMembershipGroupInvite?: Maybe<RevokeMembershipGroupInvitePayload>;
  /** Run specified commands from the billing dashboard */
  runBillingAdminTask?: Maybe<RunBillingAdminTaskPayload>;
  /** Run a Waystar eligibility check on the given insurance */
  runInsuranceEligibilityAutomaticVerification?: Maybe<RunInsuranceEligibilityAutomaticVerificationPayload>;
  saveAppointmentRecordingDetailedLaunchFeedback?: Maybe<SaveAppointmentRecordingDetailedLaunchFeedbackPayload>;
  saveAppointmentRecordingFeedback?: Maybe<SaveAppointmentRecordingFeedbackPayload>;
  saveSmartDraftFeedback?: Maybe<SaveSmartDraftFeedbackPayload>;
  saveSmartDraftText?: Maybe<SaveSmartDraftTextPayload>;
  sendForgotPasswordEmail?: Maybe<SendForgotPasswordEmailPayload>;
  /** Sends or Re-Sends a Membership Group Invite */
  sendMembershipGroupInvite?: Maybe<SendMembershipGroupInvitePayload>;
  sendRegistrationReminderEmail?: Maybe<SendRegistrationReminderEmailPayload>;
  setProviderIn?: Maybe<SetProviderInPayload>;
  setProviderOut?: Maybe<SetProviderOutPayload>;
  signLegalDocument?: Maybe<SignLegalDocumentPayload>;
  startAppointmentRecording?: Maybe<StartAppointmentRecordingPayload>;
  stopAppointmentRecording?: Maybe<StopAppointmentRecordingPayload>;
  /** Send an ADT sync for given patient */
  syncPatient?: Maybe<SyncPatientPayload>;
  transcribeAppointmentRecording?: Maybe<TranscribeAppointmentRecordingPayload>;
  transmitServiceTicketTransmission?: Maybe<TransmitServiceTicketTransmissionPayload>;
  triggerAppointment?: Maybe<TriggerAppointmentPayload>;
  /** Trigger events like 'file', 'delete' and flag. For availible events on a specific document, query the `document.availableStatusEvents` field. */
  triggerDocumentStatusEvent?: Maybe<TriggerDocumentStatusEventPayload>;
  triggerTask?: Maybe<TriggerTaskPayload>;
  unarchiveInsurance?: Maybe<UnarchiveInsurancePayload>;
  updateAddress?: Maybe<UpdateAddressPayload>;
  updateAdmission?: Maybe<UpdateAdmissionPayload>;
  updateAppointment?: Maybe<UpdateAppointmentPayload>;
  updateAppointmentInventory?: Maybe<UpdateAppointmentInventoryPayload>;
  updateContact?: Maybe<UpdateContactPayload>;
  /** Mutation to update patient's default copay stripe ID */
  updateDefaultCopayStripeId?: Maybe<UpdateDefaultCopayStripeIdPayload>;
  updateImproperlyClosedServiceTicketOrder?: Maybe<UpdateImproperlyClosedServiceTicketOrderPayload>;
  updateInsurance?: Maybe<UpdateInsurancePayload>;
  updateInsurancePlan?: Maybe<UpdateInsurancePlanPayload>;
  updateInternalUserPreferences?: Maybe<UpdateInternalUserPreferencesPayload>;
  updateLabOrder?: Maybe<UpdateLabOrderPayload>;
  /** Can update and create any field that has accepts_nested_attributes_for in the model. For deleting, the additional allow_destroy: true is required */
  updatePatient?: Maybe<UpdatePatientPayload>;
  /** Update patient address */
  updatePatientAddress?: Maybe<UpdatePatientAddressPayload>;
  /** Update patient admin comments */
  updatePatientAdminComments?: Maybe<UpdatePatientAdminCommentsPayload>;
  /** Update patient demographic info */
  updatePatientDemographics?: Maybe<UpdatePatientDemographicsPayload>;
  /** Update patient email */
  updatePatientEmail?: Maybe<UpdatePatientEmailPayload>;
  /** Update patient emergency contact */
  updatePatientEmergencyContact?: Maybe<UpdatePatientEmergencyContactPayload>;
  updatePatientPcp?: Maybe<UpdatePatientPcpPayload>;
  /** Update patient phone number */
  updatePatientPhoneNumber?: Maybe<UpdatePatientPhoneNumberPayload>;
  updatePatientPreferences?: Maybe<UpdatePatientPreferencesPayload>;
  updatePendingDirectSignupAccount?: Maybe<UpdatePendingDirectSignupAccountPayload>;
  updateProcedureInteraction?: Maybe<UpdateProcedureInteractionPayload>;
  updateProfile?: Maybe<UpdateProfilePayload>;
  updateProgramCarePlan?: Maybe<UpdateProgramCarePlanPayload>;
  updateProgramEnrollment?: Maybe<UpdateProgramEnrollmentPayload>;
  updateProgramEnrollmentCareTeam?: Maybe<UpdateProgramEnrollmentCareTeamPayload>;
  updateProgramForAppointment?: Maybe<UpdateProgramForAppointmentPayload>;
  updateProgramVisit?: Maybe<UpdateProgramVisitPayload>;
  /** Creates or updates patient's registration waitlist enrollment */
  updateRegistrationWaitlistEnrollment?: Maybe<UpdateRegistrationWaitlistEnrollmentPayload>;
  /** Update schedule template (folder) details */
  updateScheduleTemplate?: Maybe<UpdateScheduleTemplatePayload>;
  /** Update schedule template variant details */
  updateScheduleTemplateVariant?: Maybe<UpdateScheduleTemplateVariantPayload>;
  /** Updates slots only on the Template Variant */
  updateScheduleTemplateVariantSlots?: Maybe<UpdateScheduleTemplateVariantSlotsPayload>;
  updateServiceTicket?: Maybe<UpdateServiceTicketPayload>;
  updateServiceTicketItem?: Maybe<UpdateServiceTicketItemPayload>;
  updateStripePaymentMethodNickname?: Maybe<UpdateStripePaymentMethodNicknamePayload>;
  updateTask?: Maybe<UpdateTaskPayload>;
  updateVoluntaryAlignmentOffer?: Maybe<UpdateVoluntaryAlignmentOfferPayload>;
  upsertMlFeedbackTodoMessageReassignment?: Maybe<UpsertMlFeedbackTodoMessageReassignmentPayload>;
  /** Validates a claim code, persisting subscription-related data as needed, and returns information on payment provider subscription. */
  validateClaimCode?: Maybe<ValidateClaimCodePayload>;
};


export type MutationAcquireDocumentLockArgs = {
  input: AcquireDocumentLockInput;
};


export type MutationAddExternalDocumentToChartArgs = {
  input: AddExternalDocumentToChartInput;
};


export type MutationApplyTemplateAppointmentInventoryArgs = {
  input: ApplyTemplateAppointmentInventoryInput;
};


export type MutationArchiveInsuranceArgs = {
  input: ArchiveInsuranceInput;
};


export type MutationAttachDocumentToProcedureArgs = {
  input: AttachDocumentToProcedureInput;
};


export type MutationAttachServiceTicketImproperlyClosedOrderArgs = {
  input: AttachServiceTicketImproperlyClosedOrderInput;
};


export type MutationAuthorizeConnectedDevicesArgs = {
  input: AuthorizeConnectedDevicesInput;
};


export type MutationAutoVerifyInsuranceOcrResultArgs = {
  input: AutoVerifyInsuranceOcrResultInput;
};


export type MutationBookAppointmentArgs = {
  input: BookAppointmentInput;
};


export type MutationBulkUpdateInsurancePlanTypeArgs = {
  input: BulkUpdateInsurancePlanInput;
};


export type MutationCancelServiceTicketTransmissionArgs = {
  input: CancelServiceTicketTransmissionInput;
};


export type MutationChargePatientForCopayArgs = {
  input: ChargePatientForCopayInput;
};


export type MutationChargePatientForServiceTicketArgs = {
  input: ChargePatientForServiceTicketInput;
};


export type MutationCheckInsuranceEligibilityArgs = {
  input: CheckInsuranceEligibilityInput;
};


export type MutationCompleteOrdersAndSignNoteForAppointmentArgs = {
  input: CompleteOrdersAndSignNoteForAppointmentInput;
};


export type MutationCompleteProcedureInteractionArgs = {
  input: CompleteProcedureInteractionInput;
};


export type MutationCreateAdmissionArgs = {
  input: CreateAdmissionInput;
};


export type MutationCreateAppointmentInventoryArgs = {
  input: CreateAppointmentInventoryInput;
};


export type MutationCreateAppointmentRecordingArgs = {
  input: CreateAppointmentRecordingInput;
};


export type MutationCreateBillingDistrictInsuranceNetworkArgs = {
  input: CreateBillingDistrictInsuranceNetworkInput;
};


export type MutationCreateContactArgs = {
  input: CreateContactInput;
};


export type MutationCreateHelpRequestArgs = {
  input: CreateHelpRequestInput;
};


export type MutationCreateInsuranceArgs = {
  input: CreateInsuranceInput;
};


export type MutationCreateInsuranceNetworksProvidersArgs = {
  input: CreateInsuranceNetworksProvidersInput;
};


export type MutationCreateInsurancePlanArgs = {
  input: CreateInsurancePlanInput;
};


export type MutationCreateLabOrderOutreachArgs = {
  input: CreateLabOrderOutreachInput;
};


export type MutationCreateMassAppointmentCancellationRequestArgs = {
  input: CreateMassAppointmentCancellationRequestInput;
};


export type MutationCreateMiscClericalNoteArgs = {
  input: CreateMiscClericalNoteInput;
};


export type MutationCreateMlFeedbackProcedureSuggestionArgs = {
  input: CreateMlFeedbackProcedureSuggestionInput;
};


export type MutationCreateOrUpdateInsurancePlanInNetworkStatusArgs = {
  input: CreateOrUpdateInsurancePlanInNetworkStatusInput;
};


export type MutationCreateOrUpdateMeasurementForProcedureArgs = {
  input: CreateOrUpdateMeasurementForProcedureInput;
};


export type MutationCreatePatientAddressArgs = {
  input: CreatePatientAddressInput;
};


export type MutationCreatePatientEmailArgs = {
  input: CreatePatientEmailInput;
};


export type MutationCreatePatientEmergencyContactArgs = {
  input: CreatePatientEmergencyContactInput;
};


export type MutationCreatePatientPhoneNumberArgs = {
  input: CreatePatientPhoneNumberInput;
};


export type MutationCreatePrescriptionChangeRequestsArgs = {
  input: CreatePrescriptionChangeRequestsInput;
};


export type MutationCreatePrescriptionRenewalRequestsArgs = {
  input: CreatePrescriptionRenewalRequestsInput;
};


export type MutationCreateProblemAttachmentArgs = {
  input: CreateProblemAttachmentInput;
};


export type MutationCreateProcedureInteractionArgs = {
  input: CreateProcedureInteractionInput;
};


export type MutationCreateProcedureInteractionIndicationArgs = {
  input: CreateProcedureInteractionIndicationInput;
};


export type MutationCreateProgramEnrollmentCareGiverArgs = {
  input: CreateProgramEnrollmentCareGiverInput;
};


export type MutationCreateScheduleTemplateArgs = {
  input: CreateScheduleTemplateInput;
};


export type MutationCreateScheduleTemplateVariantArgs = {
  input: CreateScheduleTemplateVariantInput;
};


export type MutationCreateSmartDraftArgs = {
  input: CreateSmartDraftInput;
};


export type MutationCreateStripeSetupIntentArgs = {
  input: CreateStripeSetupIntentInput;
};


export type MutationCreateSummaryArgs = {
  input: CreateSummaryInput;
};


export type MutationCreateSurveyOrderOutreachArgs = {
  input: CreateSurveyOrderOutreachInput;
};


export type MutationCreateTaskArgs = {
  input: CreateTaskInput;
};


export type MutationCreateTokenArgs = {
  input: CreateTokenInput;
};


export type MutationCreateUserEngagementMessageOutreachArgs = {
  input: CreateUserEngagementMessageOutreachInput;
};


export type MutationCreateVideoCallArgs = {
  input: CreateVideoCallInput;
};


export type MutationCreateVisitFollowUpOrderOutreachArgs = {
  input: CreateVisitFollowUpOrderOutreachInput;
};


export type MutationCreateVoluntaryAlignmentOfferArgs = {
  input: CreateVoluntaryAlignmentOfferInput;
};


export type MutationCreate_Prescription_Renewal_RequestsArgs = {
  input: CreatePrescriptionRenewalRequestsInput;
};


export type MutationDebugServiceTicketTransmissionArgs = {
  input: DebugServiceTicketTransmissionInput;
};


export type MutationDeleteAdmissionArgs = {
  input: DeleteAdmissionInput;
};


export type MutationDeleteBillingDistrictInsuranceNetworkArgs = {
  input: DeleteBillingDistrictInsuranceNetworkInput;
};


export type MutationDeleteDocumentFromProcedureArgs = {
  input: DeleteDocumentFromProcedureInput;
};


export type MutationDeleteInsuranceCardArgs = {
  input: DeleteInsuranceCardInput;
};


export type MutationDeleteInsuranceNetworksProvidersArgs = {
  input: DeleteInsuranceNetworksProvidersInput;
};


export type MutationDeletePatientAddressArgs = {
  input: DeletePatientAddressInput;
};


export type MutationDeletePatientEmailArgs = {
  input: DeletePatientEmailInput;
};


export type MutationDeletePatientEmergencyContactArgs = {
  input: DeletePatientEmergencyContactInput;
};


export type MutationDeletePatientPhoneNumberArgs = {
  input: DeletePatientPhoneNumberInput;
};


export type MutationDeleteProblemAttachmentArgs = {
  input: DeleteProblemAttachmentInput;
};


export type MutationDeleteProcedureInteractionArgs = {
  input: DeleteProcedureInteractionInput;
};


export type MutationDeleteProcedureInteractionIndicationArgs = {
  input: DeleteProcedureInteractionIndicationInput;
};


export type MutationDeleteProgramEnrollmentCareGiverArgs = {
  input: DeleteProgramEnrollmentCareGiverInput;
};


export type MutationDeleteScheduleTemplateArgs = {
  input: DeleteScheduleTemplateInput;
};


export type MutationDeleteScheduleTemplateVariantArgs = {
  input: DeleteScheduleTemplateVariantInput;
};


export type MutationDeleteServiceTicketItemArgs = {
  input: DeleteServiceTicketItemInput;
};


export type MutationDeleteVoluntaryAlignmentOfferArgs = {
  input: DeleteVoluntaryAlignmentOfferInput;
};


export type MutationDetachStripePaymentMethodArgs = {
  input: DetachStripePaymentMethodInput;
};


export type MutationDismissMlBillingProcedureSuggestionArgs = {
  input: DismissMlBillingProcedureSuggestionInput;
};


export type MutationDuplicateScheduleTemplateVariantArgs = {
  input: DuplicateScheduleTemplateVariantInput;
};


export type MutationEndVideoCallArgs = {
  input: EndVideoCallInput;
};


export type MutationExportOrphanedOrdersArgs = {
  input: ExportOrphanedOrdersInput;
};


export type MutationForceSignSummaryArgs = {
  input: ForceSignSummaryInput;
};


export type MutationMarkPdmpReportAsViewedArgs = {
  input: MarkPdmpReportAsViewedInput;
};


export type MutationMarkRxCartReadyToSignArgs = {
  input: MarkRxCartReadyToSignInput;
};


export type MutationPatientAddMfaExceptionArgs = {
  input: PatientAddMfaExceptionInput;
};


export type MutationPatientDisableMfaArgs = {
  input: PatientDisableMfaInput;
};


export type MutationPromoteInsuranceArgs = {
  input: PromoteInsuranceInput;
};


export type MutationRefreshTaskInIndexArgs = {
  input: RefreshTaskInIndexInput;
};


export type MutationRejectSuspectProblemArgs = {
  input: RejectSuspectProblemInput;
};


export type MutationReleaseDocumentLockArgs = {
  input: ReleaseDocumentLockInput;
};


export type MutationRemoveAppointmentInventoriesArgs = {
  input: RemoveAppointmentInventoriesInput;
};


export type MutationRemoveAppointmentInventoryArgs = {
  input: RemoveAppointmentInventoryInput;
};


export type MutationRemovePatientPcpAndUpdateOfficeArgs = {
  input: RemovePatientPcpAndUpdateOfficeInput;
};


export type MutationRequestDocusignVoluntaryAlignmentArgs = {
  input: RequestDocusignVoluntaryAlignmentInput;
};


export type MutationRequestPdmpReportArgs = {
  input: RequestPdmpReportInput;
};


export type MutationRescheduleAppointmentArgs = {
  input: RescheduleAppointmentInput;
};


export type MutationResendDirectSignupEmailArgs = {
  input: ResendDirectSignupEmailInput;
};


export type MutationResendVoluntaryAlignmentDocusignRequestArgs = {
  input: ResendVoluntaryAlignmentDocusignRequestInput;
};


export type MutationRevokeMembershipGroupInviteArgs = {
  input: RevokeMembershipGroupInviteInput;
};


export type MutationRunBillingAdminTaskArgs = {
  input: RunBillingAdminTaskInput;
};


export type MutationRunInsuranceEligibilityAutomaticVerificationArgs = {
  input: RunInsuranceEligibilityAutomaticVerificationInput;
};


export type MutationSaveAppointmentRecordingDetailedLaunchFeedbackArgs = {
  input: SaveAppointmentRecordingDetailedLaunchFeedbackInput;
};


export type MutationSaveAppointmentRecordingFeedbackArgs = {
  input: SaveAppointmentRecordingFeedbackInput;
};


export type MutationSaveSmartDraftFeedbackArgs = {
  input: SaveSmartDraftFeedbackInput;
};


export type MutationSaveSmartDraftTextArgs = {
  input: SaveSmartDraftTextInput;
};


export type MutationSendForgotPasswordEmailArgs = {
  input: SendForgotPasswordEmailInput;
};


export type MutationSendMembershipGroupInviteArgs = {
  input: SendMembershipGroupInviteInput;
};


export type MutationSendRegistrationReminderEmailArgs = {
  input: SendRegistrationReminderEmailInput;
};


export type MutationSetProviderInArgs = {
  input: SetProviderInInput;
};


export type MutationSetProviderOutArgs = {
  input: SetProviderOutInput;
};


export type MutationSignLegalDocumentArgs = {
  input: SignLegalDocumentInput;
};


export type MutationStartAppointmentRecordingArgs = {
  input: StartAppointmentRecordingInput;
};


export type MutationStopAppointmentRecordingArgs = {
  input: StopAppointmentRecordingInput;
};


export type MutationSyncPatientArgs = {
  input: SyncPatientInput;
};


export type MutationTranscribeAppointmentRecordingArgs = {
  input: TranscribeAppointmentRecordingInput;
};


export type MutationTransmitServiceTicketTransmissionArgs = {
  input: TransmitServiceTicketTransmissionInput;
};


export type MutationTriggerAppointmentArgs = {
  input: TriggerAppointmentInput;
};


export type MutationTriggerDocumentStatusEventArgs = {
  input: TriggerDocumentStatusEventInput;
};


export type MutationTriggerTaskArgs = {
  input: TriggerTaskInput;
};


export type MutationUnarchiveInsuranceArgs = {
  input: UnarchiveInsuranceInput;
};


export type MutationUpdateAddressArgs = {
  input: UpdateAddressInput;
};


export type MutationUpdateAdmissionArgs = {
  input: UpdateAdmissionInput;
};


export type MutationUpdateAppointmentArgs = {
  input: UpdateAppointmentInput;
};


export type MutationUpdateAppointmentInventoryArgs = {
  input: UpdateAppointmentInventoryInput;
};


export type MutationUpdateContactArgs = {
  input: UpdateContactInput;
};


export type MutationUpdateDefaultCopayStripeIdArgs = {
  input: UpdateDefaultCopayStripeIdInput;
};


export type MutationUpdateImproperlyClosedServiceTicketOrderArgs = {
  input: UpdateImproperlyClosedServiceTicketOrderInput;
};


export type MutationUpdateInsuranceArgs = {
  input: UpdateInsuranceInput;
};


export type MutationUpdateInsurancePlanArgs = {
  input: UpdateInsurancePlanInput;
};


export type MutationUpdateInternalUserPreferencesArgs = {
  input: UpdateInternalUserPreferencesInput;
};


export type MutationUpdateLabOrderArgs = {
  input: UpdateLabOrderInput;
};


export type MutationUpdatePatientArgs = {
  input: UpdatePatientInput;
};


export type MutationUpdatePatientAddressArgs = {
  input: UpdatePatientAddressInput;
};


export type MutationUpdatePatientAdminCommentsArgs = {
  input: UpdatePatientAdminCommentsInput;
};


export type MutationUpdatePatientDemographicsArgs = {
  input: UpdatePatientDemographicsInput;
};


export type MutationUpdatePatientEmailArgs = {
  input: UpdatePatientEmailInput;
};


export type MutationUpdatePatientEmergencyContactArgs = {
  input: UpdatePatientEmergencyContactInput;
};


export type MutationUpdatePatientPcpArgs = {
  input: UpdatePatientPcpInput;
};


export type MutationUpdatePatientPhoneNumberArgs = {
  input: UpdatePatientPhoneNumberInput;
};


export type MutationUpdatePatientPreferencesArgs = {
  input: UpdatePatientPreferencesInput;
};


export type MutationUpdatePendingDirectSignupAccountArgs = {
  input: UpdatePendingDirectSignupAccountInput;
};


export type MutationUpdateProcedureInteractionArgs = {
  input: UpdateProcedureInteractionInput;
};


export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationUpdateProgramCarePlanArgs = {
  input: UpdateProgramCarePlanInput;
};


export type MutationUpdateProgramEnrollmentArgs = {
  input: UpdateProgramEnrollmentInput;
};


export type MutationUpdateProgramEnrollmentCareTeamArgs = {
  input: UpdateProgramEnrollmentCareTeamInput;
};


export type MutationUpdateProgramForAppointmentArgs = {
  input: UpdateProgramForAppointmentInput;
};


export type MutationUpdateProgramVisitArgs = {
  input: UpdateProgramVisitInput;
};


export type MutationUpdateRegistrationWaitlistEnrollmentArgs = {
  input: UpdateRegistrationWaitlistEnrollmentInput;
};


export type MutationUpdateScheduleTemplateArgs = {
  input: UpdateScheduleTemplateInput;
};


export type MutationUpdateScheduleTemplateVariantArgs = {
  input: UpdateScheduleTemplateVariantInput;
};


export type MutationUpdateScheduleTemplateVariantSlotsArgs = {
  input: UpdateScheduleTemplateVariantSlotsInput;
};


export type MutationUpdateServiceTicketArgs = {
  input: UpdateServiceTicketInput;
};


export type MutationUpdateServiceTicketItemArgs = {
  input: UpdateServiceTicketItemInput;
};


export type MutationUpdateStripePaymentMethodNicknameArgs = {
  input: UpdateStripePaymentMethodNicknameInput;
};


export type MutationUpdateTaskArgs = {
  input: UpdateTaskInput;
};


export type MutationUpdateVoluntaryAlignmentOfferArgs = {
  input: UpdateVoluntaryAlignmentOfferInput;
};


export type MutationUpsertMlFeedbackTodoMessageReassignmentArgs = {
  input: UpsertMlFeedbackTodoMessageReassignmentInput;
};


export type MutationValidateClaimCodeArgs = {
  input: ValidateClaimCodeInput;
};

/** A user-readable error */
export type MutationError = {
  __typename?: 'MutationError';
  /** A description of the error */
  messages: Array<Scalars['String']>;
  /** Which input value this error came from */
  path: Array<Scalars['String']>;
};

export type NarxMessage = {
  __typename?: 'NarxMessage';
  severity?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type NarxScore = {
  __typename?: 'NarxScore';
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Note = {
  __typename?: 'Note';
  appointmentId?: Maybe<Scalars['Int']>;
  clinicalUrl: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedBy?: Maybe<InternalUser>;
  emailSubject?: Maybe<Scalars['String']>;
  encounterId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  internalUser?: Maybe<InternalUser>;
  internalUserId?: Maybe<Scalars['Int']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  noteBodyText?: Maybe<Scalars['String']>;
  noteType?: Maybe<NoteType>;
  noteTypeId?: Maybe<Scalars['Int']>;
  /** @deprecated This field is replaced by note_type and its subfields */
  noteTypeName?: Maybe<Scalars['String']>;
  redactedBy?: Maybe<InternalUser>;
  signature?: Maybe<Scalars['String']>;
  signedAt?: Maybe<Scalars['DateTime']>;
  signedBy?: Maybe<InternalUser>;
  signedById?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
};

/** The connection type for Note. */
export type NoteConnection = {
  __typename?: 'NoteConnection';
  /** A list of edges. */
  edges: Array<NoteEdge>;
  /** A list of nodes. */
  nodes: Array<Note>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type NoteEdge = {
  __typename?: 'NoteEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Note>;
};

export type NoteTemplate = {
  __typename?: 'NoteTemplate';
  body?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  internalUserId?: Maybe<Scalars['Int']>;
  messageTemplateId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type NoteType = {
  __typename?: 'NoteType';
  name?: Maybe<Scalars['String']>;
  tagList?: Maybe<Array<Scalars['String']>>;
};

export enum NoteTypeSystemName {
  ArchiveEmail = 'ARCHIVE_EMAIL',
  ChronicCareService = 'CHRONIC_CARE_SERVICE',
  ConsultNoteDermatology = 'CONSULT_NOTE_DERMATOLOGY',
  EmailClerical = 'EMAIL_CLERICAL',
  EmailVisit = 'EMAIL_VISIT',
  FailedDirectMessage = 'FAILED_DIRECT_MESSAGE',
  FormstackSurveySubmission = 'FORMSTACK_SURVEY_SUBMISSION',
  FsaHsaReinbursmentRequest = 'FSA_HSA_REINBURSMENT_REQUEST',
  HmrLegacyEhr = 'HMR_LEGACY_EHR',
  InboundDirectMessage = 'INBOUND_DIRECT_MESSAGE',
  LabOrder = 'LAB_ORDER',
  LabOrderPath = 'LAB_ORDER_PATH',
  LabResult = 'LAB_RESULT',
  LogCall = 'LOG_CALL',
  MedicalRecordsRelease = 'MEDICAL_RECORDS_RELEASE',
  MedInformationReleaseAuthorization = 'MED_INFORMATION_RELEASE_AUTHORIZATION',
  MentalHealth = 'MENTAL_HEALTH',
  MiscClerical = 'MISC_CLERICAL',
  MiscClinical = 'MISC_CLINICAL',
  MiscProtected = 'MISC_PROTECTED',
  OfficeVisit = 'OFFICE_VISIT',
  OfficialLetter = 'OFFICIAL_LETTER',
  OnelifeMailer = 'ONELIFE_MAILER',
  PatientSurvey = 'PATIENT_SURVEY',
  PhoneVisit = 'PHONE_VISIT',
  ProcedureOrder = 'PROCEDURE_ORDER',
  RecordsImport = 'RECORDS_IMPORT',
  ReferralRequest = 'REFERRAL_REQUEST',
  RxCsTransmitFailed = 'RX_CS_TRANSMIT_FAILED',
  RxPhoneRequest = 'RX_PHONE_REQUEST',
  RxWebRenewal = 'RX_WEB_RENEWAL',
  RxWebRenewalMobile = 'RX_WEB_RENEWAL_MOBILE',
  TravelRequest = 'TRAVEL_REQUEST',
  TreatMeNow = 'TREAT_ME_NOW',
  TriageVisit = 'TRIAGE_VISIT',
  VideoEncounter = 'VIDEO_ENCOUNTER'
}

export type Observation = {
  __typename?: 'Observation';
  components: Array<ObservationComponent>;
  effectiveDateTime?: Maybe<Scalars['DateTime']>;
  fromDevice: Scalars['Boolean'];
  id: Scalars['ID'];
  integerValue?: Maybe<Scalars['Int']>;
  performerName?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type ObservationComponent = {
  __typename?: 'ObservationComponent';
  code: Scalars['String'];
  integerValue?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export enum OccurrenceOptions {
  Multiple = 'multiple',
  Single = 'single'
}

export type Office = {
  __typename?: 'Office';
  address?: Maybe<Address>;
  appointmentInventories?: Maybe<AppointmentInventoryConnection>;
  b2bCompanyId?: Maybe<Scalars['ID']>;
  b2bCompanyIds?: Maybe<Array<Scalars['ID']>>;
  /** @deprecated Please use the camelCase version. */
  b2b_company_id?: Maybe<Scalars['ID']>;
  closed?: Maybe<Scalars['Boolean']>;
  closureReason?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  closure_reason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directions?: Maybe<Scalars['HTML']>;
  email?: Maybe<Scalars['String']>;
  emailAccount?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  email_account?: Maybe<Scalars['String']>;
  fax: Scalars['String'];
  hours: Scalars['HTML'];
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  image_url?: Maybe<Scalars['String']>;
  isHidden: Scalars['Boolean'];
  /** @deprecated Please use the camelCase version. */
  is_hidden: Scalars['Boolean'];
  labHours?: Maybe<Scalars['HTML']>;
  /** @deprecated Please use the camelCase version. */
  lab_hours?: Maybe<Scalars['HTML']>;
  mapLink?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  map_link?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  officeGroups?: Maybe<Array<Scalars['JSON']>>;
  officeLabels?: Maybe<Array<Scalars['String']>>;
  pediatricServices: Scalars['Boolean'];
  /** @deprecated Please use the camelCase version. */
  pediatric_services: Scalars['Boolean'];
  permalink?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  seesSeniors: Scalars['Boolean'];
  serviceArea?: Maybe<ServiceArea>;
  /** @deprecated Please use the camelCase version. */
  service_area?: Maybe<ServiceArea>;
  structuredHours?: Maybe<Scalars['JSON']>;
  title?: Maybe<Scalars['String']>;
  virtual: Scalars['Boolean'];
};


export type OfficeAppointmentInventoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  startDate: Scalars['DateTime'];
};


export type OfficeImageUrlArgs = {
  size?: InputMaybe<Scalars['String']>;
};


export type OfficeImage_UrlArgs = {
  size?: InputMaybe<Scalars['String']>;
};

export type OpenPracticeMessage = {
  __typename?: 'OpenPracticeMessage';
  createdAt: Scalars['DateTime'];
  hl7Msg?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  operation?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['DateTime'];
};

export enum OpmEligibilityStatus {
  Active = 'active',
  Error = 'error',
  Inactive = 'inactive',
  Pending = 'pending',
  SelfPay = 'self_pay'
}

/** An object from which a notification may originate */
export type OriginatingObject = Appointment | TimelineComment | TimelinePost;

/** The connection type for OriginatingObject. */
export type OriginatingObjectConnection = {
  __typename?: 'OriginatingObjectConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OriginatingObjectEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<OriginatingObject>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of objects in query result */
  totalCount: Scalars['Int'];
  /** Total number of pages based on 'first' argument or max_page_size */
  totalPageCount: Scalars['Int'];
};

/** An edge in a connection. */
export type OriginatingObjectEdge = {
  __typename?: 'OriginatingObjectEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<OriginatingObject>;
};

/** Type for OutreachBatchPatient */
export type OutreachBatchPatient = {
  __typename?: 'OutreachBatchPatient';
  /** Datetime when OutreachBatchPatient was created */
  createdAt: Scalars['DateTime'];
  /** ID of OutreachBatchPatient */
  id: Scalars['ID'];
  /** Patient object */
  patient?: Maybe<Patient>;
  /** Provider object */
  provider?: Maybe<Provider>;
  /** Reason for rejection or failure status */
  reason?: Maybe<Scalars['String']>;
  /** Enum status of OutreachBatchPatient */
  status: OutreachBatchPatientStatus;
  /** Datetime when OutreachBatchPatient was last updated */
  updatedAt: Scalars['DateTime'];
};

/** Custom connection type for OutreachBatchPatientType */
export type OutreachBatchPatientConnection = {
  __typename?: 'OutreachBatchPatientConnection';
  /** Total count of 'completed' patients per batch */
  completedCount: Scalars['Int'];
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OutreachBatchPatientEdge>>>;
  /** Total count of 'failed' patients per batch */
  failedCount: Scalars['Int'];
  /** Total count of 'in_progress' patients per batch */
  inProgressCount: Scalars['Int'];
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<OutreachBatchPatient>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total count of 'rejected' patients per batch */
  rejectedCount: Scalars['Int'];
  /** Total count of patients per batch */
  totalCount: Scalars['Int'];
};

/** Custom edge type for OutreachBatchPatientType */
export type OutreachBatchPatientEdge = {
  __typename?: 'OutreachBatchPatientEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<OutreachBatchPatient>;
};

/** Enum for OutreachBatchPatientType statuses */
export enum OutreachBatchPatientStatus {
  Completed = 'completed',
  Failed = 'failed',
  InProgress = 'in_progress',
  Rejected = 'rejected'
}

export type OutreachManagerAppointmentTypeGroup = {
  __typename?: 'OutreachManagerAppointmentTypeGroup';
  groupName: Scalars['String'];
  id: Scalars['ID'];
};

/** The connection type for OutreachManagerAppointmentTypeGroup. */
export type OutreachManagerAppointmentTypeGroupConnection = {
  __typename?: 'OutreachManagerAppointmentTypeGroupConnection';
  /** A list of edges. */
  edges: Array<OutreachManagerAppointmentTypeGroupEdge>;
  /** A list of nodes. */
  nodes: Array<OutreachManagerAppointmentTypeGroup>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OutreachManagerAppointmentTypeGroupEdge = {
  __typename?: 'OutreachManagerAppointmentTypeGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<OutreachManagerAppointmentTypeGroup>;
};

/** Interface Outreach Manager Batch */
export type OutreachManagerBatch = {
  /** The type of order that this batch represents. */
  batchOrderType: OutreachManagerBatchOrderType;
  /** A timestamp noting when the record was created. */
  createdAt: Scalars['DateTime'];
  /** The internal user that created this batch. */
  createdBy: InternalUser;
  /** optional information about this batch of outreach  */
  description?: Maybe<Scalars['String']>;
  /** A unique identifier for this record. */
  id: Scalars['ID'];
  /** The Patients related to this batch. */
  outreachBatchPatients: OutreachBatchPatientConnection;
  s3Pointers: Array<S3Pointers>;
  /** Current batch status. */
  status: OutreachManagerStatus;
  /** A timestamp noting when the record was last modified. */
  updatedAt: Scalars['DateTime'];
};


/** Interface Outreach Manager Batch */
export type OutreachManagerBatchOutreachBatchPatientsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<OutreachBatchPatientStatus>;
};

/** Custom connection type for OutreachManagerBatchInterfaceType */
export type OutreachManagerBatchInterfaceConnection = {
  __typename?: 'OutreachManagerBatchInterfaceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OutreachManagerBatchInterfaceEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<OutreachManagerBatch>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total count of queried batches */
  totalCount: Scalars['Int'];
};

/** Custom edge type for OutreachManagerBatchInterfaceType */
export type OutreachManagerBatchInterfaceEdge = {
  __typename?: 'OutreachManagerBatchInterfaceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<OutreachManagerBatch>;
};

/** Enum for OutreachManagerBatch batch order types */
export enum OutreachManagerBatchOrderType {
  LabOrderOutreach = 'LabOrderOutreach',
  SurveyOrderOutreach = 'SurveyOrderOutreach',
  UserEngagementMessageOutreach = 'UserEngagementMessageOutreach',
  VisitFollowUpOrderOutreach = 'VisitFollowUpOrderOutreach'
}

export enum OutreachManagerStatus {
  Completed = 'completed',
  Failed = 'failed',
  InProgress = 'in_progress',
  Queued = 'queued'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type Panel = {
  __typename?: 'Panel';
  id: Scalars['ID'];
  officeId: Scalars['ID'];
  preferredBooking: Scalars['Boolean'];
  state: Scalars['String'];
};

export enum ParamOptions {
  Amount = 'amount'
}

export type Patient = MessageRecipient & MessageSender & {
  __typename?: 'Patient';
  activeAllergies: Array<PatientAllergy>;
  activeInsurances: Array<Insurance>;
  activeMedication?: Maybe<PatientMedication>;
  activeMedications: Array<PatientMedication>;
  /** @deprecated Please use the camelCase version. */
  active_insurances: Array<Insurance>;
  adminComments?: Maybe<Scalars['HTML']>;
  ageInYears?: Maybe<Scalars['Int']>;
  /**
   * If the patient has not visited their home office in the last 18 months, suggest the most visited office during that period
   *
   */
  alternateHomeOfficeSuggestion?: Maybe<Office>;
  appointments: AppointmentConnection;
  /** List of patient insurances that have been archived  */
  archivedInsurances: Array<Insurance>;
  avatarFileName?: Maybe<Scalars['String']>;
  billingSyncInProgress: Scalars['Boolean'];
  billingSyncable: Scalars['Boolean'];
  birthdayAlert?: Maybe<Scalars['String']>;
  /** A list of appointment types bookable by the patient */
  bookableAppointmentTypes?: Maybe<Array<AppointmentType>>;
  /** Returns true if the current user can manage access relationships with this patient */
  canManageAccess: Scalars['Boolean'];
  /** Whether current user has permission to create a new family account. */
  canManageFamily: Scalars['Boolean'];
  careTeam: CareTeam;
  chartUrl?: Maybe<Scalars['String']>;
  connectedDevicesAuthorized: Scalars['Boolean'];
  /** How many conversations this patient has had with a given provider */
  conversationCountWith?: Maybe<Scalars['Int']>;
  currentVoluntaryAlignment?: Maybe<VoluntaryAlignmentOffer>;
  deceasedOn?: Maybe<Scalars['Date']>;
  /** Patient default copay Stripe Payment Method details */
  defaultCopayMethod?: Maybe<PaymentMethod>;
  defaultPdmpReportState?: Maybe<Scalars['String']>;
  detailedEthnicities: Array<Ethnicity>;
  displayGender?: Maybe<Scalars['String']>;
  /** Default format: '<preferred-name> <last-name>'. Follows the convention to use the patient's preferred name over their first name when possible. Can be customized by passing in options. */
  displayName: Scalars['String'];
  dob?: Maybe<Scalars['String']>;
  documents: DocumentConnection;
  electronicHealthInformationExport?: Maybe<ElectronicHealthInformationExport>;
  electronicHealthInformationExports: ElectronicHealthInformationExportConnection;
  /** The patient's email address. Returns null if the requestor does not have permission to view this patient's email address. */
  email?: Maybe<Scalars['String']>;
  emailVerified: EmailVerificationStatus;
  /** A list of the patient's emails */
  emails: Array<Email>;
  /**
   * The patient's past, canceled, and no show appointments
   * @deprecated This field has been deprecated in favor of Patient.appointments. With Patient.appointments you can specify a strategy and order params that will meet your needs. If your needs aren't supported, please ask in #pd-guild-graphql for assistance.
   */
  endedAndNotUtilizedAppointments: AppointmentConnection;
  ethnicity?: Maybe<Ethnicity>;
  /** The patient data in FHIR */
  fhir: Scalars['JSON'];
  /** The patient's FHIR identifier */
  fhirIdentifier: Scalars['String'];
  finishedTodos?: Maybe<PrioritizedTasks>;
  firstName: Scalars['String'];
  formerFirstName?: Maybe<Scalars['String']>;
  formerLastName?: Maybe<Scalars['String']>;
  genderDetails?: Maybe<Scalars['String']>;
  globalId: Scalars['String'];
  healthkitAuthorized: Scalars['Boolean'];
  homeOffice?: Maybe<Office>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isDeceased?: Maybe<Scalars['Boolean']>;
  isPediatric: Scalars['Boolean'];
  isRegComplete: Scalars['Boolean'];
  isVirtual: Scalars['Boolean'];
  lastName: Scalars['String'];
  /**
   * The licensing body that this patient belongs to. This is effectively a StateType, though non-states like DC and Puerto Rico are included.
   * If null, it means the patient is part of the virtual service area, and has no usable address.
   *
   * If given a latitude and longitude, the licensing body is looked up based on that location. If reverse-geocoding fails, then we fall back on the above behavior.
   *
   */
  licensingBody?: Maybe<State>;
  memberSinceAt: Scalars['DateTime'];
  membership: Membership;
  /** @deprecated Use patient.multiFactorAuthentication.enabled instead */
  mfaEnabled: Scalars['Boolean'];
  /** @deprecated Use patient.multiFactorAuthentication.exceptionUntil instead */
  mfaExceptionUntil?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  mobileDirectSignupEligible: Scalars['Boolean'];
  mostRecentFitbitMeasurement?: Maybe<Measurement>;
  mostRecentHealthkitMeasurement?: Maybe<Measurement>;
  multiFactorAuthentication: MultiFactorAuthentication;
  /**
   * Returns offices located near the patient.
   * Providing geolocation argument values will return a list of offices in the search radius with distance data.
   * Providing offices_in_state will return all offices in the state with distance data.
   * Providing zip_code will return a list of offices in the search radius with approximate distance data.
   *
   */
  nearbyOffices: PatientOfficeConnection;
  nickname?: Maybe<Scalars['String']>;
  notes: NoteConnection;
  /**
   * The patient's past and current appointments
   * @deprecated This field has been deprecated in favor of Patient.appointments. With Patient.appointments you can specify a strategy and order params that will meet your needs. If your needs aren't supported, please ask in #pd-guild-graphql for assistance.
   */
  pastAndCurrentAppointments: AppointmentConnection;
  /** A list of the patient's pharmacies, with preferred first then ordered by use count descending. */
  patientPharmacies: Array<PatientPharmacy>;
  /** AKA Action Items */
  patientTasks: PatientTasksConnection;
  paymentMethods?: Maybe<Array<PaymentMethod>>;
  /** @deprecated Use `care_team.pcp` instead. */
  pcp?: Maybe<Provider>;
  pendingAdmissions: Array<Admission>;
  /**
   * A list of the patient's pharmacies
   * @deprecated This actually returns PatientPharmacies, not Pharmacies; please use the patientPharmacies field instead.
   */
  pharmacies: Array<PatientPharmacy>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers: Array<PatientPhoneNumber>;
  /** Returns non-draft posts, unless IDs are provided. Patient clients will only receive posts marked as patient-visible. */
  posts: TimelinePostConnection;
  preferences?: Maybe<PatientPreferences>;
  /** patient's preferred email. In a few cases this can be null */
  preferredEmail?: Maybe<Email>;
  preferredLanguage?: Maybe<Language>;
  preferredName: Scalars['String'];
  preferredPharmacy?: Maybe<PatientPharmacy>;
  primaryAddress?: Maybe<Address>;
  primaryInsurance: Insurance;
  /** Returns true if user has access disabled */
  privateAccount: Scalars['Boolean'];
  problems?: Maybe<ProblemConnection>;
  profileImageUrl?: Maybe<Scalars['String']>;
  profileInfo: PatientProfileInfo;
  /** @deprecated Please use the camelCase version. */
  profile_info: PatientProfileInfo;
  /** Returns a list of programs available to a patient based on their available appointment types and problem type. */
  programSuggestions: ProgramSuggestionConnection;
  programs: PatientProgramConnection;
  pronounGroup: Scalars['String'];
  /** This returns the patient's 'pronoun_id', i.e. a single word representing the pronoun group */
  pronouns: Scalars['String'];
  /** Providers seen by the patient and when they last were seen. Not to be used as a proxy for care team */
  providersSeen?: Maybe<PatientProviderConnection>;
  races?: Maybe<Array<Race>>;
  racesAndEthnicities: Array<Race>;
  /** Most recent 5 admissions sorted by admitted_at desc */
  recentAdmissions: Array<Admission>;
  referral?: Maybe<Referral>;
  /** Useful for autocomplete features to show selected patient from id. These same features tend to use Query.patientSearch */
  searchResult: PatientSearchResult;
  serviceArea?: Maybe<ServiceArea>;
  sex: Scalars['String'];
  signupIncomplete: Scalars['Boolean'];
  stripeCustomerId?: Maybe<Scalars['ID']>;
  suffix?: Maybe<Scalars['String']>;
  /** The patient's timezone */
  timezoneTzinfo: Scalars['TZInfo'];
  unreadMessagesCount: Scalars['Int'];
  /**
   * A list of the patient's upcoming and current appointments
   * @deprecated This field has been deprecated in favor of Patient.appointments. With Patient.appointments you can specify a strategy and order params that will meet your needs. If your needs aren't supported, please ask in #pd-guild-graphql for assistance.
   */
  upcomingAndCurrentAppointments: Array<Appointment>;
  /**
   * A list of the patient's upcoming appointments
   * @deprecated This field has been deprecated in favor of Patient.appointments. With Patient.appointments you can specify a strategy and order params that will meet your needs. If your needs aren't supported, please ask in #pd-guild-graphql for assistance.
   */
  upcomingAppointments: Array<Appointment>;
  /**
   * A list of the patient's upcoming appointments by appointment type
   * @deprecated This field has been deprecated in favor of Patient.appointments. With Patient.appointments you can specify a strategy and order params that will meet your needs. If your needs aren't supported, please ask in #pd-guild-graphql for assistance.
   */
  upcomingAppointmentsByType: AppointmentConnection;
  /**
   * A list of the patient's upcoming appointments that are suitable for a discussion of connected device data
   * @deprecated This field has been deprecated in favor of Patient.appointments. With Patient.appointments you can specify a strategy and order params that will meet your needs. If your needs aren't supported, please ask in #pd-guild-graphql for assistance.
   */
  upcomingAppointmentsForConnectedDeviceDiscussion: AppointmentConnection;
  /**
   * A list of the patient's upcoming appointments
   * @deprecated This field has been deprecated in favor of Patient.appointments. With Patient.appointments you can specify a strategy and order params that will meet your needs. If your needs aren't supported, please ask in #pd-guild-graphql for assistance.
   */
  upcoming_appointments: Array<Appointment>;
  /**
   * A list of the patient's upcoming appointments by appointment type
   * @deprecated This field has been deprecated in favor of Patient.appointments. With Patient.appointments you can specify a strategy and order params that will meet your needs. If your needs aren't supported, please ask in #pd-guild-graphql for assistance.
   */
  upcoming_appointments_by_type: AppointmentConnection;
  /** How many visits this patient has had with a given provider */
  visitCountWith?: Maybe<Scalars['Int']>;
  visitedServiceAreas?: Maybe<Array<ServiceArea>>;
  vitals: Vitals;
  /** Returns semantic name of patient integer worry score */
  worryScore: WorryScore;
};


export type PatientActiveMedicationArgs = {
  id: Scalars['ID'];
};


export type PatientAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  appointmentTypeIds?: InputMaybe<Array<Scalars['ID']>>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortOrder?: InputMaybe<SortOrder>;
  strategies?: InputMaybe<Array<AppointmentQueryStrategy>>;
};


export type PatientBookableAppointmentTypesArgs = {
  appointmentCategory?: InputMaybe<Scalars['String']>;
  providerId?: InputMaybe<Scalars['ID']>;
  serviceAreaId?: InputMaybe<Scalars['ID']>;
  topicName?: InputMaybe<Scalars['String']>;
};


export type PatientCareTeamArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<CareTeamType>;
};


export type PatientConversationCountWithArgs = {
  userId: Scalars['ID'];
};


export type PatientDisplayNameArgs = {
  options?: InputMaybe<PatientDisplayNameOptions>;
};


export type PatientDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type PatientElectronicHealthInformationExportArgs = {
  format: ElectronicHealthInformationExportFormat;
  id: Scalars['ID'];
};


export type PatientElectronicHealthInformationExportsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  format?: InputMaybe<ElectronicHealthInformationExportFormat>;
  last?: InputMaybe<Scalars['Int']>;
  states?: InputMaybe<Array<ElectronicHealthInformationExportState>>;
};


export type PatientEndedAndNotUtilizedAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type PatientFinishedTodosArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};


export type PatientLicensingBodyArgs = {
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
};


export type PatientNearbyOfficesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  geolocation?: InputMaybe<Geolocation>;
  last?: InputMaybe<Scalars['Int']>;
  officesInState?: InputMaybe<Scalars['String']>;
  searchRadiusInMiles?: InputMaybe<Scalars['Int']>;
  zipCode?: InputMaybe<Scalars['String']>;
};


export type PatientNotesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  noteTypes?: InputMaybe<Array<NoteTypeSystemName>>;
};


export type PatientPastAndCurrentAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type PatientPatientTasksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  last?: InputMaybe<Scalars['Int']>;
  orderTypes?: InputMaybe<Array<PatientTaskOrderType>>;
  sortBy?: InputMaybe<PatientTaskSort>;
  states?: InputMaybe<Array<PatientTaskState>>;
};


export type PatientPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<TimelinePostSort>;
};


export type PatientProblemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  status: ProblemStatus;
};


export type PatientProgramSuggestionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  problemTypeId: Scalars['ID'];
};


export type PatientProgramsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  systemName?: InputMaybe<ProgramSystemName>;
};


export type PatientProvidersSeenArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type PatientUpcomingAppointmentsByTypeArgs = {
  after?: InputMaybe<Scalars['String']>;
  appointmentTypeIds: Array<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type PatientUpcomingAppointmentsForConnectedDeviceDiscussionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type PatientUpcoming_Appointments_By_TypeArgs = {
  after?: InputMaybe<Scalars['String']>;
  appointmentTypeIds: Array<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type PatientVisitCountWithArgs = {
  includeVideo?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['ID'];
};

/** Autogenerated input type of PatientAddMfaException */
export type PatientAddMfaExceptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  mfaExceptionData: MfaExceptionData;
};

/** Autogenerated return type of PatientAddMfaException. */
export type PatientAddMfaExceptionPayload = {
  __typename?: 'PatientAddMfaExceptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  patient?: Maybe<Patient>;
  success: Scalars['Boolean'];
};

export type PatientAddressInputObject = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  destroy?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  isPreferred?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type PatientAllergy = {
  __typename?: 'PatientAllergy';
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
};

export type PatientCharge = {
  __typename?: 'PatientCharge';
  amountOwedCents: Scalars['Int'];
  amountPaidCents: Scalars['Int'];
  chargeAmount: Scalars['Float'];
  id: Scalars['ID'];
  status: PatientChargeStatus;
};

export type PatientChargePayment = {
  __typename?: 'PatientChargePayment';
  amountPaidTowards: Scalars['String'];
  id: Scalars['ID'];
  paymentType: Scalars['String'];
};

export enum PatientChargeStatus {
  Paid = 'paid',
  Processing = 'processing',
  Refunded = 'refunded',
  Unpaid = 'unpaid',
  Waived = 'waived'
}

export type PatientDemographics = {
  deceasedOn?: InputMaybe<Scalars['Date']>;
  /** an array of codes representing ethnicities */
  detailedEthnicities?: InputMaybe<Array<Scalars['String']>>;
  dob?: InputMaybe<Scalars['Date']>;
  /** snomed code of the high-level ethnicity category */
  ethnicity?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  formerFirstName?: InputMaybe<Scalars['String']>;
  formerLastName?: InputMaybe<Scalars['String']>;
  /** a free text string providing more information about the patient's gender */
  genderDetails?: InputMaybe<Scalars['String']>;
  /** the snomed code of the gender identity */
  genderIdentity?: InputMaybe<Scalars['String']>;
  isDeceased?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  /** the id of the preferred language */
  preferredLanguage?: InputMaybe<Scalars['ID']>;
  preferredName?: InputMaybe<Scalars['String']>;
  /** the string description of the pronoun set used i.e. 'They/Them' */
  pronounGroup?: InputMaybe<Scalars['String']>;
  /** the pronoun id of the pronoun set used i.e. 'they' */
  pronouns?: InputMaybe<Scalars['String']>;
  /** an array of codes representing races */
  races?: InputMaybe<Array<Scalars['String']>>;
  /** an array of codes representing either a race or detailed ethnicity */
  racesAndEthnicities?: InputMaybe<Array<Scalars['String']>>;
  sex?: InputMaybe<Scalars['Boolean']>;
  sexAssignedAtBirth?: InputMaybe<Scalars['String']>;
  /** the snomed code of the sexual orientation */
  sexualOrientation?: InputMaybe<Scalars['String']>;
  suffix?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of PatientDisableMfa */
export type PatientDisableMfaInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  mfaDisableData: MfaDisableData;
};

/** Autogenerated return type of PatientDisableMfa. */
export type PatientDisableMfaPayload = {
  __typename?: 'PatientDisableMfaPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** @deprecated Use `mutationErrors` instead */
  errors?: Maybe<Scalars['JSON']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  patient?: Maybe<Patient>;
  success: Scalars['Boolean'];
};

export type PatientDisplayNameOptions = {
  /** Makes the last name appear first (Lastnamerson, Firsty) */
  lastFirst?: InputMaybe<Scalars['Boolean']>;
  middleName?: InputMaybe<Scalars['Boolean']>;
  preferredName?: InputMaybe<Scalars['Boolean']>;
  suffix?: InputMaybe<Scalars['Boolean']>;
};

export type PatientEmailInput = {
  email: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  isPreferred?: InputMaybe<Scalars['Boolean']>;
  kind: EmailKind;
};

export type PatientMedication = {
  __typename?: 'PatientMedication';
  genericSynonym?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  isGeneric?: Maybe<Scalars['Boolean']>;
  isOutOfDate: Scalars['Boolean'];
  isRenewable: Scalars['Boolean'];
  latestPatientMedicationRegimen?: Maybe<PatientMedicationRegimen>;
  name: Scalars['String'];
  pendingRenewalRequest?: Maybe<RenewalRequest>;
  /** Can be 'pending' or 'not_pending' */
  renewalStatus: Scalars['String'];
  routeIsActive: Scalars['Boolean'];
};

export type PatientMedicationRegimen = {
  __typename?: 'PatientMedicationRegimen';
  id: Scalars['ID'];
  layInstructionsForUse?: Maybe<Scalars['String']>;
  patientMedication?: Maybe<PatientMedication>;
  startedAt?: Maybe<Scalars['DateTime']>;
};

/** The connection type for Office. */
export type PatientOfficeConnection = {
  __typename?: 'PatientOfficeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PatientOfficeEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Office>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Extra metadata modeling the general relationship between patients and offices. */
export type PatientOfficeEdge = {
  __typename?: 'PatientOfficeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** Distance between the patient and the office. */
  distanceInMiles: Scalars['Float'];
  /** The item at the end of the edge. */
  node?: Maybe<Office>;
};

export type PatientPharmacy = {
  __typename?: 'PatientPharmacy';
  customerCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isPreferred: Scalars['Boolean'];
  /** @deprecated Please use lastUsedAtDate instead; this field is mistakenly string-type. */
  lastUsedAt?: Maybe<Scalars['String']>;
  lastUsedAtDate?: Maybe<Scalars['DateTime']>;
  patientId: Scalars['Int'];
  pharmacy: Pharmacy;
  pharmacyId: Scalars['Int'];
  useCount: Scalars['Int'];
};

export type PatientPhoneNumber = {
  __typename?: 'PatientPhoneNumber';
  ext?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isPreferred: Scalars['Boolean'];
  kind: PatientPhoneNumberKind;
  number: Scalars['String'];
  numberFormatted: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PatientPhoneNumberInput = {
  id?: InputMaybe<Scalars['ID']>;
  isPreferred?: InputMaybe<Scalars['Boolean']>;
  kind: PhoneNumberKind;
  number: Scalars['String'];
};

export type PatientPhoneNumberInputObject = {
  _destroy?: InputMaybe<Scalars['Boolean']>;
  ext?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isPreferred?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<PatientPhoneNumberKind>;
  number?: InputMaybe<Scalars['String']>;
};

export enum PatientPhoneNumberKind {
  Home = 'home',
  Mobile = 'mobile',
  Other = 'other',
  Work = 'work'
}

export type PatientPreferences = {
  __typename?: 'PatientPreferences';
  acceptsClinicalEmails: Scalars['Boolean'];
  acceptsOnlineBills: Scalars['Boolean'];
  acceptsPatientTaskEmails?: Maybe<Scalars['Boolean']>;
  acceptsPatientTaskPush?: Maybe<Scalars['Boolean']>;
  avsHideWeight: Scalars['Boolean'];
  chartHidden: Scalars['Boolean'];
  connectedDevicesOptIn?: Maybe<Scalars['Boolean']>;
  fullTextNotifications?: Maybe<Scalars['Boolean']>;
  healthInformationExchangeLegalTextVersion?: Maybe<Scalars['Int']>;
  healthInformationExchangeOptIn?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  loginWithAmazon: LoginWithAmazon;
  marketingEmailOptOut?: Maybe<Scalars['Boolean']>;
  needsLanguageInterpreter?: Maybe<Scalars['Boolean']>;
};

export type PatientProfileInfo = {
  __typename?: 'PatientProfileInfo';
  /** @deprecated We are working to move this field to the patient type. However, it may or may not yet be migrated. If it exists on patient, please use it there. If not, consider migrating it! */
  addresses: Array<Address>;
  /** @deprecated Please use this field on the Patient type. */
  ageInYears?: Maybe<Scalars['Int']>;
  allPhoneNumberKinds: Array<PatientPhoneNumberKind>;
  allPronounGroups: Array<Scalars['String']>;
  allRelationshipTypes: Array<Scalars['String']>;
  allSexes: Array<Scalars['String']>;
  /** @deprecated Please use this field on the Patient type. */
  deceasedOn?: Maybe<Scalars['Date']>;
  /** @deprecated Please use this field on the Patient type. */
  detailedEthnicities: Array<Ethnicity>;
  /**
   * @deprecated the word 'display' is redundant. Use pronounGroup instead. Any other representations of the
   *           pronouns are only a backend concern.
   */
  displayPronouns: Scalars['String'];
  /** @deprecated Please use this field on the Patient type. */
  dob?: Maybe<Scalars['String']>;
  /** @deprecated We are working to move this field to the patient type. However, it may or may not yet be migrated. If it exists on patient, please use it there. If not, consider migrating it! */
  emergencyContacts?: Maybe<Array<EmergencyContact>>;
  /** @deprecated We are working to move this field to the patient type. However, it may or may not yet be migrated. If it exists on patient, please use it there. If not, consider migrating it! */
  employerName?: Maybe<Scalars['String']>;
  /** @deprecated We are working to move this field to the patient type. However, it may or may not yet be migrated. If it exists on patient, please use it there. If not, consider migrating it! */
  employerZipCode?: Maybe<Scalars['String']>;
  /** @deprecated Please use this field on the Patient type. */
  ethnicity?: Maybe<Ethnicity>;
  /** @deprecated Please use this field on the Patient type. */
  firstName: Scalars['String'];
  /** @deprecated Please use this field on the Patient type. */
  formerFirstName?: Maybe<Scalars['String']>;
  /** @deprecated Please use this field on the Patient type. */
  formerLastName?: Maybe<Scalars['String']>;
  /** @deprecated Please use this field on the Patient type. */
  genderDetails?: Maybe<Scalars['String']>;
  genderIdentity?: Maybe<GenderIdentity>;
  id: Scalars['ID'];
  insuranceVerifiedWithinPastYear: Scalars['Boolean'];
  insurancesManuallyVerifiedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated Please use this field on the Patient type. */
  isDeceased?: Maybe<Scalars['Boolean']>;
  /** @deprecated We are working to move this field to the patient type. However, it may or may not yet be migrated. If it exists on patient, please use it there. If not, consider migrating it! */
  isPregnant: Scalars['Boolean'];
  /**
   * Whether the patient is an unborn child, can be null if the child_characteristic association doesn't exist
   * @deprecated We are working to move this field to the patient type. However, it may or may not yet be migrated. If it exists on patient, please use it there. If not, consider migrating it!
   */
  isUnborn?: Maybe<Scalars['Boolean']>;
  /** @deprecated We are working to move this field to the patient type. However, it may or may not yet be migrated. If it exists on patient, please use it there. If not, consider migrating it! */
  languages?: Maybe<Array<Language>>;
  /** @deprecated Please use this field on the Patient type. */
  lastName: Scalars['String'];
  /** @deprecated Please use this field on the Patient type. */
  middleName?: Maybe<Scalars['String']>;
  onlyHasSelfPay: Scalars['Boolean'];
  /** @deprecated Please use this field on the Patient type. */
  phoneNumber?: Maybe<Scalars['String']>;
  /** @deprecated Please use this field on the Patient type. */
  phoneNumbers: Array<PatientPhoneNumber>;
  /** @deprecated Please use this field on the Patient type. */
  preferredLanguage?: Maybe<Language>;
  /** @deprecated Please use this field on the Patient type. */
  preferredName?: Maybe<Scalars['String']>;
  /** @deprecated Please use this field on the Patient type. */
  primaryAddress?: Maybe<Address>;
  /** @deprecated Please use this field on the Patient type. */
  profileImageUrl?: Maybe<Scalars['String']>;
  /** @deprecated We are working to move this field to the patient type. However, it may or may not yet be migrated. If it exists on patient, please use it there. If not, consider migrating it! */
  pronounGroup: Scalars['String'];
  /** @deprecated Please use this field on the Patient type. */
  races?: Maybe<Array<Race>>;
  /** @deprecated We are working to move this field to the patient type. However, it may or may not yet be migrated. If it exists on patient, please use it there. If not, consider migrating it! */
  sex: Scalars['String'];
  sexAssignedAtBirth?: Maybe<Scalars['String']>;
  sexualOrientation?: Maybe<SexualOrientation>;
  /** @deprecated Please use this field on the Patient type. */
  suffix?: Maybe<Scalars['String']>;
};

/** The connection type for Program. */
export type PatientProgramConnection = {
  __typename?: 'PatientProgramConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PatientProgramEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Program>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Describes the relationship between patients and programs */
export type PatientProgramEdge = {
  __typename?: 'PatientProgramEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Program>;
  programEnrollment: ProgramEnrollment;
};

/** The connection type for Provider. */
export type PatientProviderConnection = {
  __typename?: 'PatientProviderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PatientProviderEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Provider>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Extra metadata modeling the general relationship between patients and providers. */
export type PatientProviderEdge = {
  __typename?: 'PatientProviderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The last time the patient was seen by the provider */
  lastSeenAt: Scalars['DateTime'];
  /** The item at the end of the edge. */
  node?: Maybe<Provider>;
};

export type PatientProviderInput = {
  patientId: Scalars['ID'];
  /** The Internal User ID that will be used to sign the Lab Order. */
  providerId: Scalars['ID'];
};

export type PatientSearchResult = SearchResult & {
  __typename?: 'PatientSearchResult';
  dob?: Maybe<Scalars['Date']>;
  emails: Array<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  node: Patient;
  phones: Array<Scalars['String']>;
};

/** Enum for PatientTaskType order types */
export enum PatientTaskOrderType {
  BasicFollowUpOrder = 'BasicFollowUpOrder',
  ConnectedDeviceOrder = 'ConnectedDeviceOrder',
  ConsultOrder = 'ConsultOrder',
  GenericFollowUpOrder = 'GenericFollowUpOrder',
  LabOrder = 'LabOrder',
  ProcedureOrder = 'ProcedureOrder',
  SurveyOrder = 'SurveyOrder',
  VaccineOrder = 'VaccineOrder',
  VisitFollowUpOrder = 'VisitFollowUpOrder'
}

export enum PatientTaskSort {
  /** Sorts patient tasks by updated_at date in descending order. */
  UpdatedAtDesc = 'UpdatedAtDesc'
}

/** Enum for PatientTaskType states */
export enum PatientTaskState {
  Assigned = 'assigned',
  Cancelled = 'cancelled',
  Completed = 'completed',
  Declined = 'declined',
  Expired = 'expired'
}

/** The connection type for PatientTasks. */
export type PatientTasksConnection = {
  __typename?: 'PatientTasksConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PatientTasksEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PatientTasksEdge = {
  __typename?: 'PatientTasksEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  billingName?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  cardNickname?: Maybe<Scalars['String']>;
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  id: Scalars['String'];
  isDefaultCopay: Scalars['Boolean'];
  last4: Scalars['String'];
};

export type PdmpNonParticipatingState = {
  __typename?: 'PdmpNonParticipatingState';
  id: Scalars['ID'];
  shortName: Scalars['String'];
};

export type PdmpReportEvent = {
  __typename?: 'PdmpReportEvent';
  correlationId?: Maybe<Scalars['ID']>;
  eventType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  patient?: Maybe<Patient>;
  requestedLocation?: Maybe<Scalars['String']>;
  viewedAt?: Maybe<Scalars['DateTime']>;
  viewedBy?: Maybe<Provider>;
};

export type Pharmacy = {
  __typename?: 'Pharmacy';
  address?: Maybe<Address>;
  /** Markdown-encoded */
  details?: Maybe<Scalars['Markdown']>;
  dispensabilityAttributes?: Maybe<PharmacyMedicationAttributesResponse>;
  displayAddressStreet?: Maybe<Scalars['String']>;
  /** Pharmacy brand name if it exists; otherwise, the pharmacy name title-ized */
  displayName: Scalars['String'];
  id: Scalars['ID'];
  isAmazonPharmacy: Scalars['Boolean'];
  isMailOrder: Scalars['Boolean'];
  /** @deprecated Use dispensability_attributes instead; the format has been updated for retrieving medication attributes. */
  medicationAttributes?: Maybe<Array<PharmacyMedicationAttributes>>;
  mobileIconUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  workPhoneNumber?: Maybe<Scalars['String']>;
};


export type PharmacyDispensabilityAttributesArgs = {
  pendingNewRxIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type PharmacyMedicationAttributesArgs = {
  pendingNewRxIds?: InputMaybe<Array<Scalars['ID']>>;
};

/** The connection type for Pharmacy. */
export type PharmacyConnection = {
  __typename?: 'PharmacyConnection';
  /** A list of edges. */
  edges: Array<PharmacyEdge>;
  /** A list of nodes. */
  nodes: Array<Pharmacy>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PharmacyEdge = {
  __typename?: 'PharmacyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Pharmacy>;
};

export type PharmacyMedicationAttributes = {
  __typename?: 'PharmacyMedicationAttributes';
  availabilityStatus?: Maybe<PharmacyMedicationAvailabilityStatusEnum>;
  id?: Maybe<Scalars['ID']>;
};

export type PharmacyMedicationAttributesResponse = {
  __typename?: 'PharmacyMedicationAttributesResponse';
  attributes?: Maybe<Array<PharmacyMedicationAttributes>>;
  deliveryPromise?: Maybe<PharmacyMedicationDeliveryPromise>;
};

export enum PharmacyMedicationAvailabilityStatusEnum {
  Available = 'available',
  CurrentlyUnavailable = 'currently_unavailable',
  DoesNotFill = 'does_not_fill',
  Unavailable = 'unavailable',
  Unknown = 'unknown'
}

export type PharmacyMedicationDeliveryPromise = {
  __typename?: 'PharmacyMedicationDeliveryPromise';
  bannerMessage?: Maybe<Scalars['String']>;
  confidence?: Maybe<PharmacyMedicationDeliveryPromiseConfidenceEnum>;
  message?: Maybe<Scalars['String']>;
  patientCutoffMessage?: Maybe<Scalars['String']>;
  pharmacyListingMessage?: Maybe<Scalars['String']>;
  providerCutoffMessage?: Maybe<Scalars['String']>;
  sameDayPromiseType?: Maybe<SameDayPromiseMessagingEnum>;
};

export enum PharmacyMedicationDeliveryPromiseConfidenceEnum {
  High = 'high',
  Low = 'low'
}

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  ext?: Maybe<Scalars['String']>;
  formattedNumber: Scalars['String'];
  id: Scalars['ID'];
  isPreferred?: Maybe<Scalars['Boolean']>;
  kind: PhoneNumberKind;
  number: Scalars['String'];
};

export type PhoneNumberAttributes = {
  _destroy?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  ext?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  kind?: InputMaybe<PhoneNumberKind>;
  number?: InputMaybe<Scalars['String']>;
};

export enum PhoneNumberKind {
  BackOffice = 'back_office',
  Fax = 'fax',
  Home = 'home',
  Mobile = 'mobile',
  Other = 'other',
  Pager = 'pager',
  PatientAssistance = 'patient_assistance',
  Work = 'work'
}

/** Interface for different plans */
export type Plan = {
  id: Scalars['ID'];
  kind: Scalars['String'];
  name: Scalars['String'];
};

export type PrescribingCredential = {
  __typename?: 'PrescribingCredential';
  deaLicenseNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  licenseNumber: Scalars['String'];
  serviceArea?: Maybe<ServiceArea>;
  stateOfIssue?: Maybe<State>;
};

/** We filter location data differently among providers and Ops roles. We care about licensing for providers, which is reflected in LicensingBodyType. For Ops roles, locations info will be reflected as ServiceAreaType. */
export type PrioritizedTaskLocation = LicensingBody | ServiceArea;

export type PrioritizedTasks = {
  __typename?: 'PrioritizedTasks';
  /** These are raw aggregations results from the Elasticsearch query. WARNING: The structure of this field is subject to change with the change of the Elasticsearch index. A typed `aggregations`field should be added if more stability is desired. */
  aggregationsRaw?: Maybe<Scalars['JSON']>;
  /** These are raw hits results from the Elasticsearch query. WARNING: The structure of this field is subject to change with the change of the Elasticsearch index. A typed `hits`field should be added if more stability is desired. */
  hitsRaw?: Maybe<Array<Scalars['JSON']>>;
  tasks?: Maybe<Array<Task>>;
  totalHits: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export enum PrioritizedTasksBucket {
  /** All tasks for the current user or passed in user */
  All = 'all',
  /** All tasks assigned to the specified user */
  AssignedTo = 'assigned_to',
  /** Completed tasks from the past 7 days for the current user or passed in user */
  Finished = 'finished',
  /** Tasks that are assigned to me */
  Mine = 'mine',
  /** Tasks assigned to me including waiting or deferred tasks */
  MineWaiting = 'mine_waiting',
  /** All tasks with notifications and assigned to the current user */
  Notifications = 'notifications',
  /** Tasks that are in my panel */
  Panel = 'panel',
  /** All unfinished tasks for the specified patient */
  Patient = 'patient',
  /** All tasks that are part of a task filter */
  TaskFilter = 'task_filter',
  /** Waiting or deferred tasks that are assigned to me */
  Waiting = 'waiting'
}

export enum Priority {
  High = 'high',
  Normal = 'normal'
}

export type Problem = {
  __typename?: 'Problem';
  aAndP?: Maybe<Scalars['String']>;
  attachments: ProblemAttachmentConnection;
  createdAt: Scalars['DateTime'];
  createdBy: InternalUser;
  icd10Codes: Array<ProblemCode>;
  id: Scalars['ID'];
  layDescription: Scalars['String'];
  problemType: ProblemType;
  reviewedAt?: Maybe<Scalars['DateTime']>;
  reviewedBy?: Maybe<InternalUser>;
  status: ProblemStatus;
};


export type ProblemAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ProblemAttachableInterface = {
  id: Scalars['ID'];
};

export enum ProblemAttachmentAttachmentEnum {
  Document = 'Document',
  PatientMedication = 'PatientMedication'
}

/** The connection type for ProblemAttachableInterface. */
export type ProblemAttachmentConnection = {
  __typename?: 'ProblemAttachmentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProblemAttachmentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ProblemAttachableInterface>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Describes the relationship between problems and attachments */
export type ProblemAttachmentEdge = {
  __typename?: 'ProblemAttachmentEdge';
  createdAt: Scalars['DateTime'];
  createdBy: InternalUser;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  id: Scalars['ID'];
  /** The item at the end of the edge. */
  node?: Maybe<ProblemAttachableInterface>;
};

export type ProblemCode = {
  __typename?: 'ProblemCode';
  code: Scalars['String'];
  id: Scalars['ID'];
};

export type ProblemCodeExtension = {
  __typename?: 'ProblemCodeExtension';
  description: Scalars['String'];
  id: Scalars['ID'];
};

export type ProblemCodeLocation = {
  __typename?: 'ProblemCodeLocation';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** The connection type for Problem. */
export type ProblemConnection = {
  __typename?: 'ProblemConnection';
  /** A list of edges. */
  edges: Array<ProblemEdge>;
  /** A list of nodes. */
  nodes: Array<Problem>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ProblemEdge = {
  __typename?: 'ProblemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Problem>;
};

export type ProblemHistory = {
  __typename?: 'ProblemHistory';
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  problemCodeLocation?: Maybe<ProblemCodeLocation>;
  problemId: Scalars['Int'];
  problemType?: Maybe<ProblemType>;
};

export enum ProblemStatus {
  Current = 'current',
  Mistaken = 'mistaken',
  Rejected = 'rejected',
  Resolved = 'resolved',
  Suspected = 'suspected',
  Unconfirmed = 'unconfirmed'
}

export type ProblemType = {
  __typename?: 'ProblemType';
  clinicalDescription: Scalars['String'];
  commonProcedureTypes?: Maybe<Array<ProcedureType>>;
  id: Scalars['ID'];
};

/** The connection type for ProblemType. */
export type ProblemTypeConnection = {
  __typename?: 'ProblemTypeConnection';
  /** A list of edges. */
  edges: Array<ProblemTypeEdge>;
  /** A list of nodes. */
  nodes: Array<ProblemType>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ProblemTypeEdge = {
  __typename?: 'ProblemTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ProblemType>;
};

export type ProblemTypeResult = SearchResult & {
  __typename?: 'ProblemTypeResult';
  clinicalAbbreviation?: Maybe<Scalars['String']>;
  clinicalDescription: Scalars['String'];
  id: Scalars['ID'];
  label: Scalars['String'];
  layDescription: Scalars['String'];
  node: ProblemType;
};

export type ProcedureCode = {
  __typename?: 'ProcedureCode';
  code: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
};

/** Type for procedure form type object */
export type ProcedureFormType = {
  __typename?: 'ProcedureFormType';
  id: Scalars['ID'];
  requiredOccurrences?: Maybe<OccurrenceOptions>;
  requiredParams?: Maybe<Array<ParamOptions>>;
  systemName?: Maybe<Scalars['String']>;
};

/** Type for procedure interaction object */
export type ProcedureInteraction = {
  __typename?: 'ProcedureInteraction';
  completedAt?: Maybe<Scalars['DateTime']>;
  completedBy?: Maybe<InternalUser>;
  createdAt: Scalars['DateTime'];
  dynamicForm?: Maybe<Scalars['JSON']>;
  formData?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  indications: Array<Maybe<Indication>>;
  measurements?: Maybe<Array<Measurement>>;
  patient: Patient;
  procedureCodes: Array<ProcedureCode>;
  procedureSummary?: Maybe<Scalars['String']>;
  procedureType?: Maybe<ProcedureType>;
  resultDocuments: Array<Document>;
  resultsInterpretation?: Maybe<Scalars['String']>;
  reviewedAt?: Maybe<Scalars['DateTime']>;
  reviewedBy?: Maybe<InternalUser>;
  state: Scalars['String'];
  totalComments?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type ProcedureMeasurementInputObject = {
  /** The id of the measurement type associated with this measurement. */
  typeId: Scalars['ID'];
  /** The result value of the test. Can be string or number. For example 'non reactive' or 78. Null values will soft delete the measurement. */
  value?: InputMaybe<Scalars['String']>;
};

/** Type for procedure order object */
export type ProcedureOrder = {
  __typename?: 'ProcedureOrder';
  id: Scalars['ID'];
  measurements?: Maybe<Array<Measurement>>;
};

/** Source for a procedure */
export type ProcedureOrigin = ProcedureInteraction | ProcedureOrder;

export enum ProcedureOriginType {
  ProcedureInteraction = 'ProcedureInteraction',
  ProcedureOrder = 'ProcedureOrder'
}

export type ProcedureType = {
  __typename?: 'ProcedureType';
  administrationGuidance?: Maybe<Scalars['String']>;
  billableUnitOfMeasure?: Maybe<MedicationUnitOfMeasure>;
  billableUnitOfMeasureId?: Maybe<Scalars['Int']>;
  billingUnitOfMeasureHeading?: Maybe<Scalars['String']>;
  clinicalDescription: Scalars['String'];
  clinicalGuidance?: Maybe<Scalars['String']>;
  diagnosticGroup?: Maybe<DiagnosticGroup>;
  externalOnly?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isBillableAmountRequired: Scalars['Boolean'];
  isDocumentReferenceRequired: Scalars['Boolean'];
  isKidneyFunctionDocRequired: Scalars['Boolean'];
  isLateralityRequired: Scalars['Boolean'];
  isPregnancyDocRequired: Scalars['Boolean'];
  isResultsInterpretationRequired: Scalars['Boolean'];
  layDescription: Scalars['String'];
  maxBillableAmount?: Maybe<Scalars['Float']>;
  measurementTypes: Array<MeasurementType>;
  orderable: Scalars['Boolean'];
  performable: Scalars['Boolean'];
  procedureCodes?: Maybe<Array<Maybe<ProcedureCode>>>;
  procedureFormType?: Maybe<ProcedureFormType>;
};

export type Program = {
  __typename?: 'Program';
  appointmentTypes?: Maybe<Array<AppointmentType>>;
  careTeamSupport: Scalars['Boolean'];
  description?: Maybe<Scalars['Markdown']>;
  displayName: Scalars['String'];
  id: Scalars['ID'];
  systemName?: Maybe<ProgramSystemName>;
};

export type ProgramCarePlan = {
  __typename?: 'ProgramCarePlan';
  carePlan?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  programEnrollment: ProgramEnrollment;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<InternalUser>;
};

export type ProgramEnrollment = {
  __typename?: 'ProgramEnrollment';
  careTeam: ProgramEnrollmentCareTeam;
  flag: Scalars['Boolean'];
  frequency?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  outreachComment?: Maybe<Scalars['String']>;
  preferredTime?: Maybe<Scalars['String']>;
  program: Program;
  programCarePlan?: Maybe<ProgramCarePlan>;
  programPhase?: Maybe<ProgramPhase>;
};

export type ProgramEnrollmentCareTeam = {
  __typename?: 'ProgramEnrollmentCareTeam';
  careGivers: Array<CareTeamsCareGiver>;
  id: Scalars['ID'];
  patient: Patient;
  providers: Array<Provider>;
  status: Scalars['String'];
};

export type ProgramPhase = {
  __typename?: 'ProgramPhase';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ProgramSuggestion = {
  __typename?: 'ProgramSuggestion';
  allowsOneClickReferrals: Scalars['Boolean'];
  contactId?: Maybe<Scalars['ID']>;
  description: Scalars['String'];
  displayName: Scalars['String'];
  externalUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  internalName: Scalars['String'];
  messageTemplate?: Maybe<MessageTemplate>;
};


export type ProgramSuggestionMessageTemplateArgs = {
  patientId?: InputMaybe<Scalars['ID']>;
};

/** The connection type for ProgramSuggestion. */
export type ProgramSuggestionConnection = {
  __typename?: 'ProgramSuggestionConnection';
  /** A list of edges. */
  edges: Array<ProgramSuggestionEdge>;
  /** A list of nodes. */
  nodes: Array<ProgramSuggestion>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ProgramSuggestionEdge = {
  __typename?: 'ProgramSuggestionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ProgramSuggestion>;
};

export enum ProgramSystemName {
  HighRiskCareManagement = 'HIGH_RISK_CARE_MANAGEMENT',
  MindsetPlus = 'MINDSET_PLUS'
}

export type ProgramVisit = {
  __typename?: 'ProgramVisit';
  formData?: Maybe<ProgramVisitFormData>;
  id: Scalars['ID'];
  summary?: Maybe<Summary>;
  summaryOfVisit?: Maybe<Scalars['String']>;
};

export type ProgramVisitFormData = {
  __typename?: 'ProgramVisitFormData';
  time?: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of PromoteInsurance */
export type PromoteInsuranceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  insuranceId: Scalars['ID'];
  patientId: Scalars['ID'];
};

/** Autogenerated return type of PromoteInsurance. */
export type PromoteInsurancePayload = {
  __typename?: 'PromoteInsurancePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Insurance object */
  insurance?: Maybe<Insurance>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

export type Provider = {
  __typename?: 'Provider';
  acceptingNewPatients?: Maybe<Scalars['Boolean']>;
  /** @deprecated Please use the camelCase version. */
  accepting_new_patients?: Maybe<Scalars['Boolean']>;
  activeUniquePrescribingCredentials: Array<PrescribingCredential>;
  appointmentInventories?: Maybe<AppointmentInventoryConnection>;
  bio?: Maybe<Scalars['HTML']>;
  bookable: Scalars['Boolean'];
  defaultOffice?: Maybe<Office>;
  /** @deprecated Please use the camelCase version. */
  default_office?: Maybe<Office>;
  displayName: Scalars['String'];
  /** @deprecated Please use the camelCase version. */
  display_name: Scalars['String'];
  email: Scalars['String'];
  essentialProceduresNotPerformed: Array<ProcedureType>;
  essentialProceduresPerformed: Array<ProcedureType>;
  expertiseTypes: Array<Expertise>;
  firstName: Scalars['String'];
  /** @deprecated Please use the camelCase version. */
  first_name: Scalars['String'];
  fullImageUrl?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  full_image_url?: Maybe<Scalars['String']>;
  gender: Scalars['String'];
  id: Scalars['ID'];
  imageObjectId?: Maybe<Scalars['ID']>;
  /** @deprecated Please use the camelCase version. */
  image_object_id?: Maybe<Scalars['ID']>;
  isActive: Scalars['Boolean'];
  isPatientBookable: Scalars['Boolean'];
  /** @deprecated Please use the camelCase version. */
  is_active: Scalars['Boolean'];
  /** @deprecated Please use the camelCase version. */
  is_patient_bookable: Scalars['Boolean'];
  languages: Array<UserLanguage>;
  lastName: Scalars['String'];
  /** @deprecated Please use the camelCase version. */
  last_name: Scalars['String'];
  /** Licensing bodies (e.g. states, territories) where the provider is able to practice */
  locationsForPracticing: Array<LicensingBody>;
  npi?: Maybe<Scalars['String']>;
  outOfOffice?: Maybe<Scalars['Boolean']>;
  /** @deprecated Please use the camelCase version. */
  out_of_office?: Maybe<Scalars['Boolean']>;
  panels: Array<Panel>;
  prescribingCredentials: Array<PrescribingCredential>;
  profileImageUrl?: Maybe<Scalars['String']>;
  /** @deprecated Please use the camelCase version. */
  profile_image_url?: Maybe<Scalars['String']>;
  returnDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated Please use the camelCase version. */
  return_date?: Maybe<Scalars['DateTime']>;
  roles?: Maybe<Array<Maybe<RoleType>>>;
  school?: Maybe<Scalars['String']>;
  seeingPatientsSince?: Maybe<Scalars['Int']>;
  serviceArea?: Maybe<ServiceArea>;
  serviceTypes: Array<SpecialtyType>;
  /** @deprecated Please use the camelCase version. */
  service_area?: Maybe<ServiceArea>;
  specializedProceduresPerformed: Array<ProcedureType>;
  specialtyTypes?: Maybe<Array<SpecialtyType>>;
  /** @deprecated Please use the camelCase version. */
  specialty_types?: Maybe<Array<SpecialtyType>>;
  suffix?: Maybe<Scalars['String']>;
  supportedAgeRange?: Maybe<SupportedAgeRange>;
  updatedAt: Scalars['DateTime'];
  workSchedule?: Maybe<WorkSchedule>;
  worksWith?: Maybe<Array<Provider>>;
  /** @deprecated Please use the camelCase version. */
  works_with?: Maybe<Array<Provider>>;
};


export type ProviderAppointmentInventoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  startDate: Scalars['DateTime'];
};


export type ProviderDisplayNameArgs = {
  options?: InputMaybe<InternalUserDisplayNameOptions>;
};


export type ProviderDisplay_NameArgs = {
  options?: InputMaybe<InternalUserDisplayNameOptions>;
};

/** The connection type for Provider. */
export type ProviderConnection = {
  __typename?: 'ProviderConnection';
  /** A list of edges. */
  edges: Array<ProviderEdge>;
  /** A list of nodes. */
  nodes: Array<Provider>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ProviderEdge = {
  __typename?: 'ProviderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Provider>;
};

export type ProviderPronoun = {
  __typename?: 'ProviderPronoun';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export enum ProviderSortBy {
  LastName = 'LAST_NAME'
}

export type Query = {
  __typename?: 'Query';
  acceptedSuspectProblems: ProblemConnection;
  activePharmacyCoverageDetails?: Maybe<Array<ActivePharmacyCoverageDetails>>;
  admission?: Maybe<Admission>;
  /** Use this query to search admissions. Note: while the type signature suggests 2 way pagination to conform with Relay, this resource can only paginate forwards. Do not use `before` and `last` */
  admissions: AdmissionSearchPage;
  appointment?: Maybe<Appointment>;
  appointmentCancellationReasons: Array<AppointmentCancellationReason>;
  appointmentInventory?: Maybe<AppointmentInventory>;
  /** Valid reasons an appointment slot can be marked as blocked */
  appointmentInventoryBlocks: Array<AppointmentInventoryBlock>;
  appointmentInventorySearch?: Maybe<AppointmentInventorySearchResults>;
  appointmentLengths: Array<AppointmentLength>;
  /** Returns a list of candidate locations based on the given search text query. */
  appointmentLocationSearch?: Maybe<Array<Address>>;
  appointmentRecording?: Maybe<AppointmentRecording>;
  appointmentTypeAlternatives: AppointmentTypeAlternative;
  appointmentTypeGroups: Array<AppointmentTypeGroup>;
  appointmentTypes: Array<AppointmentType>;
  availableAppointmentSearch?: Maybe<AppointmentInventorySearchResults>;
  b2bCompany?: Maybe<B2bCompany>;
  billingAdminTasks: Array<Scalars['String']>;
  billingDistrict: BillingDistrict;
  /** Search for bookable providers by name or ID. Not paginated and designed for autocompletes. */
  bookableProviderSearch: Array<BookableProviderSearchResult>;
  /** Returns generated report contents according to selected options */
  cervicalCancerScreeningReport?: Maybe<CervicalCancerReportContents>;
  /** Returns reporting field options */
  cervicalCancerScreeningReportOptions: CervicalCancerResultOptions;
  checkVersion: CheckVersion;
  contact?: Maybe<Contact>;
  /** Use this query to search contacts. Note: while the type signature suggests 2 way pagination to conform with Relay, this resource can only paginate forwards. Do not use `before` and `last` */
  contacts: ContactSearchPage;
  /** @deprecated Please reference the ContactStatus enum instead */
  contactsStatuses: Array<Maybe<ContactsStatus>>;
  customNetworks: Array<CustomNetwork>;
  detailedEthnicities: Array<Ethnicity>;
  diagnosticGroups: Array<Maybe<DiagnosticGroup>>;
  directSignupToken?: Maybe<DirectSignupToken>;
  /** Finds a B2B discount type, consumer discount type, or referral code by its code */
  discountType?: Maybe<DiscountType>;
  document: Document;
  /** Filing templates for documents */
  documentFilingTemplates: Array<FilingTemplate>;
  /** Documents inbox */
  documents: DocumentConnection;
  encounter?: Maybe<Encounter>;
  ethnicities?: Maybe<Array<Ethnicity>>;
  expertiseTypes: Array<Maybe<Expertise>>;
  externalDocuments?: Maybe<Array<ExternalDocument>>;
  featuredPharmacy?: Maybe<Pharmacy>;
  firebase?: Maybe<Firebase>;
  genderIdentities?: Maybe<Array<GenderIdentity>>;
  hearabouts: Array<Maybe<Hearabout>>;
  helpflowTypes: Array<HelpFlow>;
  inboundFhirItems?: Maybe<Array<InboundFhirItem>>;
  indication?: Maybe<Indication>;
  institutions: Array<Maybe<Institution>>;
  insurance?: Maybe<Insurance>;
  insuranceEligibilityRules: Array<EligibilityRule>;
  insuranceNetworks: Array<Maybe<InsuranceNetwork>>;
  insuranceNetworksProviders: Array<Maybe<InsuranceNetworksProviders>>;
  insurancePlan?: Maybe<InsurancePlan>;
  /** Returns a patient's insurance plan, if returned from Waystar, given the carrier member number */
  insurancePlanPopulation?: Maybe<InsurancePlan>;
  insuranceRelationshipTypes: Array<Maybe<InsuranceRelationshipType>>;
  insuranceTypes: Array<Maybe<InsuranceType>>;
  internalUser: InternalUser;
  internalUsers: InternalUserConnection;
  labOrder: LabOrder;
  /** Search for lab order type by clinical abbreviation, clinical description, lay description, id, etc. Not paginated and designed for autocompletes */
  labOrderTypeSearch: Array<LabOrderTypeResult>;
  labOrderTypes: LabOrderTypeConnection;
  languages?: Maybe<Array<Language>>;
  /** The required legal documents */
  legalDocuments: Array<Maybe<LegalDocument>>;
  /** The required legal documents for beneficiary */
  legalDocumentsForBeneficiary: Array<LegalDocument>;
  /**
   * The required legal documents
   * @deprecated Please use the camelCase version.
   */
  legal_documents: Array<Maybe<LegalDocument>>;
  /** A list of bodies (e.g. states and non-states like Washington DC) that are relevant with respect to medical licensing. Differs from the `states` field only in name; since `states` actually includes regions that aren't states, it seems clearer to use a more generic name. */
  licensingBodies: Array<State>;
  massAppointmentChangeRequest?: Maybe<MassAppointmentChangeRequest>;
  massAppointmentChangeRequests: MassAppointmentChangeRequestConnection;
  measurementOrderTypeSources: Array<MeasurementOrderTypeSource>;
  measurementTypes: MeasurementTypeConnection;
  measurementsValidationRules: MeasurementsValidationRules;
  membership?: Maybe<Membership>;
  messageTemplates: MessageTemplateConnection;
  mlBillingProcedureSuggestion?: Maybe<MlBillingProcedureSuggestion>;
  mlFeedbackProcedureSuggestion?: Maybe<MlFeedbackProcedureSuggestion>;
  note?: Maybe<Note>;
  noteTemplate?: Maybe<NoteTemplate>;
  observations?: Maybe<Array<Observation>>;
  offices: Array<Maybe<Office>>;
  /** Returns a list of offices that have open lab hours for the current week */
  officesWithOpenLabHoursInServiceArea?: Maybe<Array<Office>>;
  openPracticeMessage?: Maybe<OpenPracticeMessage>;
  openTokToken: Scalars['String'];
  outreachManagerAppointmentTypeGroups: OutreachManagerAppointmentTypeGroupConnection;
  /**
   * The interface for all types of outreach via the Outreach Manager.
   * A single batch order mainly consists of the type of outreach, the patients who receive the order, and the orderer.
   * Currently the subtypes are:
   * Survey Order, Visit Follow-up Order, Lab Order, and User Engagement Message
   *
   */
  outreachManagerBatches: OutreachManagerBatchInterfaceConnection;
  patient?: Maybe<Patient>;
  patientCharge?: Maybe<PatientCharge>;
  patientChargePayment?: Maybe<PatientChargePayment>;
  /** Search for patients by name, email, DOB, id, etc. Not paginated and designed for autocompletes */
  patientSearch: Array<PatientSearchResult>;
  patients: Array<Patient>;
  pdmpLastViewed?: Maybe<PdmpReportEvent>;
  pdmpNonParticipatingStates: Array<Maybe<PdmpNonParticipatingState>>;
  pharmacy: Pharmacy;
  /**
   * Searches for pharmacies close to a location OR mail order pharmacies.
   *
   * When not searching for mail order pharmacies, a location or patient must be provided.
   *
   * If no location is provided, the location of the patient's home address, home office address, service area default office address will be used in that order.
   *
   */
  pharmacySearch: PharmacyConnection;
  phasedPrograms: Array<Program>;
  /** Search for possible parent contacts by name. Not paginated and designed for autocompletes */
  possibleParentContacts: Array<ContactSearchResult>;
  /** calls Elasticsearch query for the prioritized task list for the user */
  prioritizedTasks: PrioritizedTasks;
  /** Retrieve Problem by ID */
  problem: Problem;
  /** Search for Problem Types. */
  problemTypeSearch: Array<ProblemTypeResult>;
  problemTypes: ProblemTypeConnection;
  /** Retrieve Problems that match specific criteria */
  problems: Array<Problem>;
  procedureInteraction?: Maybe<ProcedureInteraction>;
  procedureOrder?: Maybe<ProcedureOrder>;
  procedureType?: Maybe<ProcedureType>;
  profile?: Maybe<InternalUser>;
  program: Program;
  programEnrollmentCareTeam: ProgramEnrollmentCareTeam;
  programVisit?: Maybe<ProgramVisit>;
  /** @deprecated Use providersConnection instead; this deprecated field should have ids as an array of strings instead of an array of integers, and should be paginated. */
  providers: Array<Maybe<Provider>>;
  providersConnection: ProviderConnection;
  races?: Maybe<Array<Race>>;
  recommendedAppointmentLocations?: Maybe<RecommendedAppointmentLocations>;
  rejectedSuspectProblems: ProblemConnection;
  reservationTags: Array<ReservationTags>;
  scheduleTemplate: ScheduleTemplate;
  scheduleTemplateVariant: ScheduleTemplateVariant;
  scheduleTemplateVariants: Array<ScheduleTemplateVariant>;
  scheduleTemplates: ScheduleTemplateConnection;
  serviceArea: ServiceArea;
  serviceAreas: Array<Maybe<ServiceArea>>;
  serviceTicket?: Maybe<ServiceTicket>;
  serviceTicketItem?: Maybe<ServiceTicketItem>;
  serviceTicketPaymentTransmission?: Maybe<ServiceTicketPaymentTransmission>;
  serviceTicketTransmission?: Maybe<ServiceTicketTransmission>;
  /** @deprecated Please use the camelCase version. */
  service_areas: Array<Maybe<ServiceArea>>;
  sexualOrientations?: Maybe<Array<SexualOrientation>>;
  signedS3Url?: Maybe<SignedS3Url>;
  smartDraft: SmartDraft;
  specialties: Array<Maybe<Specialty>>;
  states: Array<Maybe<State>>;
  stripeSetupIntent?: Maybe<StripeSetupIntent>;
  summary?: Maybe<Summary>;
  /** A list of currently active surveys. */
  surveys: Array<Survey>;
  task?: Maybe<Task>;
  /** Calls Elasticsearch query to get task aggregrations only, by location. */
  taskCounts: TasksAggregations;
  textTemplate?: Maybe<TextTemplate>;
  /** @deprecated Unused by patient clients */
  topics: Array<Topic>;
  /** A list of all User Engagement Messages */
  userEngagementMessages: UserEngagementMessageConnection;
  /**
   * Validates a claim code and returns information on payment provider subscription.
   * @deprecated Use the mutation validateClaimCode instead
   */
  validateClaimCode?: Maybe<ClaimCodeValidation>;
  verifiedEligibleEmployee?: Maybe<VerifiedEligibleEmployee>;
  visitReasonMap?: Maybe<VisitReasonMap>;
  voluntaryAlignmentOffer?: Maybe<VoluntaryAlignmentOffer>;
  voluntaryAlignmentOffers: Array<VoluntaryAlignmentOffer>;
  /** Returns an HTML response displaying the result of a Waystar eligibility check */
  waystarAdhocHtmlResponse?: Maybe<Scalars['HTML']>;
  /** Returns an HTML response displaying the result of a Waystar eligibility check. Requires a Patient ID, Carrier ID & Subscriber Number */
  waystarAutoVerificationHtmlResponse?: Maybe<Scalars['HTML']>;
  waystarEligibilityStatus?: Maybe<WaystarEligibilityStatus>;
  /** Returns a HTML response displaying the result of a Waystar eligibility check. Requires an Insurance id */
  waystarHtmlResponse?: Maybe<Scalars['HTML']>;
  waystarParsedData?: Maybe<WaystarParsedData>;
  whitelistedEmployee?: Maybe<WhitelistedEmployee>;
  whitelistedEmployeeDependent?: Maybe<WhitelistedEmployee>;
  zendeskToken?: Maybe<Scalars['String']>;
  zoom: Zoom;
};


export type QueryAcceptedSuspectProblemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  patientId: Scalars['ID'];
};


export type QueryActivePharmacyCoverageDetailsArgs = {
  patientId: Scalars['ID'];
};


export type QueryAdmissionArgs = {
  id: Scalars['ID'];
};


export type QueryAdmissionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  query: AdmissionSearchQuery;
};


export type QueryAppointmentArgs = {
  id: Scalars['ID'];
};


export type QueryAppointmentInventoryArgs = {
  id: Scalars['ID'];
};


export type QueryAppointmentInventorySearchArgs = {
  appointmentCategory?: InputMaybe<Scalars['String']>;
  appointmentDate: Scalars['Date'];
  appointmentTypeId: Scalars['ID'];
  excludeClosedPanels?: InputMaybe<Scalars['Boolean']>;
  excludePreferredBooking?: InputMaybe<Scalars['Boolean']>;
  expertiseTypeIds?: InputMaybe<Array<Scalars['ID']>>;
  firstAvailable?: InputMaybe<Scalars['Boolean']>;
  officeIds?: InputMaybe<Array<Scalars['ID']>>;
  patientId?: InputMaybe<Scalars['ID']>;
  providerId?: InputMaybe<Scalars['ID']>;
  providerIds?: InputMaybe<Array<Scalars['ID']>>;
  serviceAreaId: Scalars['ID'];
  spokenLanguageIds?: InputMaybe<Array<Scalars['ID']>>;
  view?: InputMaybe<AppointmentInventoryView>;
};


export type QueryAppointmentLocationSearchArgs = {
  textQuery?: InputMaybe<Scalars['String']>;
};


export type QueryAppointmentRecordingArgs = {
  id: Scalars['ID'];
};


export type QueryAppointmentTypeAlternativesArgs = {
  after?: InputMaybe<Scalars['String']>;
  appointmentTypeId: Scalars['ID'];
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  serviceAreaId?: InputMaybe<Scalars['ID']>;
};


export type QueryAppointmentTypesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryAvailableAppointmentSearchArgs = {
  appointmentDate: Scalars['Date'];
  excludeClosedPanels?: InputMaybe<Scalars['Boolean']>;
  excludePreferredBooking?: InputMaybe<Scalars['Boolean']>;
  expertiseTypeIds?: InputMaybe<Array<Scalars['ID']>>;
  officeIds?: InputMaybe<Array<Scalars['ID']>>;
  patientId?: InputMaybe<Scalars['ID']>;
  providerIds?: InputMaybe<Array<Scalars['ID']>>;
  serviceAreaId: Scalars['ID'];
  spokenLanguageIds?: InputMaybe<Array<Scalars['ID']>>;
  view?: InputMaybe<AppointmentInventoryView>;
  visitReasonId: Scalars['ID'];
};


export type QueryB2bCompanyArgs = {
  b2bEmail?: InputMaybe<Scalars['String']>;
  reCaptchaToken?: InputMaybe<Scalars['String']>;
};


export type QueryBillingDistrictArgs = {
  id: Scalars['ID'];
};


export type QueryBookableProviderSearchArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  textQuery?: InputMaybe<Scalars['String']>;
};


export type QueryCervicalCancerScreeningReportArgs = {
  cervicalCancerScreeningReportInput?: InputMaybe<CervicalCancerScreeningReportInput>;
};


export type QueryCheckVersionArgs = {
  appId: Scalars['String'];
  currentVersion: Scalars['String'];
};


export type QueryContactArgs = {
  id: Scalars['ID'];
};


export type QueryContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  query: ContactSearchQuery;
};


export type QueryCustomNetworksArgs = {
  restricted?: InputMaybe<Scalars['Boolean']>;
};


export type QueryDirectSignupTokenArgs = {
  token: Scalars['String'];
};


export type QueryDiscountTypeArgs = {
  code: Scalars['String'];
};


export type QueryDocumentArgs = {
  id: Scalars['ID'];
};


export type QueryDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  fax?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locked?: InputMaybe<Scalars['Boolean']>;
  serviceAreaIds?: InputMaybe<Array<Scalars['ID']>>;
  startDate?: InputMaybe<Scalars['Date']>;
  statuses?: InputMaybe<Array<DocumentStatus>>;
  suggestedPatientId?: InputMaybe<Scalars['ID']>;
};


export type QueryEncounterArgs = {
  id: Scalars['ID'];
};


export type QueryExternalDocumentsArgs = {
  patientId: Scalars['ID'];
  searchString?: InputMaybe<Scalars['String']>;
};


export type QueryInboundFhirItemsArgs = {
  patientId: Scalars['ID'];
};


export type QueryIndicationArgs = {
  id: Scalars['ID'];
};


export type QueryInsuranceArgs = {
  id: Scalars['ID'];
};


export type QueryInsuranceEligibilityRulesArgs = {
  id: Scalars['ID'];
};


export type QueryInsurancePlanArgs = {
  id: Scalars['ID'];
};


export type QueryInsurancePlanPopulationArgs = {
  insuranceCarrierId: Scalars['ID'];
  patientId: Scalars['ID'];
  subscriberNumber: Scalars['String'];
};


export type QueryInternalUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryInternalUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  last?: InputMaybe<Scalars['Int']>;
  withMassCancellations?: InputMaybe<Scalars['Boolean']>;
};


export type QueryLabOrderArgs = {
  id: Scalars['ID'];
};


export type QueryLabOrderTypeSearchArgs = {
  searchQuery: Scalars['String'];
};


export type QueryLabOrderTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryLegalDocumentsForBeneficiaryArgs = {
  beneficiaryId: Scalars['ID'];
};


export type QueryLicensingBodiesArgs = {
  excludeTerritories?: InputMaybe<Scalars['Boolean']>;
};


export type QueryMassAppointmentChangeRequestArgs = {
  id: Scalars['ID'];
};


export type QueryMassAppointmentChangeRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  changeTypes: Array<MassAppointmentChangeRequestChangeType>;
  date?: InputMaybe<Scalars['Date']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  officeIds?: InputMaybe<Array<Scalars['ID']>>;
  submittedByIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryMeasurementOrderTypeSourcesArgs = {
  limitByCurrentUser?: InputMaybe<Scalars['Boolean']>;
};


export type QueryMeasurementTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forConnectedDeviceOrders?: InputMaybe<Scalars['Boolean']>;
  forInsightsPanel?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['ID']>;
};


export type QueryMessageTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryMlBillingProcedureSuggestionArgs = {
  id: Scalars['ID'];
};


export type QueryMlFeedbackProcedureSuggestionArgs = {
  id: Scalars['ID'];
};


export type QueryNoteArgs = {
  id: Scalars['ID'];
};


export type QueryNoteTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryObservationsArgs = {
  code?: InputMaybe<Scalars['String']>;
  patientId: Scalars['ID'];
  unit?: InputMaybe<Scalars['String']>;
};


export type QueryOfficesArgs = {
  excludeSeniorSpecific?: InputMaybe<Scalars['Boolean']>;
  excludeVirtual?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  serviceAreaId?: InputMaybe<Scalars['ID']>;
  sortByDistance?: InputMaybe<Scalars['Boolean']>;
};


export type QueryOfficesWithOpenLabHoursInServiceAreaArgs = {
  id: Scalars['ID'];
};


export type QueryOpenPracticeMessageArgs = {
  id: Scalars['ID'];
};


export type QueryOpenTokTokenArgs = {
  sessionId: Scalars['String'];
};


export type QueryOutreachManagerAppointmentTypeGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryOutreachManagerBatchesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  last?: InputMaybe<Scalars['Int']>;
  outreachType?: InputMaybe<OutreachManagerBatchOrderType>;
  status?: InputMaybe<OutreachManagerStatus>;
};


export type QueryPatientArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPatientChargeArgs = {
  id: Scalars['ID'];
};


export type QueryPatientChargePaymentArgs = {
  id: Scalars['ID'];
};


export type QueryPatientSearchArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  textQuery?: InputMaybe<Scalars['String']>;
};


export type QueryPatientsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryPdmpLastViewedArgs = {
  patientId: Scalars['ID'];
  requestedLocation: Scalars['String'];
};


export type QueryPharmacyArgs = {
  id: Scalars['ID'];
};


export type QueryPharmacySearchArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  geolocation?: InputMaybe<Geolocation>;
  last?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
  mailOrder?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryPossibleParentContactsArgs = {
  contactId?: InputMaybe<Scalars['ID']>;
  query: Scalars['String'];
};


export type QueryPrioritizedTasksArgs = {
  b2bCompanyId?: InputMaybe<Scalars['ID']>;
  bucket: PrioritizedTasksBucket;
  categories?: InputMaybe<Array<Scalars['String']>>;
  filterId?: InputMaybe<Scalars['ID']>;
  keywords?: InputMaybe<Scalars['String']>;
  licensingBodyIds?: InputMaybe<Array<Scalars['ID']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['ID']>;
  serviceAreaIds?: InputMaybe<Array<Scalars['ID']>>;
  similarToTaskId?: InputMaybe<Scalars['ID']>;
  sortOption?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
};


export type QueryProblemArgs = {
  id: Scalars['ID'];
};


export type QueryProblemTypeSearchArgs = {
  searchQuery: Scalars['String'];
};


export type QueryProblemTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryProblemsArgs = {
  attachmentId?: InputMaybe<Scalars['ID']>;
  attachmentType?: InputMaybe<ProblemAttachmentAttachmentEnum>;
};


export type QueryProcedureInteractionArgs = {
  id: Scalars['ID'];
};


export type QueryProcedureOrderArgs = {
  id: Scalars['ID'];
};


export type QueryProcedureTypeArgs = {
  id: Scalars['ID'];
};


export type QueryProgramArgs = {
  id: Scalars['ID'];
};


export type QueryProgramEnrollmentCareTeamArgs = {
  id: Scalars['ID'];
};


export type QueryProgramVisitArgs = {
  id?: InputMaybe<Scalars['ID']>;
  summaryId?: InputMaybe<Scalars['ID']>;
};


export type QueryProvidersArgs = {
  ids?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryProvidersConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bookable?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  officeId?: InputMaybe<Scalars['ID']>;
  roleIds?: InputMaybe<Array<Scalars['ID']>>;
  serviceAreaId?: InputMaybe<Scalars['ID']>;
  sortBy?: InputMaybe<ProviderSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QueryRecommendedAppointmentLocationsArgs = {
  patientId: Scalars['ID'];
  serviceAreaId: Scalars['ID'];
};


export type QueryRejectedSuspectProblemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  patientId: Scalars['ID'];
};


export type QueryScheduleTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryScheduleTemplateVariantArgs = {
  id: Scalars['ID'];
};


export type QueryScheduleTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  searchTerms?: InputMaybe<Scalars['String']>;
};


export type QueryServiceAreaArgs = {
  id: Scalars['Int'];
};


export type QueryServiceAreasArgs = {
  excludeNoOffices?: InputMaybe<Scalars['Boolean']>;
};


export type QueryServiceTicketArgs = {
  id: Scalars['ID'];
};


export type QueryServiceTicketItemArgs = {
  id: Scalars['ID'];
};


export type QueryServiceTicketPaymentTransmissionArgs = {
  id: Scalars['ID'];
};


export type QueryServiceTicketTransmissionArgs = {
  id: Scalars['ID'];
};


export type QueryService_AreasArgs = {
  excludeNoOffices?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySignedS3UrlArgs = {
  contentType: Scalars['String'];
  fileName: Scalars['String'];
  path?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['ID']>;
};


export type QuerySmartDraftArgs = {
  id: Scalars['ID'];
};


export type QueryStripeSetupIntentArgs = {
  id: Scalars['String'];
};


export type QuerySummaryArgs = {
  id: Scalars['ID'];
};


export type QueryTaskArgs = {
  id: Scalars['ID'];
};


export type QueryTaskCountsArgs = {
  roleIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryTextTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryUserEngagementMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryValidateClaimCodeArgs = {
  claimCode: Scalars['String'];
};


export type QueryVerifiedEligibleEmployeeArgs = {
  token: Scalars['String'];
};


export type QueryVisitReasonMapArgs = {
  patientId: Scalars['ID'];
  serviceAreaId: Scalars['ID'];
};


export type QueryVoluntaryAlignmentOfferArgs = {
  id: Scalars['ID'];
};


export type QueryVoluntaryAlignmentOffersArgs = {
  patientId: Scalars['ID'];
};


export type QueryWaystarAdhocHtmlResponseArgs = {
  dob: Scalars['String'];
  firstName: Scalars['String'];
  insuranceCarrierId: Scalars['ID'];
  lastName: Scalars['String'];
  serviceAreaId: Scalars['ID'];
  subscriberNumberId: Scalars['ID'];
};


export type QueryWaystarAutoVerificationHtmlResponseArgs = {
  insuranceCarrierId: Scalars['ID'];
  patientId: Scalars['ID'];
  subscriberNumber: Scalars['String'];
};


export type QueryWaystarEligibilityStatusArgs = {
  id: Scalars['ID'];
};


export type QueryWaystarHtmlResponseArgs = {
  id: Scalars['ID'];
};


export type QueryWaystarParsedDataArgs = {
  id: Scalars['ID'];
};


export type QueryWhitelistedEmployeeArgs = {
  b2bCompanyId?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['String']>;
  employeeId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  reCaptchaToken?: InputMaybe<Scalars['String']>;
  workEmail?: InputMaybe<Scalars['String']>;
};


export type QueryWhitelistedEmployeeDependentArgs = {
  b2bCompanyId: Scalars['String'];
  dateOfBirth: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  parentId: Scalars['String'];
  reCaptchaToken?: InputMaybe<Scalars['String']>;
};

export type Race = {
  __typename?: 'Race';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export enum Rating {
  Acceptable = 'acceptable',
  Great = 'great',
  Problematic = 'problematic',
  Unrated = 'unrated'
}

export enum ReadmissionRisk {
  HighRisk = 'HIGH_RISK',
  LowToMediumRisk = 'LOW_TO_MEDIUM_RISK',
  NotEvaluatedOrNa = 'NOT_EVALUATED_OR_NA'
}

export type RecommendedAppointmentLocations = {
  __typename?: 'RecommendedAppointmentLocations';
  patientSavedAddresses?: Maybe<Array<Address>>;
  previouslyVisitedOffices?: Maybe<Array<Office>>;
};

export type Referral = {
  __typename?: 'Referral';
  /** For use with the dismissAlert mutation */
  bannerAlertKey: Scalars['String'];
  messageUrl: Scalars['String'];
  name: Scalars['String'];
  pageAction: Scalars['String'];
  pageBody: Scalars['String'];
  pageHeader: Scalars['String'];
};

/** Autogenerated input type of RefreshTaskInIndex */
export type RefreshTaskInIndexInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of RefreshTaskInIndex. */
export type RefreshTaskInIndexPayload = {
  __typename?: 'RefreshTaskInIndexPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

export type RegistrationWaitlistEnrollment = {
  __typename?: 'RegistrationWaitlistEnrollment';
  id: Scalars['ID'];
  patient: Patient;
  serviceArea: ServiceArea;
  state: RegistrationWaitlistEnrollmentState;
};

export enum RegistrationWaitlistEnrollmentState {
  Registered = 'registered',
  Waitlisted = 'waitlisted'
}

/** Autogenerated input type of RejectSuspectProblem */
export type RejectSuspectProblemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  problemId: Scalars['ID'];
};

/** Autogenerated return type of RejectSuspectProblem. */
export type RejectSuspectProblemPayload = {
  __typename?: 'RejectSuspectProblemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  problem?: Maybe<Problem>;
  success: Scalars['Boolean'];
};

export type RelatedPerson = {
  __typename?: 'RelatedPerson';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  /** The related person data in FHIR */
  fhir?: Maybe<Scalars['JSON']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  patient?: Maybe<Patient>;
  phoneNumber?: Maybe<Scalars['String']>;
  relationship?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  zipCode?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ReleaseDocumentLock */
export type ReleaseDocumentLockInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  documentId: Scalars['ID'];
};

/** Autogenerated return type of ReleaseDocumentLock. */
export type ReleaseDocumentLockPayload = {
  __typename?: 'ReleaseDocumentLockPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Document unlocked */
  document?: Maybe<Document>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

export type Remindable = {
  /** id of remindable object */
  id: Scalars['ID'];
  /**
   * class name of remindable object
   * @deprecated Field is redundant, use reminder.type instead
   */
  name: Scalars['String'];
  /** string to indicate a status of the remindable object */
  notifiableStatus?: Maybe<Scalars['String']>;
  /**
   * string to indicate a status of the remindable object
   * @deprecated Please use the camelCase version.
   */
  notifiable_status?: Maybe<Scalars['String']>;
  /** notification subtitle based on remindable type */
  notificationSubtitle?: Maybe<Scalars['String']>;
  /** notification title based on remindable type */
  notificationTitle: Scalars['String'];
  /**
   * notification subtitle based on remindable type
   * @deprecated Please use the camelCase version.
   */
  notification_subtitle?: Maybe<Scalars['String']>;
  /**
   * notification title based on remindable type
   * @deprecated Please use the camelCase version.
   */
  notification_title: Scalars['String'];
  /** Long-form description of this object */
  reminderDescription?: Maybe<Scalars['String']>;
  /** a weight given to remindable objects to define sort order */
  sortWeight: Scalars['Int'];
  /**
   * a weight given to remindable objects to define sort order
   * @deprecated Please use the camelCase version.
   */
  sort_weight: Scalars['Int'];
  /** universal link for notification */
  universalLink: Scalars['String'];
  /**
   * universal link for notification
   * @deprecated Please use the camelCase version.
   */
  universal_link: Scalars['String'];
};

/** Autogenerated input type of RemoveAppointmentInventories */
export type RemoveAppointmentInventoriesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  date: Scalars['DateTime'];
  providerId: Scalars['Int'];
};

/** Autogenerated return type of RemoveAppointmentInventories. */
export type RemoveAppointmentInventoriesPayload = {
  __typename?: 'RemoveAppointmentInventoriesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  inventoryIds?: Maybe<Array<Scalars['ID']>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of RemoveAppointmentInventory */
export type RemoveAppointmentInventoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of RemoveAppointmentInventory. */
export type RemoveAppointmentInventoryPayload = {
  __typename?: 'RemoveAppointmentInventoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of RemovePatientPcpAndUpdateOffice */
export type RemovePatientPcpAndUpdateOfficeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  officeId: Scalars['ID'];
  patientId: Scalars['ID'];
};

/** Autogenerated return type of RemovePatientPcpAndUpdateOffice. */
export type RemovePatientPcpAndUpdateOfficePayload = {
  __typename?: 'RemovePatientPcpAndUpdateOfficePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  patient?: Maybe<Patient>;
  success: Scalars['Boolean'];
};

export type RenewalRequest = {
  __typename?: 'RenewalRequest';
  createdAt: Scalars['DateTime'];
  patientPharmacy?: Maybe<PatientPharmacy>;
  pharmacy?: Maybe<Pharmacy>;
};

export type Report = {
  __typename?: 'Report';
  additionalLinks?: Maybe<Array<Link>>;
  correlationId?: Maybe<Scalars['ID']>;
  errors?: Maybe<Array<Scalars['String']>>;
  message?: Maybe<Scalars['String']>;
  narxMessages?: Maybe<Array<NarxMessage>>;
  narxScores?: Maybe<Array<NarxScore>>;
  pmpDestinations?: Maybe<Array<Scalars['String']>>;
  reportUrl?: Maybe<Scalars['String']>;
  requestEvent?: Maybe<Scalars['JSON']>;
  requestedLocation?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of RequestDocusignVoluntaryAlignment */
export type RequestDocusignVoluntaryAlignmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  patientId: Scalars['ID'];
};

/** Autogenerated return type of RequestDocusignVoluntaryAlignment. */
export type RequestDocusignVoluntaryAlignmentPayload = {
  __typename?: 'RequestDocusignVoluntaryAlignmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
  voluntaryAlignmentOffer?: Maybe<VoluntaryAlignmentOffer>;
};

/** Autogenerated input type of RequestPdmpReport */
export type RequestPdmpReportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  patientId: Scalars['ID'];
  pendingNewRxIds?: InputMaybe<Array<Scalars['ID']>>;
  prescribingCredentialId?: InputMaybe<Scalars['ID']>;
  rxCartId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of RequestPdmpReport. */
export type RequestPdmpReportPayload = {
  __typename?: 'RequestPdmpReportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  reports?: Maybe<Array<Report>>;
  success: Scalars['Boolean'];
};

export type RescheduleAppointmentAttributes = {
  appointmentTypeId: Scalars['ID'];
  /** ID of the appointment inventory that will be booked as a part of rescheduling. */
  inventoryId: Scalars['ID'];
  sendSms?: InputMaybe<Scalars['Boolean']>;
  smsNumber?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of RescheduleAppointment */
export type RescheduleAppointmentInput = {
  appointmentCancellationReasonId?: InputMaybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** ID of the appointment that will be cancelled as a part of rescheduling. */
  fromAppointmentId: Scalars['ID'];
  newAppointmentAttributes: RescheduleAppointmentAttributes;
  patientId: Scalars['ID'];
};

/** Autogenerated return type of RescheduleAppointment. */
export type RescheduleAppointmentPayload = {
  __typename?: 'RescheduleAppointmentPayload';
  /** The new appointment that was created from rescheduling. */
  appointment?: Maybe<Appointment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** @deprecated Use `mutationErrors` instead */
  errors?: Maybe<Scalars['JSON']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  patient: Patient;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of ResendDirectSignupEmail */
export type ResendDirectSignupEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};

/** Autogenerated return type of ResendDirectSignupEmail. */
export type ResendDirectSignupEmailPayload = {
  __typename?: 'ResendDirectSignupEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of ResendVoluntaryAlignmentDocusignRequest */
export type ResendVoluntaryAlignmentDocusignRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  voluntaryAlignmentOfferId: Scalars['ID'];
};

/** Autogenerated return type of ResendVoluntaryAlignmentDocusignRequest. */
export type ResendVoluntaryAlignmentDocusignRequestPayload = {
  __typename?: 'ResendVoluntaryAlignmentDocusignRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
  voluntaryAlignmentOffer?: Maybe<VoluntaryAlignmentOffer>;
};

export type ReservationTags = {
  __typename?: 'ReservationTags';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** The connection type for Measurement. */
export type RespiratoryRateMeasurementConnection = {
  __typename?: 'RespiratoryRateMeasurementConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MeasurementEdge>>>;
  education: Education;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Measurement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  validationRules?: Maybe<IntegerMeasurementValidationRule>;
};

export type Response = {
  __typename?: 'Response';
  accudrawCollectionInstructions?: Maybe<Array<CollectionInstruction>>;
  messages: Array<Message>;
};

/** Autogenerated input type of RevokeMembershipGroupInvite */
export type RevokeMembershipGroupInviteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  inviteId: Scalars['ID'];
};

/** Autogenerated return type of RevokeMembershipGroupInvite. */
export type RevokeMembershipGroupInvitePayload = {
  __typename?: 'RevokeMembershipGroupInvitePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invite?: Maybe<MembershipGroupInvite>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

export type RoamMessageLabelPrediction = {
  __typename?: 'RoamMessageLabelPrediction';
  annotationLabel: Scalars['String'];
  id: Scalars['ID'];
};

export type RoamMessageLabelPredictionAttributes = {
  _destroy?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  annotationLabel?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export enum RoleEnum {
  Acupuncturist = 'ACUPUNCTURIST',
  Admin = 'ADMIN',
  Allergist = 'ALLERGIST',
  AutomatedTemplateEditor = 'AUTOMATED_TEMPLATE_EDITOR',
  AutoReplyTemplateManager = 'AUTO_REPLY_TEMPLATE_MANAGER',
  BillingAdmin = 'BILLING_ADMIN',
  CalendarAdmin = 'CALENDAR_ADMIN',
  CareManager = 'CARE_MANAGER',
  CareNav = 'CARE_NAV',
  CentralizedAdmin = 'CENTRALIZED_ADMIN',
  CentralBillingSpecialist = 'CENTRAL_BILLING_SPECIALIST',
  CentralSupportSpecialistOne = 'CENTRAL_SUPPORT_SPECIALIST_ONE',
  CentralSupportSpecialistThree = 'CENTRAL_SUPPORT_SPECIALIST_THREE',
  CentralSupportSpecialistTwo = 'CENTRAL_SUPPORT_SPECIALIST_TWO',
  CeClinicalTrialTeam = 'CE_CLINICAL_TRIAL_TEAM',
  ChargeCaptureAndCodingSpecialists = 'CHARGE_CAPTURE_AND_CODING_SPECIALISTS',
  Chiropractic = 'CHIROPRACTIC',
  ChronicCareProvider = 'CHRONIC_CARE_PROVIDER',
  ClientServices = 'CLIENT_SERVICES',
  ClinicalProgramsManager = 'CLINICAL_PROGRAMS_MANAGER',
  ClinicalSupportTeam = 'CLINICAL_SUPPORT_TEAM',
  Clubhouse = 'CLUBHOUSE',
  Colpo = 'COLPO',
  ContactAdmin = 'CONTACT_ADMIN',
  ContentAdmin = 'CONTENT_ADMIN',
  DceProvider = 'DCE_PROVIDER',
  Dermatologist = 'DERMATOLOGIST',
  DiscountTypeAdmin = 'DISCOUNT_TYPE_ADMIN',
  DuplicateReviewer = 'DUPLICATE_REVIEWER',
  EndometrialBiopsy = 'ENDOMETRIAL_BIOPSY',
  Engineer = 'ENGINEER',
  FamilyMedicine = 'FAMILY_MEDICINE',
  FrontDeskAdmin = 'FRONT_DESK_ADMIN',
  GoogleCepheidProvider = 'GOOGLE_CEPHEID_PROVIDER',
  GoogleNycWellness = 'GOOGLE_NYC_WELLNESS',
  GroupVisitCoordinator = 'GROUP_VISIT_COORDINATOR',
  GroupVisitFacilitator = 'GROUP_VISIT_FACILITATOR',
  Gyn = 'GYN',
  HealthCoach = 'HEALTH_COACH',
  HealthNavigator = 'HEALTH_NAVIGATOR',
  HiResAnoscopy = 'HI_RES_ANOSCOPY',
  ImpactProvider = 'IMPACT_PROVIDER',
  IntegrativeHealthLabs = 'INTEGRATIVE_HEALTH_LABS',
  Itstuff = 'ITSTUFF',
  IudInserter = 'IUD_INSERTER',
  IudRemover = 'IUD_REMOVER',
  JaneStreetAdmin = 'JANE_STREET_ADMIN',
  LactationConsultant = 'LACTATION_CONSULTANT',
  Legal = 'LEGAL',
  LicensedPracticalNurse = 'LICENSED_PRACTICAL_NURSE',
  Manager = 'MANAGER',
  Marketer = 'MARKETER',
  MassCancellation = 'MASS_CANCELLATION',
  MediaAndSocialResponseTeam = 'MEDIA_AND_SOCIAL_RESPONSE_TEAM',
  MedicalRecordsAdmin = 'MEDICAL_RECORDS_ADMIN',
  MedicationAdmin = 'MEDICATION_ADMIN',
  MedStudent = 'MED_STUDENT',
  MembershipAdvisor = 'MEMBERSHIP_ADVISOR',
  MemberIdentityAdmin = 'MEMBER_IDENTITY_ADMIN',
  MemberTechnicalSupport = 'MEMBER_TECHNICAL_SUPPORT',
  MentalHealth = 'MENTAL_HEALTH',
  MindsetEnterprise = 'MINDSET_ENTERPRISE',
  MindBody = 'MIND_BODY',
  Naturopath = 'NATUROPATH',
  NexplanonInserter = 'NEXPLANON_INSERTER',
  NexplanonRemover = 'NEXPLANON_REMOVER',
  Nutritionist = 'NUTRITIONIST',
  Ob = 'OB',
  OfficeRnNyc = 'OFFICE_RN_NYC',
  Osteopath = 'OSTEOPATH',
  OsteopathOnly = 'OSTEOPATH_ONLY',
  PanelManagementAdmin = 'PANEL_MANAGEMENT_ADMIN',
  Papper = 'PAPPER',
  ParetoAdmin = 'PARETO_ADMIN',
  Pcp = 'PCP',
  PeakmedCss = 'PEAKMED_CSS',
  Pediatrics = 'PEDIATRICS',
  PediatricsAdmin = 'PEDIATRICS_ADMIN',
  PedsUrgentCare = 'PEDS_URGENT_CARE',
  Pharmacist = 'PHARMACIST',
  Phlebotomist = 'PHLEBOTOMIST',
  PhysicalTherapy = 'PHYSICAL_THERAPY',
  PrenatalAdmin = 'PRENATAL_ADMIN',
  PrenatalUltrasound = 'PRENATAL_ULTRASOUND',
  ProblemTypeAdmin = 'PROBLEM_TYPE_ADMIN',
  Provider = 'PROVIDER',
  PsychiatricServices = 'PSYCHIATRIC_SERVICES',
  RadiologicTechnologist = 'RADIOLOGIC_TECHNOLOGIST',
  RespiratorProvider = 'RESPIRATOR_PROVIDER',
  Rn = 'RN',
  SexualAndReproductiveHealthCss = 'SEXUAL_AND_REPRODUCTIVE_HEALTH_CSS',
  SpacexWellness = 'SPACEX_WELLNESS',
  SportsMedicine = 'SPORTS_MEDICINE',
  Teacher = 'TEACHER',
  TemplateEditor = 'TEMPLATE_EDITOR',
  Ucp = 'UCP',
  UcsfOb = 'UCSF_OB',
  UltrasoundTechnician = 'ULTRASOUND_TECHNICIAN',
  UnlicensedMedicalAssistant = 'UNLICENSED_MEDICAL_ASSISTANT',
  UrgentCareProvider = 'URGENT_CARE_PROVIDER',
  Vaccinator = 'VACCINATOR',
  VaccineDocumenter = 'VACCINE_DOCUMENTER',
  VirtualPhysician = 'VIRTUAL_PHYSICIAN'
}

export type RoleType = Assignee & MessageRecipient & {
  __typename?: 'RoleType';
  assignable: Scalars['Boolean'];
  /** Describes this role to patients */
  description?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  globalId: Scalars['String'];
  /** @deprecated Please use `normalizedId`. This ID field is an `int` but should be type `ID` */
  id: Scalars['Int'];
  kind: Scalars['String'];
  name: Scalars['String'];
  /** Once clients switch over to :normalized_id, we can change the deprecated :id field to be of type ID and then switch clients back. */
  normalizedId: Scalars['ID'];
  /** A display string that states how long a user of this role may take to respond to a patient message */
  responseTimeText?: Maybe<Scalars['String']>;
  todoFilters: Array<Maybe<TodoFilter>>;
};

/** Autogenerated input type of RunBillingAdminTask */
export type RunBillingAdminTaskInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  params: Scalars['String'];
  task: Scalars['String'];
};

/** Autogenerated return type of RunBillingAdminTask. */
export type RunBillingAdminTaskPayload = {
  __typename?: 'RunBillingAdminTaskPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of RunInsuranceEligibilityAutomaticVerification */
export type RunInsuranceEligibilityAutomaticVerificationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  insuranceCarrierId: Scalars['ID'];
  patientId: Scalars['ID'];
  subscriberNumber: Scalars['String'];
};

/** Autogenerated return type of RunInsuranceEligibilityAutomaticVerification. */
export type RunInsuranceEligibilityAutomaticVerificationPayload = {
  __typename?: 'RunInsuranceEligibilityAutomaticVerificationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Insurance plan object */
  insurancePlan?: Maybe<InsurancePlan>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
  /** Waystar status object */
  waystarEligibilityStatus?: Maybe<WaystarEligibilityStatus>;
  /** Waystar error - waystar text response */
  waystarError?: Maybe<Scalars['String']>;
};

export type S3Attributes = {
  bucket?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
};

/** S3 Pointer Metadata for an Outreach Batch */
export type S3Pointers = {
  __typename?: 'S3Pointers';
  bucket: Scalars['String'];
  contentLength?: Maybe<Scalars['Int']>;
  contentType?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  key: Scalars['String'];
  outreachManagerBatch: OutreachManagerBatch;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export enum SameDayPromiseMessagingEnum {
  All = 'all',
  NoSameDay = 'no_same_day',
  Some = 'some',
  ZipCodeEligible = 'zip_code_eligible'
}

/** Autogenerated input type of SaveAppointmentRecordingDetailedLaunchFeedback */
export type SaveAppointmentRecordingDetailedLaunchFeedbackInput = {
  appointmentRecordingId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  detailedLaunchFeedback: Scalars['JSON'];
};

/** Autogenerated return type of SaveAppointmentRecordingDetailedLaunchFeedback. */
export type SaveAppointmentRecordingDetailedLaunchFeedbackPayload = {
  __typename?: 'SaveAppointmentRecordingDetailedLaunchFeedbackPayload';
  appointmentRecording?: Maybe<AppointmentRecording>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of SaveAppointmentRecordingFeedback */
export type SaveAppointmentRecordingFeedbackInput = {
  appointmentRecordingId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  feedback: Array<AppointmentRecordingFeedbackInput>;
};

/** Autogenerated return type of SaveAppointmentRecordingFeedback. */
export type SaveAppointmentRecordingFeedbackPayload = {
  __typename?: 'SaveAppointmentRecordingFeedbackPayload';
  appointmentRecording?: Maybe<AppointmentRecording>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of SaveSmartDraftFeedback */
export type SaveSmartDraftFeedbackInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  feedback: SmartDraftFeedback;
  smartDraftId: Scalars['ID'];
};

/** Autogenerated return type of SaveSmartDraftFeedback. */
export type SaveSmartDraftFeedbackPayload = {
  __typename?: 'SaveSmartDraftFeedbackPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  smartDraft?: Maybe<SmartDraft>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of SaveSmartDraftText */
export type SaveSmartDraftTextInput = {
  action: SmartDraftSaveAction;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  editedText?: InputMaybe<Scalars['String']>;
  smartDraftId: Scalars['ID'];
};

/** Autogenerated return type of SaveSmartDraftText. */
export type SaveSmartDraftTextPayload = {
  __typename?: 'SaveSmartDraftTextPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

export type ScheduleTemplate = {
  __typename?: 'ScheduleTemplate';
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  scheduleTemplateVariants: ScheduleTemplateVariantConnection;
  updatedAt: Scalars['DateTime'];
};


export type ScheduleTemplateScheduleTemplateVariantsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** The connection type for ScheduleTemplate. */
export type ScheduleTemplateConnection = {
  __typename?: 'ScheduleTemplateConnection';
  /** A list of edges. */
  edges: Array<ScheduleTemplateEdge>;
  /** A list of nodes. */
  nodes: Array<ScheduleTemplate>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ScheduleTemplateEdge = {
  __typename?: 'ScheduleTemplateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ScheduleTemplate>;
};

export type ScheduleTemplateVariant = {
  __typename?: 'ScheduleTemplateVariant';
  duration: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  scheduleTemplate: ScheduleTemplate;
  scheduleTemplateId: Scalars['ID'];
  slots: Array<ScheduleTemplateVariantSlot>;
  startTime?: Maybe<Scalars['Int']>;
};

/** The connection type for ScheduleTemplateVariant. */
export type ScheduleTemplateVariantConnection = {
  __typename?: 'ScheduleTemplateVariantConnection';
  /** A list of edges. */
  edges: Array<ScheduleTemplateVariantEdge>;
  /** A list of nodes. */
  nodes: Array<ScheduleTemplateVariant>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ScheduleTemplateVariantEdge = {
  __typename?: 'ScheduleTemplateVariantEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ScheduleTemplateVariant>;
};

export type ScheduleTemplateVariantSlot = {
  __typename?: 'ScheduleTemplateVariantSlot';
  appointmentTypeGroupIds: Array<Scalars['ID']>;
  blocked: Scalars['Boolean'];
  capacity: Scalars['Int'];
  comments?: Maybe<Scalars['String']>;
  duration: Scalars['Int'];
  label?: Maybe<Scalars['String']>;
  /** Maximum allowed duration value to prevent overlap with upcoming slot */
  maxDuration: Scalars['Int'];
  reserved: Scalars['Boolean'];
  startTime: Scalars['Int'];
};

export type ScrubbedWhitelistedEmployee = {
  __typename?: 'ScrubbedWhitelistedEmployee';
  id: Scalars['ID'];
  registered: Scalars['Boolean'];
};

export type SearchResult = {
  id: Scalars['ID'];
  label: Scalars['String'];
};

/** Autogenerated input type of SendForgotPasswordEmail */
export type SendForgotPasswordEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  /** Set to true if the user is in the process of resetting their password for the purposes of enabling MFA. */
  mfaVerification?: InputMaybe<Scalars['Boolean']>;
  usePreferredEmail?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of SendForgotPasswordEmail. */
export type SendForgotPasswordEmailPayload = {
  __typename?: 'SendForgotPasswordEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of SendMembershipGroupInvite */
export type SendMembershipGroupInviteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
};

/** Autogenerated return type of SendMembershipGroupInvite. */
export type SendMembershipGroupInvitePayload = {
  __typename?: 'SendMembershipGroupInvitePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  membershipGroupInvite?: Maybe<MembershipGroupInvite>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of SendRegistrationReminderEmail */
export type SendRegistrationReminderEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  patientId: Scalars['ID'];
};

/** Autogenerated return type of SendRegistrationReminderEmail. */
export type SendRegistrationReminderEmailPayload = {
  __typename?: 'SendRegistrationReminderEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

export type ServiceArea = {
  __typename?: 'ServiceArea';
  atCapacity: Scalars['Boolean'];
  code?: Maybe<Scalars['String']>;
  defaultOffice?: Maybe<Office>;
  /** @deprecated Please use the camelCase version. */
  default_office?: Maybe<Office>;
  faxNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  insuranceCarriers: Array<InsuranceCarrier>;
  latitude?: Maybe<Scalars['Float']>;
  /**
   * The licensing body that this service area belongs to. This is effectively a StateType, though non-states like DC are included.
   * If null, it means this service area is virtual so any licensing body applies.
   *
   * If you're trying to determine the licensing body for a patient, use patient -> licensingBody instead. In the future, it's possible that the licensing body is determined from a combination of address and service area, so try not to couple your client to the service area -> licensing body approach.
   *
   */
  licensingBody?: Maybe<State>;
  locationDescription?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  nearCapacity: Scalars['Boolean'];
  offices?: Maybe<Array<Office>>;
  registrationHidden?: Maybe<Scalars['Boolean']>;
  serviceAreaGroup?: Maybe<ServiceAreaGroup>;
  stripePlan?: Maybe<StripePlan>;
  /** @deprecated Please use the camelCase version. */
  stripe_plan?: Maybe<StripePlan>;
  timezone?: Maybe<Scalars['TZInfo']>;
  virtual: Scalars['Boolean'];
};


export type ServiceAreaInsuranceCarriersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


export type ServiceAreaOfficesArgs = {
  excludeVirtual?: InputMaybe<Scalars['Boolean']>;
};

export type ServiceAreaGroup = {
  __typename?: 'ServiceAreaGroup';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  serviceAreas?: Maybe<Array<ServiceArea>>;
};

export type ServiceTicket = {
  __typename?: 'ServiceTicket';
  administrativeComments?: Maybe<Scalars['String']>;
  appointment?: Maybe<Appointment>;
  id: Scalars['ID'];
  incompleteReasons: Array<Scalars['String']>;
  internalUser: InternalUser;
  labOrders?: Maybe<Array<LabOrder>>;
  processingInstructions?: Maybe<Scalars['String']>;
  serviceTicketItems: Array<ServiceTicketItem>;
  serviceTicketTransmissionId?: Maybe<Scalars['Int']>;
  state: Scalars['String'];
  visitProcedure?: Maybe<ServiceTicketItem>;
  visitSummary?: Maybe<Summary>;
};

export type ServiceTicketImproperlyClosedOrder = {
  __typename?: 'ServiceTicketImproperlyClosedOrder';
  id: Scalars['ID'];
  possibleServiceTicketId?: Maybe<Scalars['Int']>;
  state: Scalars['String'];
};

export type ServiceTicketItem = {
  __typename?: 'ServiceTicketItem';
  billingAmount?: Maybe<Scalars['Float']>;
  billingAmountOwed: Scalars['Float'];
  billingMethod?: Maybe<Scalars['String']>;
  /** Informs if the item is marked as self pay */
  billingMethodIsSelfPay: Scalars['Boolean'];
  /** CPT codes for a given service ticket item */
  cpts?: Maybe<Array<Scalars['String']>>;
  /** Direct billing packages that this item is covered by */
  enterpriseQualifyingBillingPackages: Array<Scalars['String']>;
  /** Informs if the item is hidden from the note */
  hideFromNote: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Indications for a given service ticket item */
  indications?: Maybe<Array<Scalars['String']>>;
  /** Displays if a Visit Procedure has new findings */
  newFindings?: Maybe<Scalars['Boolean']>;
  patientCharge?: Maybe<PatientCharge>;
  /** Displays if an item has been marked as direct bill automatically. */
  patientDirectBillable?: Maybe<Scalars['Boolean']>;
  patientResponsibilityType?: Maybe<Scalars['String']>;
  /**
   * Informs if the item is marked as self pay
   * @deprecated Replaced by billingMethodIsSelfPay
   */
  selfPay?: Maybe<Scalars['Boolean']>;
  /** Amount in cents that should be paid if doing self-pay */
  selfPayAmount?: Maybe<Scalars['Float']>;
  selfPayReason?: Maybe<Scalars['String']>;
  /** Informs if the item is able to be marked self pay */
  selfPayable: Scalars['Boolean'];
  serviceTicketId?: Maybe<Scalars['Int']>;
  /** Shorter display title for a service ticket item */
  shortTitle?: Maybe<Scalars['String']>;
  /** Display summary for a service ticket item */
  summary?: Maybe<Scalars['String']>;
  /** Display title for a service ticket item */
  title?: Maybe<Scalars['String']>;
  /** Displays if a Visit Procedure is Virtual */
  virtual?: Maybe<Scalars['Boolean']>;
};

export type ServiceTicketPaymentTransmission = {
  __typename?: 'ServiceTicketPaymentTransmission';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  lastTransmissionAttemptAt?: Maybe<Scalars['DateTime']>;
  numberOfAttempts?: Maybe<Scalars['Int']>;
  openPracticeMessages: Array<OpenPracticeMessage>;
  patientChargePayment: PatientChargePayment;
  state: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ServiceTicketTransmission = {
  __typename?: 'ServiceTicketTransmission';
  /** Service Ticket Items Associated to  */
  closedServiceTicketItems: Array<ServiceTicketItem>;
  /** Service Tickets Associated to  */
  closedServiceTickets: Array<ServiceTicket>;
  createdAt: Scalars['DateTime'];
  /** Output for a rules engine run in debug mode */
  debugOutput?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastHl7Message?: Maybe<Scalars['String']>;
  lastTransmissionAttemptAt?: Maybe<Scalars['DateTime']>;
  markedReadyAt?: Maybe<Scalars['DateTime']>;
  mutatedData?: Maybe<Scalars['String']>;
  numberOfAttempts?: Maybe<Scalars['Int']>;
  openPracticeMessages: Array<OpenPracticeMessage>;
  parsedErrorMessage?: Maybe<Scalars['String']>;
  patient: Patient;
  /** Service Ticket Payment Transmissions associated with service tickets */
  serviceTicketPaymentTransmissions?: Maybe<Array<ServiceTicketPaymentTransmission>>;
  serviceTickets: Array<ServiceTicket>;
  state: Scalars['String'];
  waystarEligibilityStatuses?: Maybe<Array<WaystarEligibilityStatus>>;
};

/** Autogenerated input type of SetProviderIn */
export type SetProviderInInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of SetProviderIn. */
export type SetProviderInPayload = {
  __typename?: 'SetProviderInPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of SetProviderOut */
export type SetProviderOutInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  returningOn: Scalars['Date'];
};

/** Autogenerated return type of SetProviderOut. */
export type SetProviderOutPayload = {
  __typename?: 'SetProviderOutPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

export type SexualOrientation = {
  __typename?: 'SexualOrientation';
  description?: Maybe<Scalars['String']>;
  snomedCode?: Maybe<Scalars['String']>;
};

export type ShipContainer = {
  __typename?: 'ShipContainer';
  destinationLabs?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  originalsNeeded?: Maybe<Scalars['Boolean']>;
  pcsVolume: Scalars['Float'];
  pcsVolumeUnits?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  sampleDescription?: Maybe<Scalars['String']>;
  sampleType: Scalars['String'];
  sampleTypeDescription?: Maybe<Scalars['String']>;
  storageCodeDescription?: Maybe<Scalars['String']>;
  storageShortName?: Maybe<Scalars['String']>;
  unopenedNeeded?: Maybe<Scalars['Boolean']>;
  yieldVolume: Scalars['Float'];
  yieldVolumeUnits?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of SignLegalDocument */
export type SignLegalDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

/** Autogenerated return type of SignLegalDocument. */
export type SignLegalDocumentPayload = {
  __typename?: 'SignLegalDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  legalDocument?: Maybe<LegalDocument>;
  /** @deprecated This field is not yet implemented. It is included for forward-compatibility reasons */
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

export type SignedS3Url = {
  __typename?: 'SignedS3Url';
  getUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  urlFields?: Maybe<Scalars['JSON']>;
};

export type SlotInput = {
  appointmentTypeGroupIds: Array<Scalars['ID']>;
  blocked: Scalars['Boolean'];
  capacity?: InputMaybe<Scalars['Int']>;
  comments?: InputMaybe<Scalars['String']>;
  duration: Scalars['Int'];
  label?: InputMaybe<Scalars['String']>;
  reserved: Scalars['Boolean'];
  startTime: Scalars['Int'];
};

export type SmartDraft = {
  __typename?: 'SmartDraft';
  feedback?: Maybe<SmartDraftFeedback>;
  id: Scalars['ID'];
  replyInstructions: Scalars['HTML'];
  state: SmartDraftState;
  text?: Maybe<Scalars['HTML']>;
};

export enum SmartDraftFeedback {
  /** @deprecated Feedback value no longer allowed */
  Copy = 'COPY',
  Downvote = 'DOWNVOTE',
  Good = 'GOOD',
  Unusable = 'UNUSABLE',
  Upvote = 'UPVOTE',
  Usable = 'USABLE'
}

export enum SmartDraftSaveAction {
  Draft = 'DRAFT',
  Save = 'SAVE',
  Send = 'SEND',
  Update = 'UPDATE'
}

export enum SmartDraftState {
  Complete = 'COMPLETE',
  Enqueued = 'ENQUEUED',
  Failed = 'FAILED',
  Running = 'RUNNING'
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SpecialInstruction = {
  __typename?: 'SpecialInstruction';
  code?: Maybe<Scalars['String']>;
  instruction?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type Specialty = {
  __typename?: 'Specialty';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SpecialtyType = {
  __typename?: 'SpecialtyType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Autogenerated input type of StartAppointmentRecording */
export type StartAppointmentRecordingInput = {
  appointmentRecordingId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of StartAppointmentRecording. */
export type StartAppointmentRecordingPayload = {
  __typename?: 'StartAppointmentRecordingPayload';
  appointmentRecording?: Maybe<AppointmentRecording>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

export type State = {
  __typename?: 'State';
  id: Scalars['ID'];
  name: Scalars['String'];
  shortName: Scalars['String'];
  /** @deprecated Please use the camelCase version. */
  short_name: Scalars['String'];
};

/** Autogenerated input type of StopAppointmentRecording */
export type StopAppointmentRecordingInput = {
  appointmentRecordingId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of StopAppointmentRecording. */
export type StopAppointmentRecordingPayload = {
  __typename?: 'StopAppointmentRecordingPayload';
  appointmentRecording?: Maybe<AppointmentRecording>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

export type StripePlan = Plan & {
  __typename?: 'StripePlan';
  amount?: Maybe<Scalars['Int']>;
  amountInDollars?: Maybe<Scalars['Float']>;
  /** @deprecated Please use the camelCase version. */
  amount_in_dollars?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  kind: Scalars['String'];
  name: Scalars['String'];
};

export type StripeSetupIntent = {
  __typename?: 'StripeSetupIntent';
  clientSecret?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type Summary = {
  __typename?: 'Summary';
  appointment?: Maybe<Appointment>;
  id: Scalars['ID'];
  internalUser?: Maybe<InternalUser>;
  note?: Maybe<Note>;
  procedureSuggestions?: Maybe<Array<Maybe<MlBillingProcedureSuggestion>>>;
  signedAt?: Maybe<Scalars['DateTime']>;
  /** The appointment that is linked to the Summary through the Encounter. Which exist when appointment is first booked. */
  visitAppointment?: Maybe<Appointment>;
};

export type SummaryLine = {
  __typename?: 'SummaryLine';
  id: Scalars['ID'];
  rating?: Maybe<Scalars['Int']>;
  segmentIds: Array<Scalars['ID']>;
  summary: Scalars['String'];
};

export enum SummarySection {
  Assessment = 'ASSESSMENT',
  ChiefComplaint = 'CHIEF_COMPLAINT',
  HistoryOfPresentIllness = 'HISTORY_OF_PRESENT_ILLNESS',
  PastMedicalHistory = 'PAST_MEDICAL_HISTORY',
  Plan = 'PLAN',
  ReviewOfSystems = 'REVIEW_OF_SYSTEMS'
}

export type SupportedAgeRange = {
  __typename?: 'SupportedAgeRange';
  id: Scalars['String'];
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** A set of structured questions for Patients. */
export type Survey = {
  __typename?: 'Survey';
  active: Scalars['Boolean'];
  allowMultiplePerPatient: Scalars['Boolean'];
  id: Scalars['ID'];
  instructions?: Maybe<Scalars['String']>;
  introduction?: Maybe<Scalars['String']>;
  /** A human readable name for this survey. */
  layName: Scalars['String'];
  /** An alias for `layName`. */
  name: Scalars['String'];
  singlePageForm: Scalars['Boolean'];
  systemName: Scalars['String'];
  /** An alias for `layName`. */
  title: Scalars['String'];
  uniqueName: Scalars['String'];
};

/** Type for Survey Order Outreach */
export type SurveyOrderOutreach = OutreachManagerBatch & {
  __typename?: 'SurveyOrderOutreach';
  /** The type of order that this batch represents. */
  batchOrderType: OutreachManagerBatchOrderType;
  /** A timestamp noting when the record was created. */
  createdAt: Scalars['DateTime'];
  /** The internal user that created this batch. */
  createdBy: InternalUser;
  /** optional information about this batch of outreach  */
  description?: Maybe<Scalars['String']>;
  /** Survey expiration date */
  dueDate: Scalars['DateTime'];
  /** A unique identifier for this record. */
  id: Scalars['ID'];
  /** Note for outreach shown to patients */
  note?: Maybe<Scalars['String']>;
  /** The Patients related to this batch. */
  outreachBatchPatients: OutreachBatchPatientConnection;
  s3Pointers: Array<S3Pointers>;
  /** Current batch status. */
  status: OutreachManagerStatus;
  /** The survey the outreach was created for */
  survey: Survey;
  /** Title for outreach shown to patients */
  title?: Maybe<Scalars['String']>;
  /** A timestamp noting when the record was last modified. */
  updatedAt: Scalars['DateTime'];
};


/** Type for Survey Order Outreach */
export type SurveyOrderOutreachOutreachBatchPatientsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<OutreachBatchPatientStatus>;
};

/** Autogenerated input type of SyncPatient */
export type SyncPatientInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  patientId: Scalars['ID'];
};

/** Autogenerated return type of SyncPatient. */
export type SyncPatientPayload = {
  __typename?: 'SyncPatientPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  /** Patient object */
  patient?: Maybe<Patient>;
  success: Scalars['Boolean'];
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Task = {
  __typename?: 'Task';
  assignee?: Maybe<InternalUser>;
  associatedWith?: Maybe<TaskAssociation>;
  associatedWithId?: Maybe<Scalars['ID']>;
  associatedWithType?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['HTML']>;
  category?: Maybe<TaskCategory>;
  categoryId?: Maybe<Array<Scalars['ID']>>;
  createdAt: Scalars['DateTime'];
  deferUntil?: Maybe<Scalars['DateTime']>;
  displayCategory: Scalars['String'];
  finishedBy?: Maybe<InternalUser>;
  finishedByThankYou: Scalars['Boolean'];
  /** Describes task history in descending order, from most recent to oldest. Returns empty list otherwise. */
  history: Array<TaskHistory>;
  id: Scalars['ID'];
  isAssociatedWithPriorityLabResults: Scalars['Boolean'];
  keywords?: Maybe<Scalars['String']>;
  licensingBody?: Maybe<LicensingBody>;
  name: Scalars['String'];
  nextActions: Scalars['JSON'];
  nextTaskTemplates?: Maybe<Array<TaskTemplate>>;
  patient?: Maybe<Patient>;
  patientId?: Maybe<Scalars['ID']>;
  priority: Priority;
  relatedTaskTemplates?: Maybe<Array<TaskTemplate>>;
  roamMessageLabelPredictions: Array<RoamMessageLabelPrediction>;
  slaDueAt?: Maybe<Scalars['DateTime']>;
  state: Scalars['String'];
  stateTransitionedAt?: Maybe<Scalars['DateTime']>;
  subcategory?: Maybe<TaskCategory>;
  tags?: Maybe<Array<Tag>>;
  targetUrl?: Maybe<Scalars['String']>;
  taskTemplate?: Maybe<TaskTemplate>;
  updatedAt: Scalars['DateTime'];
};

/** What is the object on the graph that this task relates to */
export type TaskAssociation = Document | Insurance | LabOrder | Note | ProcedureOrder | TimelinePost | VoluntaryAlignmentOffer;

export type TaskCategory = {
  __typename?: 'TaskCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TaskHistory = {
  __typename?: 'TaskHistory';
  description: Scalars['String'];
  detail: Scalars['String'];
};

export type TaskTemplate = {
  __typename?: 'TaskTemplate';
  id: Scalars['ID'];
  isChangeable: Scalars['Boolean'];
  name: Scalars['String'];
  standardWorkUrl?: Maybe<Scalars['String']>;
};

export type TasksAggregations = {
  __typename?: 'TasksAggregations';
  /** These are raw aggregations results from the Elasticsearch query. WARNING: The structure of this field is subject to change with the change of the Elasticsearch index. A typed `aggregations`field should be added if more stability is desired. */
  aggregationsRaw: Scalars['JSON'];
};

/** The connection type for Measurement. */
export type TemperatureMeasurementConnection = {
  __typename?: 'TemperatureMeasurementConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MeasurementEdge>>>;
  education: Education;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Measurement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  validationRules?: Maybe<FloatMeasurementValidationRule>;
};

export type TemplatedResponse = ErrorResponse | Response;

export type TextTemplate = {
  __typename?: 'TextTemplate';
  body?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  internalUserId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['Int']>;
};

export type TimelineComment = Remindable & {
  __typename?: 'TimelineComment';
  chartUrl: Scalars['String'];
  createdAt: Scalars['DateTime'];
  draft: Scalars['Boolean'];
  id: Scalars['ID'];
  messagePreview: Scalars['String'];
  /**
   * class name for a TimelineComment
   * @deprecated Field is redundant, use reminder.type instead
   */
  name: Scalars['String'];
  /** whether it is unread */
  notifiableStatus?: Maybe<Scalars['String']>;
  /**
   * string to indicate a status of the remindable object
   * @deprecated Please use the camelCase version.
   */
  notifiable_status?: Maybe<Scalars['String']>;
  /** notification subtitle */
  notificationSubtitle?: Maybe<Scalars['String']>;
  /** notification title */
  notificationTitle: Scalars['String'];
  /**
   * notification subtitle based on remindable type
   * @deprecated Please use the camelCase version.
   */
  notification_subtitle?: Maybe<Scalars['String']>;
  /**
   * notification title based on remindable type
   * @deprecated Please use the camelCase version.
   */
  notification_title: Scalars['String'];
  patient: Patient;
  postedBy: MessageSender;
  /** The internal user who posted this post */
  postedByInternalUser?: Maybe<Provider>;
  /**
   * The internal user who posted this post
   * @deprecated Please use the camelCase version.
   */
  posted_by_internal_user?: Maybe<Provider>;
  /** Long-form description of this object */
  reminderDescription?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  /** weight for TimelineComment reminders */
  sortWeight: Scalars['Int'];
  /**
   * a weight given to remindable objects to define sort order
   * @deprecated Please use the camelCase version.
   */
  sort_weight: Scalars['Int'];
  text?: Maybe<Scalars['HTML']>;
  topic?: Maybe<Scalars['String']>;
  /** universal link for the message */
  universalLink: Scalars['String'];
  /**
   * universal link for notification
   * @deprecated Please use the camelCase version.
   */
  universal_link: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type TimelinePost = Remindable & {
  __typename?: 'TimelinePost';
  chartUrl: Scalars['String'];
  /** Returns only non-draft comments by default. */
  comments: Array<TimelineComment>;
  contentType: Scalars['String'];
  createdAt: Scalars['DateTime'];
  draft: Scalars['Boolean'];
  id: Scalars['ID'];
  messagePreview: Scalars['String'];
  /**
   * class name for a TimelinePost
   * @deprecated Field is redundant, use reminder.type instead
   */
  name: Scalars['String'];
  /** whether it is unread */
  notifiableStatus?: Maybe<Scalars['String']>;
  /**
   * string to indicate a status of the remindable object
   * @deprecated Please use the camelCase version.
   */
  notifiable_status?: Maybe<Scalars['String']>;
  /** notification subtitle for TimelinePost */
  notificationSubtitle?: Maybe<Scalars['String']>;
  /** notification title for TimelinePost */
  notificationTitle: Scalars['String'];
  /**
   * notification subtitle based on remindable type
   * @deprecated Please use the camelCase version.
   */
  notification_subtitle?: Maybe<Scalars['String']>;
  /**
   * notification title based on remindable type
   * @deprecated Please use the camelCase version.
   */
  notification_title: Scalars['String'];
  patient: Patient;
  postedBy: MessageSender;
  recipient: MessageRecipient;
  /** Long-form description of this object */
  reminderDescription?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  /** weight for TimelinePost reminders */
  sortWeight: Scalars['Int'];
  /**
   * a weight given to remindable objects to define sort order
   * @deprecated Please use the camelCase version.
   */
  sort_weight: Scalars['Int'];
  summary?: Maybe<Scalars['String']>;
  /** The text content of this post. */
  textContent?: Maybe<TimelinePostTextContent>;
  /**
   * The text content of this post.
   * @deprecated Please use the camelCase version.
   */
  text_content?: Maybe<TimelinePostTextContent>;
  /** If the internal Todo associated with this post's conversation was reassigned by ML, then it will be returned. If it was manually reassigned or not reassigned, then null is returned. */
  todoMessageReassignment?: Maybe<TodoMessageReassignment>;
  topic?: Maybe<Scalars['String']>;
  /** universal link for the message */
  universalLink: Scalars['String'];
  /**
   * universal link for notification
   * @deprecated Please use the camelCase version.
   */
  universal_link: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type TimelinePostCommentsArgs = {
  includeDraftComments?: InputMaybe<Scalars['Boolean']>;
};


export type TimelinePostTodoMessageReassignmentArgs = {
  onlyReassigned?: InputMaybe<Scalars['Boolean']>;
};

/** The connection type for TimelinePost. */
export type TimelinePostConnection = {
  __typename?: 'TimelinePostConnection';
  /** A list of edges. */
  edges: Array<TimelinePostEdge>;
  /** A list of nodes. */
  nodes: Array<TimelinePost>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TimelinePostEdge = {
  __typename?: 'TimelinePostEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<TimelinePost>;
};

export enum TimelinePostSort {
  /** Sorts posts by sent date. If a post has comments, the most recent sent date is used for sorting. If a post does not have comments, the post's sent date is used for sorting. */
  RecentActivity = 'RecentActivity'
}

export type TimelinePostTextContent = {
  __typename?: 'TimelinePostTextContent';
  id: Scalars['ID'];
  /** @deprecated Part of the legacy messaging model. Use Types::TimelinePostType.recipient */
  notificationTarget?: Maybe<Scalars['String']>;
  /** @deprecated Part of the legacy messaging model. Use Types::TimelinePostType.recipient */
  notification_target?: Maybe<Scalars['String']>;
  /** The provider who posted this post */
  postedByInternalUser?: Maybe<Provider>;
  /**
   * The provider who posted this post
   * @deprecated Please use the camelCase version.
   */
  posted_by_internal_user?: Maybe<Provider>;
  /** The subject topic for this post. */
  subject?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['HTML']>;
};

export type TodoFilter = {
  __typename?: 'TodoFilter';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TodoMessageReassignment = {
  __typename?: 'TodoMessageReassignment';
  feedback?: Maybe<TodoMessageReassignmentFeedback>;
  id: Scalars['ID'];
  labels: Array<Scalars['String']>;
  newAssignee?: Maybe<Assignee>;
  originalAssignee?: Maybe<Assignee>;
  reason: Scalars['String'];
  wasReassigned: Scalars['Boolean'];
};

export type TodoMessageReassignmentFeedback = {
  __typename?: 'TodoMessageReassignmentFeedback';
  feedbackCategory?: Maybe<Scalars['String']>;
  feedbackText?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  newAssignee?: Maybe<Assignee>;
  todoMessageReassignment: TodoMessageReassignment;
};

export type Topic = {
  __typename?: 'Topic';
  details?: Maybe<TopicDetails>;
  displayName: Scalars['String'];
  /** @deprecated Replaced by displayName */
  displayTitle: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TopicDetails = {
  __typename?: 'TopicDetails';
  description: Scalars['String'];
  highlights: Array<Scalars['String']>;
  linkedAppointmentDisplayName: Scalars['String'];
  universalLink: Scalars['String'];
};

/** Autogenerated input type of TranscribeAppointmentRecording */
export type TranscribeAppointmentRecordingInput = {
  appointmentRecordingId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of TranscribeAppointmentRecording. */
export type TranscribeAppointmentRecordingPayload = {
  __typename?: 'TranscribeAppointmentRecordingPayload';
  appointmentRecording?: Maybe<AppointmentRecording>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

export type TranscriptSegment = {
  __typename?: 'TranscriptSegment';
  content: Scalars['String'];
  participant: Scalars['String'];
  segmentId: Scalars['ID'];
};

/** Autogenerated input type of TransmitServiceTicketTransmission */
export type TransmitServiceTicketTransmissionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

/** Autogenerated return type of TransmitServiceTicketTransmission. */
export type TransmitServiceTicketTransmissionPayload = {
  __typename?: 'TransmitServiceTicketTransmissionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  numberQueued?: Maybe<Scalars['Int']>;
  serviceTicketTransmission?: Maybe<ServiceTicketTransmission>;
  serviceTicketTransmissions?: Maybe<Array<ServiceTicketTransmission>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of TriggerAppointment */
export type TriggerAppointmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  event: Scalars['String'];
  id: Scalars['ID'];
};

/** Autogenerated return type of TriggerAppointment. */
export type TriggerAppointmentPayload = {
  __typename?: 'TriggerAppointmentPayload';
  appointment?: Maybe<Appointment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  /** @deprecated This field is not yet implemented. It is included for forward-compatibility reasons */
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of TriggerDocumentStatusEvent */
export type TriggerDocumentStatusEventInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  documentId: Scalars['ID'];
  event: DocumentStatusEvent;
  /** Used by file event only, will throw without `file_options` if event is `file` */
  fileOptions?: InputMaybe<FileDocumentInput>;
};

/** Autogenerated return type of TriggerDocumentStatusEvent. */
export type TriggerDocumentStatusEventPayload = {
  __typename?: 'TriggerDocumentStatusEventPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of TriggerTask */
export type TriggerTaskInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  deferUntil?: InputMaybe<Scalars['DateTime']>;
  event?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of TriggerTask. */
export type TriggerTaskPayload = {
  __typename?: 'TriggerTaskPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
  task?: Maybe<Task>;
};

/** Autogenerated input type of UnarchiveInsurance */
export type UnarchiveInsuranceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of UnarchiveInsurance. */
export type UnarchiveInsurancePayload = {
  __typename?: 'UnarchiveInsurancePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** @deprecated Use `mutationErrors` instead */
  errors?: Maybe<Scalars['JSON']>;
  insurance?: Maybe<Insurance>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

export type UnregisteredPatientAttributes = {
  dob?: InputMaybe<Scalars['Date']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  isGuest?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateAddress */
export type UpdateAddressInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  stateCode?: InputMaybe<Scalars['String']>;
  zip: Scalars['String'];
};

/** Autogenerated return type of UpdateAddress. */
export type UpdateAddressPayload = {
  __typename?: 'UpdateAddressPayload';
  address?: Maybe<Address>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateAdmission */
export type UpdateAdmissionInput = {
  admitReason?: InputMaybe<Scalars['String']>;
  admittedAt?: InputMaybe<Scalars['Date']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  dischargeType?: InputMaybe<AdmissionDischargeType>;
  dischargedAt?: InputMaybe<Scalars['Date']>;
  facilityLocation?: InputMaybe<Scalars['String']>;
  facilityType?: InputMaybe<AdmissionFacilityType>;
  hasDischargeSummary?: InputMaybe<Scalars['Boolean']>;
  hrcmEnrollment?: InputMaybe<HrcmEnrollment>;
  id: Scalars['ID'];
  monitoring?: InputMaybe<AdmissionMonitoring>;
  nextCheckInDate?: InputMaybe<Scalars['Date']>;
  nextSteps?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['ID']>;
  readmissionRisk?: InputMaybe<ReadmissionRisk>;
  story?: InputMaybe<Scalars['HTML']>;
  type?: InputMaybe<AdmissionType>;
};

/** Autogenerated return type of UpdateAdmission. */
export type UpdateAdmissionPayload = {
  __typename?: 'UpdateAdmissionPayload';
  admission?: Maybe<Admission>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateAppointment */
export type UpdateAppointmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isBillingReviewed?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated input type of UpdateAppointmentInventory */
export type UpdateAppointmentInventoryInput = {
  blocked?: InputMaybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  label?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateAppointmentInventory. */
export type UpdateAppointmentInventoryPayload = {
  __typename?: 'UpdateAppointmentInventoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  inventory?: Maybe<AppointmentInventory>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateAppointment. */
export type UpdateAppointmentPayload = {
  __typename?: 'UpdateAppointmentPayload';
  appointment?: Maybe<Appointment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateContact */
export type UpdateContactInput = {
  acceptingNewPatients?: InputMaybe<Scalars['Boolean']>;
  acceptsInsurance?: InputMaybe<Scalars['Boolean']>;
  addressAttributes?: InputMaybe<AddressAttributes>;
  availability?: InputMaybe<Rating>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactInsurancesAttributes?: InputMaybe<Array<InputMaybe<ContactInsuranceAttributes>>>;
  customNetworkIds?: InputMaybe<Array<Scalars['ID']>>;
  customWarning?: InputMaybe<Scalars['String']>;
  degrees?: InputMaybe<Scalars['String']>;
  diagnosticGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  emailsAttributes?: InputMaybe<Array<InputMaybe<EmailAttributes>>>;
  expertise?: InputMaybe<Rating>;
  firstName?: InputMaybe<Scalars['String']>;
  formerName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  hasPreferredAccess?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  incompleteReason?: InputMaybe<Scalars['String']>;
  institutionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  insuranceNetworkIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  insuranceNotes?: InputMaybe<Scalars['String']>;
  isComplete?: InputMaybe<Scalars['Boolean']>;
  isCustom?: InputMaybe<Scalars['Boolean']>;
  isElectronicallyIntegrated?: InputMaybe<Scalars['Boolean']>;
  isInternal?: InputMaybe<Scalars['Boolean']>;
  isPartner?: InputMaybe<Scalars['Boolean']>;
  isQuality?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<ContactKind>;
  languagesSpoken?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  noPhysicalLocation?: InputMaybe<Scalars['Boolean']>;
  nonAcceptedContactInsurancesAttributes?: InputMaybe<Array<InputMaybe<ContactInsuranceAttributes>>>;
  npi?: InputMaybe<Scalars['String']>;
  officeHours?: InputMaybe<Scalars['String']>;
  otherNotes?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['ID']>;
  personality?: InputMaybe<Rating>;
  phoneNumbersAttributes?: InputMaybe<Array<InputMaybe<PhoneNumberAttributes>>>;
  specialtyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  status?: InputMaybe<ContactStatus>;
  suffix?: InputMaybe<Scalars['String']>;
  taxId?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Rating>;
  website?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateContact. */
export type UpdateContactPayload = {
  __typename?: 'UpdateContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contact?: Maybe<Contact>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateDefaultCopayStripeId */
export type UpdateDefaultCopayStripeIdInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  patientId: Scalars['ID'];
  stripePaymentMethodId: Scalars['String'];
};

/** Autogenerated return type of UpdateDefaultCopayStripeId. */
export type UpdateDefaultCopayStripeIdPayload = {
  __typename?: 'UpdateDefaultCopayStripeIdPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** @deprecated Use `mutationErrors` instead */
  errors?: Maybe<Scalars['JSON']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateImproperlyClosedServiceTicketOrder */
export type UpdateImproperlyClosedServiceTicketOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  state: Scalars['String'];
};

/** Autogenerated return type of UpdateImproperlyClosedServiceTicketOrder. */
export type UpdateImproperlyClosedServiceTicketOrderPayload = {
  __typename?: 'UpdateImproperlyClosedServiceTicketOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  serviceTicketImproperlyClosedOrder: ServiceTicketImproperlyClosedOrder;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateInsurance */
export type UpdateInsuranceInput = {
  cardImageS3KeyBack?: InputMaybe<Scalars['String']>;
  cardImageS3KeyFront?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  coinsurance?: InputMaybe<Scalars['Int']>;
  comment?: InputMaybe<Scalars['String']>;
  copay?: InputMaybe<Scalars['Int']>;
  effectiveAt?: InputMaybe<Scalars['String']>;
  groupno?: InputMaybe<Scalars['String']>;
  holderAddress1?: InputMaybe<Scalars['String']>;
  holderAddress2?: InputMaybe<Scalars['String']>;
  holderCity?: InputMaybe<Scalars['String']>;
  holderDob?: InputMaybe<Scalars['String']>;
  holderFirstName?: InputMaybe<Scalars['String']>;
  holderLastName?: InputMaybe<Scalars['String']>;
  holderPhoneNumber?: InputMaybe<Scalars['String']>;
  holderState?: InputMaybe<Scalars['String']>;
  holderZipCode?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  insuranceCarrierId?: InputMaybe<Scalars['ID']>;
  insuranceImage?: InputMaybe<S3Attributes>;
  insuranceNetworkId?: InputMaybe<Scalars['ID']>;
  insurancePlanId?: InputMaybe<Scalars['ID']>;
  insuranceRelationshipTypeId?: InputMaybe<Scalars['ID']>;
  insuranceTypeId?: InputMaybe<Scalars['ID']>;
  mlOcrNeedsHumanReview?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  primary?: InputMaybe<Scalars['Boolean']>;
  subscriberNumber?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateInsurance. */
export type UpdateInsurancePayload = {
  __typename?: 'UpdateInsurancePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** @deprecated Use `mutationErrors` instead */
  errors?: Maybe<Scalars['JSON']>;
  insurance?: Maybe<Insurance>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateInsurancePlan */
export type UpdateInsurancePlanInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  insuranceTypeId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of UpdateInsurancePlan. */
export type UpdateInsurancePlanPayload = {
  __typename?: 'UpdateInsurancePlanPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  insurancePlan?: Maybe<InsurancePlan>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateInternalUserPreferences */
export type UpdateInternalUserPreferencesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  licensingBodies?: InputMaybe<Array<Scalars['String']>>;
  videoNotificationEnabled?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateInternalUserPreferences. */
export type UpdateInternalUserPreferencesPayload = {
  __typename?: 'UpdateInternalUserPreferencesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  internalUser?: Maybe<InternalUser>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateLabOrder */
export type UpdateLabOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  measurementOrderTypeSourceId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of UpdateLabOrder. */
export type UpdateLabOrderPayload = {
  __typename?: 'UpdateLabOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  labOrder?: Maybe<LabOrder>;
  success: Scalars['Boolean'];
};

export type UpdatePatientAddressAddressInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  id: Scalars['ID'];
  isPreferred?: InputMaybe<Scalars['Boolean']>;
  kind: AddressKind;
  state: Scalars['String'];
  status: AddressStatus;
  zipCode: Scalars['String'];
};

/** Autogenerated input type of UpdatePatientAddress */
export type UpdatePatientAddressInput = {
  address: UpdatePatientAddressAddressInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdatePatientAddress. */
export type UpdatePatientAddressPayload = {
  __typename?: 'UpdatePatientAddressPayload';
  address?: Maybe<Address>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdatePatientAdminComments */
export type UpdatePatientAdminCommentsInput = {
  adminComments: Scalars['Markdown'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  patientId: Scalars['ID'];
};

/** Autogenerated return type of UpdatePatientAdminComments. */
export type UpdatePatientAdminCommentsPayload = {
  __typename?: 'UpdatePatientAdminCommentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  patient?: Maybe<Patient>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdatePatientDemographics */
export type UpdatePatientDemographicsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  demographics: PatientDemographics;
  patientId: Scalars['ID'];
};

/** Autogenerated return type of UpdatePatientDemographics. */
export type UpdatePatientDemographicsPayload = {
  __typename?: 'UpdatePatientDemographicsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  patient: Patient;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdatePatientEmail */
export type UpdatePatientEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: PatientEmailInput;
};

/** Autogenerated return type of UpdatePatientEmail. */
export type UpdatePatientEmailPayload = {
  __typename?: 'UpdatePatientEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  email?: Maybe<Email>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdatePatientEmergencyContact */
export type UpdatePatientEmergencyContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  emergencyContact: EmergencyContactInputObject;
};

/** Autogenerated return type of UpdatePatientEmergencyContact. */
export type UpdatePatientEmergencyContactPayload = {
  __typename?: 'UpdatePatientEmergencyContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContact>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdatePatient */
export type UpdatePatientInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  officeId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of UpdatePatient. */
export type UpdatePatientPayload = {
  __typename?: 'UpdatePatientPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  /** @deprecated This field is not yet implemented. It is included for forward-compatibility reasons */
  mutationErrors?: Maybe<Array<MutationError>>;
  patient?: Maybe<Patient>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdatePatientPcp */
export type UpdatePatientPcpInput = {
  allowClosedPanels?: InputMaybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  officeId: Scalars['ID'];
  patientId?: InputMaybe<Scalars['ID']>;
  providerId: Scalars['ID'];
};

/** Autogenerated return type of UpdatePatientPcp. */
export type UpdatePatientPcpPayload = {
  __typename?: 'UpdatePatientPcpPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** @deprecated Use `mutationErrors` instead */
  errors?: Maybe<Scalars['JSON']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  patient?: Maybe<Patient>;
  /** @deprecated Do not use this for getting the patient's PCP as it circumvents Apollo client caching. Use `patient` instead. */
  provider?: Maybe<Provider>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdatePatientPhoneNumber */
export type UpdatePatientPhoneNumberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  phoneNumber: PatientPhoneNumberInput;
};

/** Autogenerated return type of UpdatePatientPhoneNumber. */
export type UpdatePatientPhoneNumberPayload = {
  __typename?: 'UpdatePatientPhoneNumberPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  phoneNumber?: Maybe<PhoneNumber>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdatePatientPreferences */
export type UpdatePatientPreferencesInput = {
  acceptsClinicalEmails?: InputMaybe<Scalars['Boolean']>;
  acceptsPatientTaskEmails?: InputMaybe<Scalars['Boolean']>;
  acceptsPatientTaskPush?: InputMaybe<Scalars['Boolean']>;
  avsHideWeight?: InputMaybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  connectedDevicesOptIn?: InputMaybe<Scalars['Boolean']>;
  fullTextNotifications?: InputMaybe<Scalars['Boolean']>;
  needsLanguageInterpreter?: InputMaybe<Scalars['Boolean']>;
  patientId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of UpdatePatientPreferences. */
export type UpdatePatientPreferencesPayload = {
  __typename?: 'UpdatePatientPreferencesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  /** @deprecated This field is not yet implemented. It is included for forward-compatibility reasons */
  mutationErrors?: Maybe<Array<MutationError>>;
  preferences: PatientPreferences;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdatePendingDirectSignupAccount */
export type UpdatePendingDirectSignupAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
};

/** Autogenerated return type of UpdatePendingDirectSignupAccount. */
export type UpdatePendingDirectSignupAccountPayload = {
  __typename?: 'UpdatePendingDirectSignupAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<DirectSignupError>;
  success: Scalars['Boolean'];
  token?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateProcedureInteraction */
export type UpdateProcedureInteractionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  formData?: InputMaybe<Scalars['JSON']>;
  id: Scalars['ID'];
  procedureSummary?: InputMaybe<Scalars['String']>;
  procedureTypeId?: InputMaybe<Scalars['ID']>;
  resultsInterpretation?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateProcedureInteraction. */
export type UpdateProcedureInteractionPayload = {
  __typename?: 'UpdateProcedureInteractionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  procedureInteraction?: Maybe<ProcedureInteraction>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateProfile */
export type UpdateProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  roleIds?: InputMaybe<Array<Scalars['ID']>>;
};

/** Autogenerated return type of UpdateProfile. */
export type UpdateProfilePayload = {
  __typename?: 'UpdateProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  profile: InternalUser;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateProgramCarePlan */
export type UpdateProgramCarePlanInput = {
  carePlan: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateProgramCarePlan. */
export type UpdateProgramCarePlanPayload = {
  __typename?: 'UpdateProgramCarePlanPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  programCarePlan?: Maybe<ProgramCarePlan>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateProgramEnrollmentCareTeam */
export type UpdateProgramEnrollmentCareTeamInput = {
  careGiverIds: Array<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateProgramEnrollmentCareTeam. */
export type UpdateProgramEnrollmentCareTeamPayload = {
  __typename?: 'UpdateProgramEnrollmentCareTeamPayload';
  careTeam?: Maybe<ProgramEnrollmentCareTeam>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateProgramEnrollment */
export type UpdateProgramEnrollmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  flag?: InputMaybe<Scalars['Boolean']>;
  frequency?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  outreachComment?: InputMaybe<Scalars['String']>;
  preferredTime?: InputMaybe<Scalars['String']>;
  programPhaseId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of UpdateProgramEnrollment. */
export type UpdateProgramEnrollmentPayload = {
  __typename?: 'UpdateProgramEnrollmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  programEnrollment?: Maybe<ProgramEnrollment>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateProgramForAppointment */
export type UpdateProgramForAppointmentInput = {
  appointmentId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  programId: Scalars['ID'];
};

/** Autogenerated return type of UpdateProgramForAppointment. */
export type UpdateProgramForAppointmentPayload = {
  __typename?: 'UpdateProgramForAppointmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  programEnrollment?: Maybe<ProgramEnrollment>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateProgramVisit */
export type UpdateProgramVisitInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  formData?: InputMaybe<Scalars['JSON']>;
  id: Scalars['ID'];
  summaryOfVisit?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateProgramVisit. */
export type UpdateProgramVisitPayload = {
  __typename?: 'UpdateProgramVisitPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  programVisit?: Maybe<ProgramVisit>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateRegistrationWaitlistEnrollment */
export type UpdateRegistrationWaitlistEnrollmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  patientId: Scalars['ID'];
  serviceAreaCode: Scalars['String'];
};

/** Autogenerated return type of UpdateRegistrationWaitlistEnrollment. */
export type UpdateRegistrationWaitlistEnrollmentPayload = {
  __typename?: 'UpdateRegistrationWaitlistEnrollmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  registrationWaitlistEnrollment?: Maybe<RegistrationWaitlistEnrollment>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateScheduleTemplate */
export type UpdateScheduleTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateScheduleTemplate. */
export type UpdateScheduleTemplatePayload = {
  __typename?: 'UpdateScheduleTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  scheduleTemplate?: Maybe<ScheduleTemplate>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateScheduleTemplateVariant */
export type UpdateScheduleTemplateVariantInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  scheduleTemplateId?: InputMaybe<Scalars['ID']>;
  startTime?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of UpdateScheduleTemplateVariant. */
export type UpdateScheduleTemplateVariantPayload = {
  __typename?: 'UpdateScheduleTemplateVariantPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
  variant?: Maybe<ScheduleTemplateVariant>;
};

/** Autogenerated input type of UpdateScheduleTemplateVariantSlots */
export type UpdateScheduleTemplateVariantSlotsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  slots: Array<SlotInput>;
  variantId: Scalars['ID'];
};

/** Autogenerated return type of UpdateScheduleTemplateVariantSlots. */
export type UpdateScheduleTemplateVariantSlotsPayload = {
  __typename?: 'UpdateScheduleTemplateVariantSlotsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  slots?: Maybe<Array<ScheduleTemplateVariantSlot>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateServiceTicket */
export type UpdateServiceTicketInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  processingInstructions?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateServiceTicketItem */
export type UpdateServiceTicketItemInput = {
  billingMethod?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  selfPayReason?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateServiceTicketItem. */
export type UpdateServiceTicketItemPayload = {
  __typename?: 'UpdateServiceTicketItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  serviceTicketItem?: Maybe<ServiceTicketItem>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateServiceTicket. */
export type UpdateServiceTicketPayload = {
  __typename?: 'UpdateServiceTicketPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  serviceTicket?: Maybe<ServiceTicket>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateStripePaymentMethodNickname */
export type UpdateStripePaymentMethodNicknameInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  stripePaymentMethodId: Scalars['String'];
};

/** Autogenerated return type of UpdateStripePaymentMethodNickname. */
export type UpdateStripePaymentMethodNicknamePayload = {
  __typename?: 'UpdateStripePaymentMethodNicknamePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  paymentMethod?: Maybe<PaymentMethod>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateTask */
export type UpdateTaskInput = {
  assigneeIdent?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  priority?: InputMaybe<Priority>;
  roamMessageLabelPredictionsAttributes?: InputMaybe<Array<RoamMessageLabelPredictionAttributes>>;
  tagIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  taskTemplateId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of UpdateTask. */
export type UpdateTaskPayload = {
  __typename?: 'UpdateTaskPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
  task?: Maybe<Task>;
};

/** Autogenerated input type of UpdateVoluntaryAlignmentOffer */
export type UpdateVoluntaryAlignmentOfferInput = {
  alignmentDocument?: InputMaybe<S3Attributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  followUpAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
  offerMethod?: InputMaybe<Scalars['String']>;
  offeredAt?: InputMaybe<Scalars['DateTime']>;
  offeredById?: InputMaybe<Scalars['ID']>;
  patientId?: InputMaybe<Scalars['ID']>;
  reason?: InputMaybe<Scalars['String']>;
  signedAt?: InputMaybe<Scalars['DateTime']>;
  state?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateVoluntaryAlignmentOffer. */
export type UpdateVoluntaryAlignmentOfferPayload = {
  __typename?: 'UpdateVoluntaryAlignmentOfferPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
  voluntaryAlignmentOffer?: Maybe<VoluntaryAlignmentOffer>;
};

/** Autogenerated input type of UpsertMlFeedbackTodoMessageReassignment */
export type UpsertMlFeedbackTodoMessageReassignmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  feedbackCategory?: InputMaybe<Scalars['String']>;
  feedbackText?: InputMaybe<Scalars['String']>;
  newAssigneeId?: InputMaybe<Scalars['ID']>;
  newAssigneeType?: InputMaybe<Scalars['String']>;
  todoMessageReassignmentId: Scalars['ID'];
};

/** Autogenerated return type of UpsertMlFeedbackTodoMessageReassignment. */
export type UpsertMlFeedbackTodoMessageReassignmentPayload = {
  __typename?: 'UpsertMlFeedbackTodoMessageReassignmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  success: Scalars['Boolean'];
  todoMessageReassignmentFeedback?: Maybe<TodoMessageReassignmentFeedback>;
};

export type UserEngagementMessage = {
  __typename?: 'UserEngagementMessage';
  id: Scalars['ID'];
  imageS3Pointer?: Maybe<Scalars['String']>;
  notificationSubtitle?: Maybe<Scalars['String']>;
  notificationTitle: Scalars['String'];
  /** The Call to Action Text for the User Engagement Message */
  primaryCtaText?: Maybe<Scalars['String']>;
  /** The Call to Action URL for the User Engagement Message */
  primaryCtaUrl?: Maybe<Scalars['String']>;
  /** The template text for the current_patient */
  text: Scalars['String'];
  title: Scalars['String'];
};

/** The connection type for UserEngagementMessage. */
export type UserEngagementMessageConnection = {
  __typename?: 'UserEngagementMessageConnection';
  /** A list of edges. */
  edges: Array<UserEngagementMessageEdge>;
  /** A list of nodes. */
  nodes: Array<UserEngagementMessage>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UserEngagementMessageEdge = {
  __typename?: 'UserEngagementMessageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<UserEngagementMessage>;
};

/** Creates User Engagement Message outreach batches */
export type UserEngagementMessageOutreach = OutreachManagerBatch & {
  __typename?: 'UserEngagementMessageOutreach';
  /** The type of order that this batch represents. */
  batchOrderType: OutreachManagerBatchOrderType;
  /** A timestamp noting when the record was created. */
  createdAt: Scalars['DateTime'];
  /** The internal user that created this batch. */
  createdBy: InternalUser;
  /** optional information about this batch of outreach  */
  description?: Maybe<Scalars['String']>;
  /** A unique identifier for this record. */
  id: Scalars['ID'];
  /** The Patients related to this batch. */
  outreachBatchPatients: OutreachBatchPatientConnection;
  s3Pointers: Array<S3Pointers>;
  /** Current batch status. */
  status: OutreachManagerStatus;
  /** A timestamp noting when the record was last modified. */
  updatedAt: Scalars['DateTime'];
  /** The User Engagement Message that this outreach batch will be sent with. */
  userEngagementMessage: UserEngagementMessage;
};


/** Creates User Engagement Message outreach batches */
export type UserEngagementMessageOutreachOutreachBatchPatientsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<OutreachBatchPatientStatus>;
};

/**
 * Languages spoken by internal users, such as providers.
 * In their onboarding survey, providers share what languages they feel comfortable using during appointments.
 * Note that a patient's preferred language is represented by Types::LanguageType.
 *
 */
export type UserLanguage = {
  __typename?: 'UserLanguage';
  id: Scalars['ID'];
  name: Scalars['String'];
  preferred: Scalars['Boolean'];
  proficiency: UserLanguageProficiencyEnum;
};

export enum UserLanguageProficiencyEnum {
  Elementary = 'elementary',
  LimitedWorking = 'limited_working',
  Native = 'native',
  ProfessionalFull = 'professional_full',
  ProfessionalWorking = 'professional_working',
  Unspecified = 'unspecified'
}

/** Autogenerated input type of ValidateClaimCode */
export type ValidateClaimCodeInput = {
  claimCode: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of ValidateClaimCode. */
export type ValidateClaimCodePayload = {
  __typename?: 'ValidateClaimCodePayload';
  claimCodeValidation?: Maybe<ClaimCodeValidation>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
};

export type VerifiedEligibleEmployee = {
  __typename?: 'VerifiedEligibleEmployee';
  b2bCompany: B2bCompany;
  dependents: Array<ScrubbedWhitelistedEmployee>;
  dob?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  homeCity?: Maybe<Scalars['String']>;
  homeState?: Maybe<Scalars['String']>;
  homeStreetAddress?: Maybe<Scalars['String']>;
  homeStreetAddress2?: Maybe<Scalars['String']>;
  homeZip?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDependent: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  registered: Scalars['Boolean'];
  workEmail?: Maybe<Scalars['String']>;
};

/** Attributes that will be used to process outreach for Visit Follow-Up Orders. */
export type VisitFollowUpOrderOutreach = OutreachManagerBatch & {
  __typename?: 'VisitFollowUpOrderOutreach';
  /** The type of order that this batch represents. */
  batchOrderType: OutreachManagerBatchOrderType;
  /** A timestamp noting when the record was created. */
  createdAt: Scalars['DateTime'];
  /** The internal user that created this batch. */
  createdBy: InternalUser;
  /** optional information about this batch of outreach  */
  description?: Maybe<Scalars['String']>;
  /** When will the appointment be due? */
  dueDate: Scalars['DateTime'];
  /** A unique identifier for this record. */
  id: Scalars['ID'];
  /** A note for the Visit Follow-Up Order. */
  note: Scalars['String'];
  /** The Patients related to this batch. */
  outreachBatchPatients: OutreachBatchPatientConnection;
  /** The appointment type group for the outreach. */
  outreachManagerAppointmentTypeGroup: OutreachManagerAppointmentTypeGroup;
  /** The problem type that will be attached to the outreach. */
  problemType?: Maybe<ProblemType>;
  s3Pointers: Array<S3Pointers>;
  /** Current batch status. */
  status: OutreachManagerStatus;
  /** A title for the Visit Follow-Up Order. */
  title?: Maybe<Scalars['String']>;
  /** A timestamp noting when the record was last modified. */
  updatedAt: Scalars['DateTime'];
};


/** Attributes that will be used to process outreach for Visit Follow-Up Orders. */
export type VisitFollowUpOrderOutreachOutreachBatchPatientsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<OutreachBatchPatientStatus>;
};

export type VisitMetadata = {
  __typename?: 'VisitMetadata';
  code?: Maybe<Scalars['Int']>;
  hostId?: Maybe<Scalars['ID']>;
  meetingUuid?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type VisitReasonAttributes = {
  categorizableId?: InputMaybe<Scalars['ID']>;
  categorizableType?: InputMaybe<Scalars['String']>;
  textToMatch?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['String']>;
};

export type VisitReasonMap = {
  __typename?: 'VisitReasonMap';
  mostBooked?: Maybe<Array<AppointmentType>>;
  remainingAvailable?: Maybe<Array<AppointmentType>>;
};

export type VitalSet = {
  __typename?: 'VitalSet';
  collectedAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  internalUser: InternalUser;
  measurements: Array<Measurement>;
  patientAgeAtCollectionInMonths: Scalars['Float'];
  readonly: Scalars['Boolean'];
  unsigned: Scalars['Boolean'];
  withings: Scalars['Boolean'];
};

/** The connection type for VitalSet. */
export type VitalSetConnection = {
  __typename?: 'VitalSetConnection';
  /** A list of edges. */
  edges: Array<VitalSetEdge>;
  /** A list of nodes. */
  nodes: Array<VitalSet>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type VitalSetEdge = {
  __typename?: 'VitalSetEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<VitalSet>;
};

export type Vitals = {
  __typename?: 'Vitals';
  heartRate: HeartRateMeasurementConnection;
  height: HeightMeasurementConnection;
  respiratoryRate: RespiratoryRateMeasurementConnection;
  sets: VitalSetConnection;
  temperature: TemperatureMeasurementConnection;
  weight: WeightMeasurementConnection;
};


export type VitalsHeartRateArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VitalsHeightArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VitalsRespiratoryRateArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VitalsSetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  hidePatientCollected?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VitalsTemperatureArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VitalsWeightArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type VoluntaryAlignmentOffer = {
  __typename?: 'VoluntaryAlignmentOffer';
  alignmentDocumentS3Link?: Maybe<Scalars['String']>;
  documentUrl?: Maybe<Scalars['String']>;
  documents: Array<Document>;
  docusignEnvelope?: Maybe<DocusignEnvelope>;
  followUpAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  offerMethod: Scalars['String'];
  offeredAt?: Maybe<Scalars['DateTime']>;
  offeredBy?: Maybe<InternalUser>;
  patient: Patient;
  reason?: Maybe<Scalars['String']>;
  resentAt?: Maybe<Scalars['DateTime']>;
  resentBy?: Maybe<InternalUser>;
  signedAt?: Maybe<Scalars['DateTime']>;
  state: Scalars['String'];
};

export type WaystarEligibilityStatus = {
  __typename?: 'WaystarEligibilityStatus';
  createdAt: Scalars['DateTime'];
  /** effective date */
  effectiveDate?: Maybe<Scalars['Date']>;
  /** eligibility status */
  eligibilityStatus?: Maybe<Scalars['String']>;
  /** expiration date */
  expirationDate?: Maybe<Scalars['Date']>;
  htmlResponse?: Maybe<Scalars['HTML']>;
  id: Scalars['ID'];
  individualRemainingDeductible?: Maybe<Scalars['Float']>;
  insurance?: Maybe<Insurance>;
  insuranceInformationBenefitCode?: Maybe<Scalars['String']>;
  insuranceNetwork?: Maybe<InsuranceNetwork>;
  mentalCoinsurance?: Maybe<Scalars['Float']>;
  mentalCopayment?: Maybe<Scalars['Float']>;
  officeCoinsurance?: Maybe<Scalars['Float']>;
  officeCopayment?: Maybe<Scalars['Float']>;
  parsedData?: Maybe<WaystarParsedData>;
  patient?: Maybe<Patient>;
  policyHolder?: Maybe<RelatedPerson>;
  request?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
  textResponse?: Maybe<Scalars['String']>;
  totalIndividualDeductible?: Maybe<Scalars['Float']>;
  transactionTrace?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  virtualCoinsurance?: Maybe<Scalars['Float']>;
  virtualCopayment?: Maybe<Scalars['Float']>;
  waystarGroupNumber?: Maybe<Scalars['String']>;
};

export type WaystarParsedData = {
  __typename?: 'WaystarParsedData';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  insuranceRelationshipType?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  messageInfo?: Maybe<Scalars['JSON']>;
  phoneNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** The connection type for Measurement. */
export type WeightMeasurementConnection = {
  __typename?: 'WeightMeasurementConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MeasurementEdge>>>;
  education: Education;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Measurement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type WhitelistedEmployee = {
  __typename?: 'WhitelistedEmployee';
  b2bCompany: B2bCompany;
  dependents: Array<ScrubbedWhitelistedEmployee>;
  employeeId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  isDependent: Scalars['Boolean'];
  registered: Scalars['Boolean'];
  workEmail?: Maybe<Scalars['String']>;
};

export type WorkSchedule = {
  __typename?: 'WorkSchedule';
  comment?: Maybe<Scalars['String']>;
  departureDate?: Maybe<Scalars['Date']>;
  fridaysOff: Scalars['Boolean'];
  id: Scalars['ID'];
  mondaysOff: Scalars['Boolean'];
  nextDayIn?: Maybe<Scalars['Date']>;
  onVacation: Scalars['Boolean'];
  out?: Maybe<Scalars['Boolean']>;
  returningOn?: Maybe<Scalars['Date']>;
  returningOnDate?: Maybe<Scalars['Date']>;
  saturdaysOff: Scalars['Boolean'];
  sundaysOff: Scalars['Boolean'];
  thursdaysOff: Scalars['Boolean'];
  tuesdaysOff: Scalars['Boolean'];
  wednesdaysOff: Scalars['Boolean'];
};

export enum WorryScore {
  Emergent = 'EMERGENT',
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  Unassigned = 'UNASSIGNED'
}

export type Zoom = {
  __typename?: 'Zoom';
  visitMetadata: VisitMetadata;
};


export type ZoomVisitMetadataArgs = {
  meetingId: Scalars['String'];
};

export type CurrentUserForLaunchDarklyQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserForLaunchDarklyQuery = { __typename?: 'Query', internalUser: { __typename?: 'InternalUser', id: string, firstName: string, lastName: string, email: string } };

export type FirebaseTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type FirebaseTokenQuery = { __typename?: 'Query', firebase?: { __typename?: 'Firebase', token: string } | null };

export type FirebaseNamespaceQueryVariables = Exact<{ [key: string]: never; }>;


export type FirebaseNamespaceQuery = { __typename?: 'Query', firebase?: { __typename?: 'Firebase', namespace: string } | null };

export type InternalUserNotificationPreferenceForNotifierQueryVariables = Exact<{ [key: string]: never; }>;


export type InternalUserNotificationPreferenceForNotifierQuery = { __typename?: 'Query', internalUser: { __typename: 'InternalUser', id: string, preferences: { __typename?: 'InternalUserPreferences', id: string, videoNotificationEnabled?: boolean | null } } };

export type InternalUserIdForNotifierQueryVariables = Exact<{ [key: string]: never; }>;


export type InternalUserIdForNotifierQuery = { __typename?: 'Query', internalUser: { __typename?: 'InternalUser', id: string } };

export type OpenTokTokenQueryVariables = Exact<{
  sessionId: Scalars['String'];
}>;


export type OpenTokTokenQuery = { __typename?: 'Query', openTokToken: string };

export type CreateVideoCallMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateVideoCallMutation = { __typename?: 'Mutation', createVideoCall?: { __typename?: 'CreateVideoCallPayload', success: boolean, errors?: any | null, meetingId?: string | null } | null };

export type JoinZoomMeetingInfoQueryVariables = Exact<{
  meetingId: Scalars['String'];
}>;


export type JoinZoomMeetingInfoQuery = { __typename?: 'Query', internalUser: { __typename?: 'InternalUser', id: string, displayName: string } };

export type LicensingBodyFieldsFragment = { __typename?: 'LicensingBody', id: string, shortName: string };

export type InternalUserVideoNotificationFragment = { __typename: 'InternalUser', id: string, preferences: { __typename?: 'InternalUserPreferences', id: string, videoNotificationEnabled?: boolean | null } };

export type InternalUserLicensingBodiesPreferenceFragment = { __typename?: 'InternalUser', id: string, preferences: { __typename?: 'InternalUserPreferences', id: string, licensingBodies?: Array<{ __typename?: 'LicensingBody', id: string, shortName: string }> | null } };

export type CachedPatientFragment = { __typename?: 'Patient', id: string, homeOffice?: { __typename?: 'Office', id: string, name: string } | null, serviceArea?: { __typename?: 'ServiceArea', id: string, name: string } | null, profileInfo: { __typename?: 'PatientProfileInfo', preferredName?: string | null, middleName?: string | null, lastName: string, ageInYears?: number | null, sex: string, pronounGroup: string, profileImageUrl?: string | null }, licensingBody?: { __typename?: 'State', id: string, shortName: string } | null, careTeam: { __typename?: 'CareTeam', pcp?: { __typename?: 'Provider', displayName: string } | null } };

export type CachedProviderFragment = { __typename?: 'Provider', id: string, displayName: string };

export type LicensingBodiesFilterPreferenceForFilterComponentQueryVariables = Exact<{ [key: string]: never; }>;


export type LicensingBodiesFilterPreferenceForFilterComponentQuery = { __typename?: 'Query', internalUser: { __typename?: 'InternalUser', id: string, preferences: { __typename?: 'InternalUserPreferences', id: string, licensingBodies?: Array<{ __typename?: 'LicensingBody', id: string, shortName: string }> | null } } };

export type LicensingBodiesQueryVariables = Exact<{ [key: string]: never; }>;


export type LicensingBodiesQuery = { __typename?: 'Query', licensingBodies: Array<{ __typename?: 'State', id: string, name: string, shortName: string }> };

export type SetLicensingBodiesPreferenceMutationVariables = Exact<{
  input: UpdateInternalUserPreferencesInput;
}>;


export type SetLicensingBodiesPreferenceMutation = { __typename?: 'Mutation', updateInternalUserPreferences?: { __typename?: 'UpdateInternalUserPreferencesPayload', success: boolean, errors?: any | null, internalUser?: { __typename?: 'InternalUser', id: string, preferences: { __typename?: 'InternalUserPreferences', id: string, licensingBodies?: Array<{ __typename?: 'LicensingBody', id: string, shortName: string }> | null } } | null } | null };

export type NotificationPreferenceQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationPreferenceQuery = { __typename?: 'Query', internalUser: { __typename: 'InternalUser', id: string, preferences: { __typename?: 'InternalUserPreferences', id: string, videoNotificationEnabled?: boolean | null } } };

export type UpdateNotificationPreferenceMutationVariables = Exact<{
  input: UpdateInternalUserPreferencesInput;
}>;


export type UpdateNotificationPreferenceMutation = { __typename?: 'Mutation', updateInternalUserPreferences?: { __typename?: 'UpdateInternalUserPreferencesPayload', success: boolean, errors?: any | null, internalUser?: { __typename: 'InternalUser', id: string, preferences: { __typename?: 'InternalUserPreferences', id: string, videoNotificationEnabled?: boolean | null } } | null } | null };

export type CurrentUserRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserRolesQuery = { __typename?: 'Query', internalUser: { __typename?: 'InternalUser', id: string, roles: Array<{ __typename?: 'RoleType', name: string }> } };

export type EndVideoCallMutationVariables = Exact<{
  input: EndVideoCallInput;
}>;


export type EndVideoCallMutation = { __typename?: 'Mutation', endVideoCall?: { __typename?: 'EndVideoCallPayload', success: boolean, errors?: any | null } | null };

export type ZoomVisitMetadataQueryVariables = Exact<{
  meetingId: Scalars['String'];
}>;


export type ZoomVisitMetadataQuery = { __typename?: 'Query', zoom: { __typename?: 'Zoom', visitMetadata: { __typename?: 'VisitMetadata', meetingUuid?: string | null, hostId?: string | null } } };

export type CreateSummaryMutationVariables = Exact<{
  input: CreateSummaryInput;
}>;


export type CreateSummaryMutation = { __typename?: 'Mutation', createSummary?: { __typename?: 'CreateSummaryPayload', success: boolean, mutationErrors?: Array<{ __typename?: 'MutationError', messages: Array<string>, path: Array<string> }> | null, summary?: { __typename?: 'Summary', id: string } | null } | null };

export type InternalUserInfoForMainWindowQueryVariables = Exact<{ [key: string]: never; }>;


export type InternalUserInfoForMainWindowQuery = { __typename?: 'Query', internalUser: { __typename?: 'InternalUser', id: string, displayName: string, homeOffice?: { __typename?: 'Office', id: string } | null, serviceArea?: { __typename?: 'ServiceArea', serviceAreaGroup?: { __typename?: 'ServiceAreaGroup', serviceAreas?: Array<{ __typename?: 'ServiceArea', id: string }> | null } | null } | null } };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', internalUser: { __typename?: 'InternalUser', id: string } };

export type PatientNameForVirtualVisitQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type PatientNameForVirtualVisitQuery = { __typename?: 'Query', patients: Array<{ __typename?: 'Patient', profileInfo: { __typename?: 'PatientProfileInfo', preferredName?: string | null, lastName: string, pronounGroup: string } }> };

export type PatientInfoForVirtualVisitsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type PatientInfoForVirtualVisitsQuery = { __typename?: 'Query', patients: Array<{ __typename?: 'Patient', id: string, homeOffice?: { __typename?: 'Office', id: string, name: string } | null, serviceArea?: { __typename?: 'ServiceArea', id: string, name: string } | null, profileInfo: { __typename?: 'PatientProfileInfo', preferredName?: string | null, middleName?: string | null, lastName: string, ageInYears?: number | null, sex: string, pronounGroup: string, profileImageUrl?: string | null }, licensingBody?: { __typename?: 'State', id: string, shortName: string } | null, careTeam: { __typename?: 'CareTeam', pcp?: { __typename?: 'Provider', displayName: string } | null } }> };

export type ClaimedByInfoQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type ClaimedByInfoQuery = { __typename?: 'Query', providers: { __typename?: 'ProviderConnection', nodes: Array<{ __typename?: 'Provider', id: string, displayName: string }> } };

export type LicensingBodiesFilterPreferenceForVisitsServiceQueryVariables = Exact<{ [key: string]: never; }>;


export type LicensingBodiesFilterPreferenceForVisitsServiceQuery = { __typename?: 'Query', internalUser: { __typename?: 'InternalUser', id: string, preferences: { __typename?: 'InternalUserPreferences', id: string, licensingBodies?: Array<{ __typename?: 'LicensingBody', id: string, shortName: string }> | null } } };

export type CurrentUserForVirtualVisitsQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserForVirtualVisitsQuery = { __typename?: 'Query', internalUser: { __typename?: 'InternalUser', id: string } };

export type LicensingBodyLookupQueryVariables = Exact<{ [key: string]: never; }>;


export type LicensingBodyLookupQuery = { __typename?: 'Query', licensingBodies: Array<{ __typename?: 'State', id: string, name: string, shortName: string }> };

export const InternalUserVideoNotificationFragmentDoc = gql`
    fragment InternalUserVideoNotification on InternalUser {
  id
  preferences {
    id
    videoNotificationEnabled
  }
  __typename
}
    `;
export const LicensingBodyFieldsFragmentDoc = gql`
    fragment LicensingBodyFields on LicensingBody {
  id
  shortName
}
    `;
export const InternalUserLicensingBodiesPreferenceFragmentDoc = gql`
    fragment InternalUserLicensingBodiesPreference on InternalUser {
  id
  preferences {
    id
    licensingBodies {
      ...LicensingBodyFields
    }
  }
}
    ${LicensingBodyFieldsFragmentDoc}`;
export const CachedPatientFragmentDoc = gql`
    fragment CachedPatient on Patient {
  id
  homeOffice {
    id
    name
  }
  serviceArea {
    id
    name
  }
  profileInfo {
    preferredName
    middleName
    lastName
    ageInYears
    sex
    pronounGroup
    profileImageUrl
  }
  licensingBody {
    id
    shortName
  }
  careTeam {
    pcp {
      displayName
    }
  }
}
    `;
export const CachedProviderFragmentDoc = gql`
    fragment CachedProvider on Provider {
  id
  displayName
}
    `;
export const CurrentUserForLaunchDarklyDocument = gql`
    query CurrentUserForLaunchDarkly {
  internalUser {
    id
    firstName
    lastName
    email
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserForLaunchDarklyGQL extends Apollo.Query<CurrentUserForLaunchDarklyQuery, CurrentUserForLaunchDarklyQueryVariables> {
    document = CurrentUserForLaunchDarklyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FirebaseTokenDocument = gql`
    query FirebaseToken {
  firebase {
    token
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FirebaseTokenGQL extends Apollo.Query<FirebaseTokenQuery, FirebaseTokenQueryVariables> {
    document = FirebaseTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FirebaseNamespaceDocument = gql`
    query FirebaseNamespace {
  firebase {
    namespace
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FirebaseNamespaceGQL extends Apollo.Query<FirebaseNamespaceQuery, FirebaseNamespaceQueryVariables> {
    document = FirebaseNamespaceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InternalUserNotificationPreferenceForNotifierDocument = gql`
    query InternalUserNotificationPreferenceForNotifier {
  internalUser {
    ...InternalUserVideoNotification
  }
}
    ${InternalUserVideoNotificationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class InternalUserNotificationPreferenceForNotifierGQL extends Apollo.Query<InternalUserNotificationPreferenceForNotifierQuery, InternalUserNotificationPreferenceForNotifierQueryVariables> {
    document = InternalUserNotificationPreferenceForNotifierDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InternalUserIdForNotifierDocument = gql`
    query InternalUserIdForNotifier {
  internalUser {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InternalUserIdForNotifierGQL extends Apollo.Query<InternalUserIdForNotifierQuery, InternalUserIdForNotifierQueryVariables> {
    document = InternalUserIdForNotifierDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OpenTokTokenDocument = gql`
    query OpenTokToken($sessionId: String!) {
  openTokToken(sessionId: $sessionId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OpenTokTokenGQL extends Apollo.Query<OpenTokTokenQuery, OpenTokTokenQueryVariables> {
    document = OpenTokTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateVideoCallDocument = gql`
    mutation CreateVideoCall {
  createVideoCall(input: {}) {
    success
    errors
    meetingId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateVideoCallGQL extends Apollo.Mutation<CreateVideoCallMutation, CreateVideoCallMutationVariables> {
    document = CreateVideoCallDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JoinZoomMeetingInfoDocument = gql`
    query JoinZoomMeetingInfo($meetingId: String!) {
  internalUser {
    id
    displayName
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class JoinZoomMeetingInfoGQL extends Apollo.Query<JoinZoomMeetingInfoQuery, JoinZoomMeetingInfoQueryVariables> {
    document = JoinZoomMeetingInfoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LicensingBodiesFilterPreferenceForFilterComponentDocument = gql`
    query LicensingBodiesFilterPreferenceForFilterComponent {
  internalUser {
    ...InternalUserLicensingBodiesPreference
  }
}
    ${InternalUserLicensingBodiesPreferenceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LicensingBodiesFilterPreferenceForFilterComponentGQL extends Apollo.Query<LicensingBodiesFilterPreferenceForFilterComponentQuery, LicensingBodiesFilterPreferenceForFilterComponentQueryVariables> {
    document = LicensingBodiesFilterPreferenceForFilterComponentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LicensingBodiesDocument = gql`
    query LicensingBodies {
  licensingBodies(excludeTerritories: true) {
    id
    name
    shortName
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LicensingBodiesGQL extends Apollo.Query<LicensingBodiesQuery, LicensingBodiesQueryVariables> {
    document = LicensingBodiesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetLicensingBodiesPreferenceDocument = gql`
    mutation SetLicensingBodiesPreference($input: UpdateInternalUserPreferencesInput!) {
  updateInternalUserPreferences(input: $input) {
    success
    errors
    internalUser {
      ...InternalUserLicensingBodiesPreference
    }
  }
}
    ${InternalUserLicensingBodiesPreferenceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SetLicensingBodiesPreferenceGQL extends Apollo.Mutation<SetLicensingBodiesPreferenceMutation, SetLicensingBodiesPreferenceMutationVariables> {
    document = SetLicensingBodiesPreferenceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NotificationPreferenceDocument = gql`
    query NotificationPreference {
  internalUser {
    ...InternalUserVideoNotification
  }
}
    ${InternalUserVideoNotificationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NotificationPreferenceGQL extends Apollo.Query<NotificationPreferenceQuery, NotificationPreferenceQueryVariables> {
    document = NotificationPreferenceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateNotificationPreferenceDocument = gql`
    mutation UpdateNotificationPreference($input: UpdateInternalUserPreferencesInput!) {
  updateInternalUserPreferences(input: $input) {
    success
    errors
    internalUser {
      ...InternalUserVideoNotification
    }
  }
}
    ${InternalUserVideoNotificationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateNotificationPreferenceGQL extends Apollo.Mutation<UpdateNotificationPreferenceMutation, UpdateNotificationPreferenceMutationVariables> {
    document = UpdateNotificationPreferenceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserRolesDocument = gql`
    query CurrentUserRoles {
  internalUser {
    id
    roles {
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserRolesGQL extends Apollo.Query<CurrentUserRolesQuery, CurrentUserRolesQueryVariables> {
    document = CurrentUserRolesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EndVideoCallDocument = gql`
    mutation EndVideoCall($input: EndVideoCallInput!) {
  endVideoCall(input: $input) {
    success
    errors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EndVideoCallGQL extends Apollo.Mutation<EndVideoCallMutation, EndVideoCallMutationVariables> {
    document = EndVideoCallDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ZoomVisitMetadataDocument = gql`
    query ZoomVisitMetadata($meetingId: String!) {
  zoom {
    visitMetadata(meetingId: $meetingId) {
      meetingUuid
      hostId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ZoomVisitMetadataGQL extends Apollo.Query<ZoomVisitMetadataQuery, ZoomVisitMetadataQueryVariables> {
    document = ZoomVisitMetadataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateSummaryDocument = gql`
    mutation CreateSummary($input: CreateSummaryInput!) {
  createSummary(input: $input) {
    success
    mutationErrors {
      messages
      path
    }
    summary {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSummaryGQL extends Apollo.Mutation<CreateSummaryMutation, CreateSummaryMutationVariables> {
    document = CreateSummaryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InternalUserInfoForMainWindowDocument = gql`
    query InternalUserInfoForMainWindow {
  internalUser {
    id
    displayName
    homeOffice {
      id
    }
    serviceArea {
      serviceAreaGroup {
        serviceAreas {
          id
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InternalUserInfoForMainWindowGQL extends Apollo.Query<InternalUserInfoForMainWindowQuery, InternalUserInfoForMainWindowQueryVariables> {
    document = InternalUserInfoForMainWindowDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserDocument = gql`
    query CurrentUser {
  internalUser {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserGQL extends Apollo.Query<CurrentUserQuery, CurrentUserQueryVariables> {
    document = CurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PatientNameForVirtualVisitDocument = gql`
    query PatientNameForVirtualVisit($ids: [ID!]!) {
  patients(ids: $ids) {
    profileInfo {
      preferredName
      lastName
      pronounGroup
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PatientNameForVirtualVisitGQL extends Apollo.Query<PatientNameForVirtualVisitQuery, PatientNameForVirtualVisitQueryVariables> {
    document = PatientNameForVirtualVisitDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PatientInfoForVirtualVisitsDocument = gql`
    query PatientInfoForVirtualVisits($ids: [ID!]!) {
  patients(ids: $ids) {
    ...CachedPatient
  }
}
    ${CachedPatientFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PatientInfoForVirtualVisitsGQL extends Apollo.Query<PatientInfoForVirtualVisitsQuery, PatientInfoForVirtualVisitsQueryVariables> {
    document = PatientInfoForVirtualVisitsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ClaimedByInfoDocument = gql`
    query ClaimedByInfo($ids: [ID!]) {
  providers: providersConnection(ids: $ids) {
    nodes {
      ...CachedProvider
    }
  }
}
    ${CachedProviderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ClaimedByInfoGQL extends Apollo.Query<ClaimedByInfoQuery, ClaimedByInfoQueryVariables> {
    document = ClaimedByInfoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LicensingBodiesFilterPreferenceForVisitsServiceDocument = gql`
    query LicensingBodiesFilterPreferenceForVisitsService {
  internalUser {
    ...InternalUserLicensingBodiesPreference
  }
}
    ${InternalUserLicensingBodiesPreferenceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LicensingBodiesFilterPreferenceForVisitsServiceGQL extends Apollo.Query<LicensingBodiesFilterPreferenceForVisitsServiceQuery, LicensingBodiesFilterPreferenceForVisitsServiceQueryVariables> {
    document = LicensingBodiesFilterPreferenceForVisitsServiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserForVirtualVisitsDocument = gql`
    query CurrentUserForVirtualVisits {
  internalUser {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserForVirtualVisitsGQL extends Apollo.Query<CurrentUserForVirtualVisitsQuery, CurrentUserForVirtualVisitsQueryVariables> {
    document = CurrentUserForVirtualVisitsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LicensingBodyLookupDocument = gql`
    query LicensingBodyLookup {
  licensingBodies(excludeTerritories: true) {
    id
    name
    shortName
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LicensingBodyLookupGQL extends Apollo.Query<LicensingBodyLookupQuery, LicensingBodyLookupQueryVariables> {
    document = LicensingBodyLookupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }