import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable, of as observableOf, Subject, throwError as observableThrowError } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';

import { ConfigService } from '@app/core/config.service';
import { VideoProvider } from '@app/core/models/video-provider';
import { WindowService } from '@app/core/window.service';
import { CreateVideoCall, CreateVideoCall_createVideoCall } from '@app/core/zoom/__generated__/CreateVideoCall';
import { JoinZoomMeetingInfo, JoinZoomMeetingInfoVariables } from '@app/core/zoom/__generated__/JoinZoomMeetingInfo';

export interface MeetingConfig {
  meetingNumber: string;
  userName: string;
  signature: string;
  apiKey: string;
}

export const GET_ZOOM_MEETING_ID_QUERY = gql`
  mutation CreateVideoCall {
    createVideoCall(input: {}) {
      success
      errors
      meetingId
    }
  }
`;

export const JOIN_ZOOM_MEETING_INFO = gql`
  query JoinZoomMeetingInfo($meetingId: String!) {
    internalUser {
      id
      displayName
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ZoomService implements VideoProvider {
  feedCount$ = new Subject<number>();
  initializingPublishing$ = new Subject<void>();
  patientDropped$ = new Subject<void>();

  constructor(private apollo: Apollo, private config: ConfigService, private windowService: WindowService) {}

  createMeeting(): Observable<string> {
    return this.apollo.mutate({ mutation: GET_ZOOM_MEETING_ID_QUERY }).pipe(
      map((response: ApolloQueryResult<CreateVideoCall>) => response.data.createVideoCall),
      switchMap((data: CreateVideoCall_createVideoCall) => {
        if (data.success) {
          return observableOf(data.meetingId);
        } else {
          return observableThrowError(data.errors);
        }
      }),
    );
  }

  init(sessionId: string): Observable<MeetingConfig> {
    return this.apollo
      .query<JoinZoomMeetingInfo, JoinZoomMeetingInfoVariables>({
        query: JOIN_ZOOM_MEETING_INFO,
        variables: { meetingId: sessionId },
      })
      .pipe(
        map(result => ({
          meetingNumber: sessionId,
          userName: result.data.internalUser.displayName,
          signature: undefined,
          apiKey: this.config.environment.zoom.apiKey,
        })),
      );
  }

  startCall$(): Observable<void> {
    // This intentionally never fires. The provider will admit the patient within the Zoom UI,
    // but since we can't easily know this (Zoom doesn't provide a hook) the mobile app will instead
    // detect this and start the call.
    return new Subject<void>();
  }

  endCall$(): Observable<void> {
    const event = new CustomEvent<void>('zoom-end-meeting');
    this.windowService.dispatchEvent(event);

    return observableOf(undefined).pipe(delay(500));
  }
}
