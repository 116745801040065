import { Injectable } from '@angular/core';
import { sha512 } from 'sha.js';
import SumoLogger, { SumoLoggerOptions } from 'sumo-logger';

import { ConfigService } from '@app/core/config.service';
import { CookieStorageService } from '@app/core/cookie-storage.service';
import { RawVirtualVisit } from '@app/core/firebase/firestore.service';
import { ErrorLogger, Logger } from '@app/core/models/logger';
import { OAuthService } from '@app/core/o-auth/o-auth.service';

interface LogPayload {
  id?: string;
  action: FirestoreActionType;
  changes?: VisitChanges;
  document?: RawVirtualVisit;
  error?: string;
}

export interface Diff<T> {
  previous: T;
  next: T;
}

export interface VisitChanges {
  visitState?: Diff<RawVirtualVisit['visitState']>;
  queuedBy?: Diff<RawVirtualVisit['queuedBy']>;
  queuedAt?: Diff<RawVirtualVisit['queuedAt']>;
  licensingBody?: Diff<RawVirtualVisit['licensingBody']>;
  claimedAt?: Diff<RawVirtualVisit['claimedAt']>;
  claimedBy?: Diff<RawVirtualVisit['claimedBy']>;
  startedAt?: Diff<RawVirtualVisit['startedAt']>;
  callbackRequested?: Diff<RawVirtualVisit['callbackRequested']>;
  endedAt?: Diff<RawVirtualVisit['endedAt']>;
  endedBy?: Diff<RawVirtualVisit['endedBy']>;
}

export const potentialChanges = [
  'visitState',
  'queuedBy',
  'queuedAt',
  'licensingBody',
  'claimedAt',
  'claimedBy',
  'startedAt',
  'callbackRequested',
  'endedAt',
  'endedBy',
];

export enum FirestoreActionType {
  Create = 'create',
  Delete = 'delete',
  ClaimCall = 'claim call',
  EndCall = 'end call',
  StartCall = 'start call',
  UnclaimCall = 'unclaim call',
  UnstartCall = 'unstart call',
  Update = 'update',
}

export const sensitiveFields: (keyof RawVirtualVisit)[] = [
  'reasonForVisit',
  'openTokSessionId',
  'zoomMeetingId',
  'meta',
];

@Injectable({
  providedIn: 'root',
})
export class LoggerService implements ErrorLogger, Logger {
  private _logger: SumoLogger;

  constructor(private config: ConfigService, private cookieStorageService: CookieStorageService) {}

  log(message: string, fields?: Record<string, any>): void {
    if (this.config.environment.localLogs || !this.logger) {
      // eslint-disable-next-line no-console
      console.log(`LoggerService: ${message}, ${JSON.stringify(fields, null, 2)}`);
    } else {
      this.logger.log({
        msg: message,
        ...fields,
      });
    }
  }

  error(error: Error): void {
    this.log(error.message, { stacktrace: error.stack, name: error.name });
  }

  logAction({ id, ...payload }: LogPayload): void {
    sensitiveFields.forEach(field => (payload.document[field.toString()] = '********'));

    if (this.config.environment.localLogs || !this.logger) {
      // eslint-disable-next-line no-console
      console.log({ visitId: id, ...payload });
    } else {
      this.logger.log({ visitId: id, ...payload });
    }
  }

  private get logger(): SumoLogger {
    if (this._logger) {
      return this._logger;
    }

    const options: SumoLoggerOptions = {
      endpoint: this.config.loggerEndpoint,
      sendErrors: false,
      sourceName: 'virtual-visits',
      sourceCategory: `${this.config.environment.environmentName}/vv-dashboard`,
      clientUrl: window.location.href,
    };

    const token = this.cookieStorageService.getItem(OAuthService.StorageKey);
    if (token) {
      options.sessionKey = new sha512().update(token).digest('hex');
    }

    this._logger = new SumoLogger(options);

    return this._logger;
  }
}
