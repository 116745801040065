import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { OAuthService } from '@app/core/o-auth/o-auth.service';

@Injectable({
  providedIn: 'root',
})
export class OAuthGuard implements CanActivate {
  constructor(private activatedRoute: ActivatedRoute, private oAuthService: OAuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.oAuthService.isAuthenticated) {
      return true;
    }

    const { code } = route.queryParams;

    if (code) {
      return this.validateTokenOrLogin(code);
    } else {
      this.oAuthService.login();
      return false;
    }
  }

  validateTokenOrLogin(code) {
    return this.oAuthService.verifyAccessToken(code).pipe(
      take(1),
      map(_ => this.router.parseUrl('/')),
    );
  }
}
