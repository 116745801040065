import { Injectable } from '@angular/core';

import { CookieStorageService } from '@app/core/cookie-storage.service';
import { LocalStorageService } from '@app/core/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ClearStorageService {
  constructor(private cookieStorage: CookieStorageService, private localStorage: LocalStorageService) {}

  clearAll() {
    this.cookieStorage.clear();
    this.localStorage.clear();
  }
}
