import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface NavBarUrls {
  home: string;
  tasks: string;
  appointments: string;
  providerSchedule: string;
  walkInVisits: string;
  officeSchedule: string;
  virtualVisits: string;
  docs: string;
  faxes: string;
  contacts: string;
  providers: string;
}

@Component({
  selector: 'omgui-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent {
  /** Links for all of the menu items */
  @Input() links: NavBarUrls;
  /** Name of the logged-in user */
  @Input() name: string;
  /** Emits when the logout menu item is clicked */
  @Output() logoutClicked = new EventEmitter<void>();

  /** @ignore */
  appointmentMenuActive = false;
  /** @ignore */
  userMenuActive = false;

  /** @ignore */
  setAppointmentMenuActive(appointmentMenuActive: boolean) {
    this.appointmentMenuActive = appointmentMenuActive;
  }

  /** @ignore */
  setUserMenuActive(userMenuActive: boolean) {
    this.userMenuActive = userMenuActive;
  }
}
