import { Injectable } from '@angular/core';

import { LoggerService } from '@app/core/logger.service';

export interface BroadcastableChannel {
  addEventListener(...args: any[]): any;
  postMessage(...args: any[]): any;
}

export class UnsupportedBroadcastChannel implements BroadcastableChannel {
  constructor(private logger: LoggerService) {
    this.logger.log('This client does not support BroadcastChannels');
  }

  addEventListener(...args: any[]) {}

  postMessage(...args: any[]) {}
}

@Injectable({
  providedIn: 'root',
})
export class BroadcastChannelService {
  browserSupported: boolean;

  constructor(private logger: LoggerService) {
    this.browserSupported = 'BroadcastChannel' in self;
  }

  createBroadcastChannel(channelName: string): BroadcastableChannel {
    if (this.browserSupported) {
      return new BroadcastChannel(channelName);
    } else {
      return new UnsupportedBroadcastChannel(this.logger);
    }
  }
}
