import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';

import { CoreModule } from '@app/core/core.module';
import { UIModule } from '@app/ui/ui.module';
import { ActionButtonComponent } from '@app/video-container/action-button/action-button.component';
import { StatusMessageComponent } from '@app/video-container/status-message/status-message.component';
import { VideoContainerComponent } from '@app/video-container/video-container.component';

import { OpenTokComponent } from './open-tok/open-tok.component';
import { ZoomComponent } from './zoom/zoom.component';

@NgModule({
  declarations: [
    VideoContainerComponent,
    StatusMessageComponent,
    ActionButtonComponent,
    OpenTokComponent,
    ZoomComponent,
  ],
  imports: [CoreModule, CommonModule, UIModule, NgbAlertModule],
})
export class VideoContainerModule {}
