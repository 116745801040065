import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { ErrorWithExtras, hasExtras } from '@app/core/error.service';
import { CLAIMED_BY_INFO_QUERY_NAME, GET_PATIENT_INFO_QUERY_NAME } from '@app/core/graphql-query-names';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
  dsn: 'https://2092efc03b9fd243c91726a5e04b7657@o4506221115408384.ingest.us.sentry.io/4506934274555904',
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend: (event, hint) => {
    const EXEMPT_OPERATIONS = [CLAIMED_BY_INFO_QUERY_NAME, GET_PATIENT_INFO_QUERY_NAME];
    const EXEMPT_STATUSES = [0];

    const IGNORED_MESSAGES = [
      /.*\/api\/graphql: 401/,
      /permission_denied at .*: Client doesn't have permission to access the desired data/,
      /Request has been terminated.*/, // usually due to adblocker
      /play\(\) failed because the user didn't interact with the document first/, // sound playing failed
    ];

    const error = hint.originalException as Error | ErrorWithExtras;

    const isExemptOperation =
      hasExtras(error) &&
      EXEMPT_OPERATIONS.includes(error.extras.operation?.operationName) &&
      EXEMPT_STATUSES.includes(error.extras.networkError?.status);

    const isIgnoredErrorMessage =
      !!error.message && IGNORED_MESSAGES.some(ignoredMessage => !!error.message.match(ignoredMessage));

    if (isExemptOperation || isIgnoredErrorMessage) {
      return null;
    }

    if (hasExtras(error)) {
      event.extra = error.extras;
    }

    return event;
  },
  environment: environment.environmentName,
  enabled: environment.sentry.enabled,
});

if (environment.enableProdMode) {
  enableProdMode();
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('service_worker.js', { updateViaCache: 'none' });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
