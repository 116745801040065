import { HttpClientModule, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ApolloLink, from, InMemoryCache } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import { ClearStorageService } from '@app/core/clear-storage.service';
import { ConfigService } from '@app/core/config.service';
import { ErrorWithExtras } from '@app/core/error.service';
import { OAuthService } from '@app/core/o-auth/o-auth.service';

export class UnhandledGraphQLError extends ErrorWithExtras {
  // todo: can this be type Extras ?
  constructor(extras: Record<string, any>) {
    super('Unhandled GraphQL Link Error', extras);
    this.name = 'UnhandledGraphQLError';
  }
}

export function createAuthLink(oAuthService: OAuthService, clearStorageService: ClearStorageService) {
  return new ApolloLink((operation, forward) => {
    const token = oAuthService.token;

    if (!token) {
      clearStorageService.clearAll();
      oAuthService.login();
    }

    const authHeader = `Bearer ${token}`;
    operation.setContext({
      headers: new HttpHeaders().set('Authorization', authHeader),
    });

    return forward(operation);
  });
}

export function createUnauthorizedLink(oAuthService: OAuthService, clearStorageService: ClearStorageService) {
  return onError(({ graphQLErrors, networkError, operation, response }) => {
    if (networkError && (<HttpErrorResponse>networkError).status === 401) {
      clearStorageService.clearAll();
      oAuthService.login();
    } else {
      throw new UnhandledGraphQLError({
        graphQLErrors,
        networkError,
        response,
        operation,
        blankToken: oAuthService.token === '',
        invalidToken: !oAuthService.token,
      });
    }
  });
}

export function createApollo(
  httpLink: HttpLink,
  oAuthService: OAuthService,
  configService: ConfigService,
  clearStorageService: ClearStorageService,
) {
  const uri = `${configService.environment.apiServer}/api/graphql`;
  const http = httpLink.create({ uri });

  const authLink = createAuthLink(oAuthService, clearStorageService);
  const unauthorizedLink = createUnauthorizedLink(oAuthService, clearStorageService);

  return {
    link: from([unauthorizedLink, authLink, http]),
    cache: new InMemoryCache({
      typePolicies: {
        InternalUserPreferences: {
          fields: {
            licensingBodies: {
              merge(_existing, incoming) {
                return incoming;
              },
            },
          },
        },
        Query: {
          fields: {
            firebase: {
              merge(_existing, incoming) {
                return incoming;
              },
            },
          },
        },
      },
    }),
  };
}

@NgModule({
  exports: [],
  imports: [ApolloModule, HttpClientModule, BrowserModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, OAuthService, ConfigService, ClearStorageService],
    },
  ],
})
export class GraphQLModule {}
