import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';

import { RealtimeDbAuthService, VirtualVisitStore } from '@app/core/models/realtime-db';

import { FirebaseAuthService } from './firebase/firebase-auth.service';
import { FirestoreService } from './firebase/firestore.service';

@Injectable({
  providedIn: 'root',
})
export class RealtimeDbService {
  auth: RealtimeDbAuthService;
  store: VirtualVisitStore<firebase.firestore.FieldValue>;

  constructor(private firebaseAuthService: FirebaseAuthService, private firestoreService: FirestoreService) {
    this.auth = firebaseAuthService;
    this.store = firestoreService;
  }
}
