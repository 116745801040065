import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { NavBarComponent } from '@app/ui/nav-bar/nav-bar.component';
import {
  NavItemComponent,
  NavItemEndContentDirective,
  NavItemIconDirective,
} from '@app/ui/nav-item/nav-item.component';
import { IsErrorStatePipe, IsLoadingStatePipe, IsSuccessStatePipe } from '@app/ui/pipes/page-state.pipe';
import { RelativeTimePipe } from '@app/ui/pipes/relative-time.pipe';
import { SvgCircleArrowUpComponent } from '@app/ui/svgs/svg-circle-arrow-up.component';
import { SvgClockComponent } from '@app/ui/svgs/svg-clock.component';
import { SvgOmLogoComponent } from '@app/ui/svgs/svg-om-logo.component';
import { ToggleComponent } from '@app/ui/toggle/toggle.component';

import { AlertComponent } from './alert/alert.component';
import { BadgeComponent } from './badge/badge.component';
import { MultiselectComponent } from './multiselect/multiselect.component';
import { PrimaryButtonComponent } from './primary-button/primary-button.component';
import { SvgTodoComponent } from './svgs/svg-todo.component';
import { SvgXComponent } from './svgs/svg-x.component';
import {
  TableColDirective,
  TableComponent,
  TableContentDirective,
  TableEmptyStateDirective,
  TableHeaderDirective,
  TableRowDirective,
} from './table/table.component';

@NgModule({
  declarations: [
    AlertComponent,
    BadgeComponent,
    IsErrorStatePipe,
    IsLoadingStatePipe,
    IsSuccessStatePipe,
    MultiselectComponent,
    NavBarComponent,
    NavItemComponent,
    NavItemEndContentDirective,
    NavItemIconDirective,
    PrimaryButtonComponent,
    RelativeTimePipe,
    SvgCircleArrowUpComponent,
    SvgClockComponent,
    SvgOmLogoComponent,
    SvgTodoComponent,
    SvgXComponent,
    TableColDirective,
    TableComponent,
    TableContentDirective,
    TableEmptyStateDirective,
    TableHeaderDirective,
    TableRowDirective,
    ToggleComponent,
  ],
  exports: [
    AlertComponent,
    BadgeComponent,
    IsErrorStatePipe,
    IsLoadingStatePipe,
    IsSuccessStatePipe,
    MultiselectComponent,
    NavBarComponent,
    NavItemComponent,
    NavItemEndContentDirective,
    NavItemIconDirective,
    PrimaryButtonComponent,
    RelativeTimePipe,
    SvgCircleArrowUpComponent,
    SvgClockComponent,
    SvgOmLogoComponent,
    SvgTodoComponent,
    SvgXComponent,
    TableColDirective,
    TableComponent,
    TableContentDirective,
    TableEmptyStateDirective,
    TableHeaderDirective,
    TableRowDirective,
    ToggleComponent,
  ],
  imports: [CommonModule, FormsModule, NgSelectModule, NgbModule],
})
export class UIModule {}
