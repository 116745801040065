import { Observable } from 'rxjs';

export const PATIENT_DOM_ID = 'patient';
export const INTERNAL_USER_DOM_ID = 'internal-user';

export interface VideoProvider {
  patientDropped$: Observable<void>;
  initializingPublishing$: Observable<void>;
  feedCount$: Observable<number>;

  init(sessionId: string): Observable<any> | void;
  startCall$(): Observable<void>;
  endCall$(): Observable<void>;
}
