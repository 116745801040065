import { Directive, EventEmitter, Input, Output } from '@angular/core';

import { ErrorLogger } from '@app/core/models/logger';
import { VideoProvider } from '@app/core/models/video-provider';
import { PageState } from '@app/ui/pipes/page-state.pipe';

export enum CallState {
  Unstarted = 'Call - Unstarted',
  Started = 'Call - Started',
  PatientEnded = 'Call - PatientEnded',
  ProviderEnded = 'Call - ProviderEnded',
  ClaimedByAnother = 'Call - ClaimedByAnother',
  PatientLeft = 'Call - PatientLeft',
  PatientDropped = 'Call - PatientDropped',
}

export enum VideoState {
  Unstarted = 'Video - Unstarted',
  Started = 'Video - Started',
  PatientDropped = 'Video - PatientDropped',
  ProviderAVNotWorking = 'Video - ProviderAVNotWorking',
  StreamingError = 'Video - StreamingError',
}

export enum ErrorMessage {
  StartCallFailed = 'Failed to start call. Please try again.',
  UnstartCallFailed = 'This visit is no longer valid. Please advise the patient to try again.',
  EndCallFailed = 'Failed to end call. Please try again.',
  InitPublisherFailed = 'It appears that your microphone and/or camera are not working or permitted. Please fix the issue and refresh this window, or unclaim the visit.',
  ClaimedByAnotherUser = 'This call was claimed by another person.',
}

export interface ShowErrorOptions {
  error: Error;
  errorMessage?: ErrorMessage | string;
  errorLogger?: ErrorLogger;
}

@Directive()
export abstract class VideoContainerDirective {
  @Input() callState: CallState;
  @Output() callStateChange = new EventEmitter<CallState>();

  @Input() videoState: VideoState;
  @Output() videoStateChange = new EventEmitter<VideoState>();

  @Input() pageState: PageState;
  @Output() pageStateChange = new EventEmitter<PageState>();

  @Input() patientPreferredName: string;

  @Output() intentToStart = new EventEmitter<void>();
  @Output() intentToEnd = new EventEmitter<void>();
  @Output() error = new EventEmitter<ShowErrorOptions>();

  abstract provider: VideoProvider;

  abstract init(sessionId: string): void;

  abstract endCall(): void;

  changeCallState(newState: CallState): void {
    this.callState = newState;
    this.callStateChange.emit(newState);
  }

  changeVideoState(newState: VideoState): void {
    this.videoState = newState;
    this.videoStateChange.emit(newState);
  }

  changePageState(newState: PageState): void {
    this.pageState = newState;
    this.pageStateChange.emit(newState);
  }
}
