import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  readonly environment;
  readonly loggerEndpoint: string =
    'https://endpoint1.collection.us2.sumologic.com/receiver/v1/http/ZaVnC4dhaV2SuCzQZLbLZerXmuvjUAkC9K3rACISSS7bd0Qz4YjrMnElWVQaiZH4-dsse5cCA-pBQPenZVjQBr779lwkulKo6KQpXqfCzS-p3c-SWKjWkQ==';

  readonly zoomEmailMappingsRoute: string;

  constructor() {
    this.environment = environment;
    this.zoomEmailMappingsRoute = `${this.environment.onelifeServer}/site_admin/zoom_email_mappings`;
  }
}
