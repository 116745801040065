import { Component, Input } from '@angular/core';

import { CallState, VideoState } from '@app/core/models/video-container.directive';
import { Status } from '@app/video-container/video-container.component';

enum ButtonText {
  Ready = 'Start Visit',
  End = 'End Visit',
  Quit = 'Close Window',
}

@Component({
  selector: 'om-action-button',
  templateUrl: './action-button.component.html',
})
export class ActionButtonComponent {
  @Input() set status(input: Status) {
    this.setText(input);
  }

  @Input() disabled = false;

  text: string;

  private readonly buttonText = {
    [CallState.Unstarted]: ButtonText.Ready,
    [CallState.Started]: ButtonText.End,
    [CallState.ClaimedByAnother]: ButtonText.Quit,
    [CallState.PatientLeft]: ButtonText.Quit,
    [CallState.PatientDropped]: ButtonText.Quit,
    [VideoState.ProviderAVNotWorking]: ButtonText.Quit,
    [VideoState.StreamingError]: ButtonText.Quit,
  };

  private setText(input: Status) {
    this.text = this.buttonText[input.videoState] || this.buttonText[input.callState];
  }
}
