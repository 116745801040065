import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import isObject from 'lodash-es/isObject';

import { ErrorLogger } from '@app/core/models/logger';

export interface Extras extends Record<string, unknown> {
  operation?: {
    operationName: string;
  };
  networkError?: {
    status: number;
  };
}

export class ErrorWithExtras extends Error {
  readonly extras: Extras;

  constructor(message: string, extras: Extras) {
    super(message);
    this.extras = extras;
  }
}

export function hasExtras(error: any): error is ErrorWithExtras {
  return isObject(error) && 'extras' in error;
}

@Injectable({
  providedIn: 'root',
})
export class ErrorService implements ErrorLogger {
  error(error: Error): void {
    const extra = hasExtras(error) ? error.extras : null;
    Sentry.captureException(error, { extra });
  }
}
