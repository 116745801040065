<nav class="d-flex align-items-center">
  <a class="item logo" [href]="links.home">
    <omgui-svg-om-logo></omgui-svg-om-logo>
  </a>
  <a class="item text" [href]="links.tasks"><strong>TASKS</strong></a>
  <div ngbDropdown #apptDrop="ngbDropdown" (openChange)="setAppointmentMenuActive($event)">
    <div
      ngbDropdownToggle
      class="item text selected"
      [class.dropdown-active]="appointmentMenuActive"
      tabindex="0"
      (keydown.enter)="apptDrop.toggle()"
    >
      <strong class="dropdown-toggle-text ">APPOINTMENTS</strong>
    </div>
    <div ngbDropdownMenu class="menu">
      <a ngbDropdownItem class="menu-item" [href]="links.appointments">Appointments</a>
      <a ngbDropdownItem class="menu-item" [href]="links.providerSchedule">Provider Schedule</a>
      <a ngbDropdownItem class="menu-item" [href]="links.walkInVisits">Walk In Visits</a>
      <a ngbDropdownItem class="menu-item" [href]="links.officeSchedule">Office Schedule</a>
      <a ngbDropdownItem class="menu-item" [href]="links.virtualVisits">Virtual Visits</a>
    </div>
  </div>
  <a class="item text" [href]="links.docs"><strong>DOCS</strong></a>
  <a class="item text" [href]="links.faxes"><strong>FAXES</strong></a>
  <a class="item text" [href]="links.contacts"><strong>CONTACTS</strong></a>
  <a class="item text" [href]="links.providers"><strong>PROVIDERS</strong></a>
  <div
    class="user-menu d-flex justify-content-end"
    ngbDropdown
    #userDrop="ngbDropdown"
    placement="bottom-right"
    (openChange)="setUserMenuActive($event)"
  >
    <div
      ngbDropdownToggle
      class="item text"
      [class.dropdown-active]="userMenuActive"
      tabindex="0"
      (keydown.enter)="userDrop.toggle()"
    >
      <strong class="dropdown-toggle-text">{{ name | uppercase }}</strong>
    </div>
    <div ngbDropdownMenu class="item menu">
      <div ngbDropdownItem class="menu-item" (click)="logoutClicked.emit()">Log Out</div>
    </div>
  </div>
</nav>
