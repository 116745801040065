import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import {
  initialize as initializeLDClient,
  LDClient,
  LDFlagChangeset,
  LDFlagSet,
  LDOptions,
  LDSingleKindContext,
} from 'launchdarkly-js-client-sdk';
import mapValues from 'lodash-es/mapValues';
import { BehaviorSubject, combineLatest, from, Observable, of as observableOf, ReplaySubject } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { ErrorService } from '@app/core/error.service';

import { CurrentUserForLaunchDarklyGQL } from '../../generated/graphql';
import { ConfigService } from './config.service';
import { LoggerService } from './logger.service';

export const CURRENT_USER_FOR_LAUNCH_DARKLY = gql`
  query CurrentUserForLaunchDarkly {
    internalUser {
      id
      firstName
      lastName
      email
    }
  }
`;

type FlagEvaluations = LDFlagSet;

class LaunchDarklyInitFailed extends Error {
  constructor(public client: LDClient) {
    super();
  }
}

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private ldClient$ = new ReplaySubject<LDClient>(1);
  private flagEvaluations$ = new BehaviorSubject<FlagEvaluations>({});

  constructor(
    private config: ConfigService,
    private currentUserForLaunchDarklyGQL: CurrentUserForLaunchDarklyGQL,
    private loggerService: LoggerService,
    private errorService: ErrorService,
  ) {}

  init(): void {
    this.currentUserForLaunchDarklyGQL
      .fetch()
      .pipe(
        switchMap(result => {
          const { id, firstName, lastName, email } = result.data.internalUser;
          const context: LDSingleKindContext = {
            kind: 'user',
            key: id,
            firstName,
            lastName,
            email,
            application: 'virtual-visits-dashboard',
          };

          const options: LDOptions = {
            sendEventsOnlyForVariation: true,
          };

          const client = initializeLDClient(this.config.environment.launchDarklyClientId, context, options);

          return from(client.waitForInitialization()).pipe(
            catchError(error => {
              this.loggerService.log('Unable to initialize the LaunchDarkly SDK. Flagged features will be disabled');
              this.errorService.error(error);

              return observableOf(client);
            }),
            map(() => client),
          );
        }),
        tap((client: LDClient) => {
          this.ldClient$.next(client);
          this.ldClient$.complete();

          client.on('change', (flagChangeset: LDFlagChangeset) => {
            this.flagEvaluations$.next(mapValues(flagChangeset, 'current'));
          });
        }),
      )
      .subscribe();
  }

  evaluate$<T>(flag: string, defaultValue?: T): Observable<T> {
    return combineLatest([this.ldClient$, this.flagEvaluations$]).pipe(
      map(([client, flags]) => {
        if (flag in flags) {
          return flags[flag];
        } else {
          return client.variation(flag, defaultValue);
        }
      }),
    );
  }
}
