import { Injectable } from '@angular/core';

import { LocalStorage } from '@app/core/storage';
import { StorageService } from '@app/core/storage-service.interface';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService implements StorageService {
  storage: LocalStorage;

  constructor() {
    this.storage = new LocalStorage();
  }

  getItem(key) {
    return this.storage.getItem(key);
  }

  setItem(key, value) {
    this.storage.setItem(key, value);
  }

  removeItem(key) {
    this.storage.removeItem(key);
  }

  clear(): void {
    this.storage.clear();
  }
}
