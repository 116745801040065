import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo, gql } from 'apollo-angular';
import { from, Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { FirebaseToken } from '@app/core/firebase/__generated__/FirebaseToken';
import { RealtimeDbAuthService } from '@app/core/models/realtime-db';

import { ClearStorageService } from '../clear-storage.service';

export const GET_FIREBASE_TOKEN_QUERY = gql`
  query FirebaseToken {
    firebase {
      token
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class FirebaseAuthService implements RealtimeDbAuthService {
  loggingOut$ = new Subject<void>();

  constructor(
    private apollo: Apollo,
    private fireAuth: AngularFireAuth,
    private clearStorageService: ClearStorageService,
  ) {}

  get isAuthenticated(): Observable<boolean> {
    return this.fireAuth.authState.pipe(map(response => !!response && !!response.uid));
  }

  login(): Observable<boolean> {
    return this.apollo.query({ query: GET_FIREBASE_TOKEN_QUERY }).pipe(
      switchMap((result: ApolloQueryResult<FirebaseToken>) => {
        const token = result.data.firebase.token;
        return from(this.fireAuth.signInWithCustomToken(token));
      }),
      map(_ => true),
    );
  }

  logout() {
    this.loggingOut$.next();
    this.loggingOut$.complete();

    this.clearStorageService.clearAll();

    this.fireAuth.signOut();
  }
}
