import { Injectable } from '@angular/core';

import { LocalStorageService } from '@app/core/local-storage.service';

export interface WindowProperties {
  width: number;
  height: number;
  left: number;
  top: number;
}

export class MissingDefaultWindowProperties extends Error {
  constructor(key: string) {
    super(`Default window properties for ${key} were not provided.`);
    this.name = MissingDefaultWindowProperties.name;
  }
}

@Injectable({
  providedIn: 'root',
})
export class WindowPropertiesService {
  static WindowPropertiesKeyPrefix = 'windowProperties';

  constructor(private storage: LocalStorageService) {}

  saveProperties(key: string, properties: WindowProperties) {
    this.storage.setItem(this.appendServiceKey(key), JSON.stringify(properties));
  }

  getPropertiesForDOM(key: string, defaultProperties: WindowProperties): string {
    if (!defaultProperties) {
      throw new MissingDefaultWindowProperties(key);
    }

    const storedProperties = this.storage.getItem(this.appendServiceKey(key));
    if (storedProperties === undefined) {
      return this.convertToDomString(defaultProperties);
    } else {
      return this.convertToDomString(JSON.parse(storedProperties));
    }
  }

  private appendServiceKey(key: string): string {
    return `${WindowPropertiesService.WindowPropertiesKeyPrefix}:${key}`;
  }

  private convertToDomString(windowProperties: WindowProperties): string {
    const { left, height, width, top } = windowProperties;
    return `width=${width}, height=${height}, left=${left}, top=${top}`;
  }
}
