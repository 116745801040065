import { Component, OnInit } from '@angular/core';

import { OAuthService } from '@app/core/o-auth/o-auth.service';

import { FeatureFlagService } from './core/feature-flag.service';

@Component({
  selector: 'om-root',
  templateUrl: './app.component.html',
  styleUrls: [],
})
export class AppComponent implements OnInit {
  constructor(private featureFlagService: FeatureFlagService, private oAuthService: OAuthService) {}

  ngOnInit(): void {
    if (this.oAuthService.isAuthenticated) {
      this.featureFlagService.init();
    }
  }
}
