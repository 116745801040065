import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { QueryFn } from '@angular/fire/compat/firestore/interfaces';
import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo, gql } from 'apollo-angular';
import firebase from 'firebase/compat/app';
import { subscribe } from 'graphql';
import 'firebase/compat/firestore';
import cloneDeep from 'lodash-es/cloneDeep';
import pick from 'lodash-es/pick';
import sortBy from 'lodash-es/sortBy';
import {
  combineLatest,
  from,
  MonoTypeOperatorFunction,
  noop,
  Observable,
  ObservedValueOf,
  of as observableOf,
  OperatorFunction,
  throwError as observableThrowError,
} from 'rxjs';
import { catchError, filter, map, share, switchMap, take, tap } from 'rxjs/operators';

import { ErrorWithExtras, Extras } from '@app/core/error.service';
import { FirebaseNamespace } from '@app/core/firebase/__generated__/FirebaseNamespace';
import { FirestoreActionType, LoggerService, potentialChanges, VisitChanges } from '@app/core/logger.service';
import { ID } from '@app/core/models/id';
import { VirtualVisitStore } from '@app/core/models/realtime-db';
import {
  EndCallReason,
  TIME_FIELDS,
  UnhydratedVirtualVisit,
  VideoCallType,
  VirtualVisitEndedBy,
  VirtualVisitForCreate,
  VirtualVisitForUpdate,
  VirtualVisitState,
} from '@app/core/models/unhydrated-virtual-visit';
import { VirtualVisit } from '@app/core/models/virtual-visit';
import { ZoomService } from '@app/core/zoom/zoom.service';

import Transaction = firebase.firestore.Transaction;
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import FirebaseError = firebase.FirebaseError;
import GetOptions = firebase.firestore.GetOptions;
import Timestamp = firebase.firestore.Timestamp;

export type FirestoreMeta = Record<string, unknown>;

export interface RawVirtualVisit {
  id: string;
  visitState: VirtualVisitState;
  queuedBy: ID;
  queuedAt: firebase.firestore.Timestamp;
  licensingBody?: string;
  openTokSessionId?: string;
  zoomMeetingId?: string;
  reasonForVisit?: string;
  claimedAt?: firebase.firestore.Timestamp;
  claimedBy?: ID;
  startedAt?: firebase.firestore.Timestamp;
  meta?: FirestoreMeta;
  callbackRequested?: boolean;
  endedAt?: firebase.firestore.Timestamp;
  endedBy?: VirtualVisitEndedBy;
}

export const GET_FIREBASE_NAMESPACE = gql`
  query FirebaseNamespace {
    firebase {
      namespace
    }
  }
`;

export class FirebasePermissionsError extends ErrorWithExtras {
  constructor(message: string, extras: Extras) {
    super(message, extras);
    this.name = FirebasePermissionsError.name;
  }

  static isFirebasePermissionsRelatedError(error: Error): boolean {
    return (<FirebaseError>error).code === 'permission-denied' && error.name === 'FirebaseError';
  }
}

export class CallNotClaimableError extends Error {
  name = CallNotClaimableError.name;
}

const getFromServer: GetOptions = {
  source: 'server',
};

@Injectable({
  providedIn: 'root',
})
export class FirestoreService implements VirtualVisitStore<firebase.firestore.FieldValue> {
  constructor(
    private ngFirestore: AngularFirestore,
    private apollo: Apollo,
    private zoomService: ZoomService,
    private logger: LoggerService,
  ) {}

  get(states: VirtualVisitState[]): Observable<UnhydratedVirtualVisit[]> {
    const queryFn = ref => ref.where('visitState', 'in', states);

    return this.virtualVisits$(queryFn).pipe(
      switchMap((collection: AngularFirestoreCollection<RawVirtualVisit>) => {
        return collection.valueChanges({ idField: 'id' });
      }),
      map((rawVisits: RawVirtualVisit[]) => {
        const transformedVisits = rawVisits.map(rawVisit => this.transformRawVisit(rawVisit));

        return sortBy(transformedVisits, ['queuedAt']);
      }),
    );
  }

  getCall(id: ID): Observable<UnhydratedVirtualVisit | undefined> {
    return this.virtualVisits$().pipe(
      switchMap((collection: AngularFirestoreCollection<RawVirtualVisit>) =>
        collection.doc<RawVirtualVisit>(id).valueChanges(),
      ),
      map(rawVisit => {
        if (!rawVisit) {
          return;
        }

        rawVisit.id = id;
        return this.transformRawVisit(rawVisit);
      }),
    );
  }

  create(virtualVisit: VirtualVisitForCreate<firebase.firestore.FieldValue>): Observable<boolean> {
    return this.actionToObservable(collection =>
      collection
        .add(virtualVisit as RawVirtualVisit)
        .then((doc: DocumentSnapshot<RawVirtualVisit>) =>
          this.logger.logAction({
            action: FirestoreActionType.Create,
            document: this.formatFieldValues({ id: doc.id, ...virtualVisit }),
          }),
        )
        .catch(error => this.logger.logAction({ action: FirestoreActionType.Create, error })),
    );
  }

  update(
    virtualVisit: VirtualVisitForUpdate<firebase.firestore.FieldValue>,
    updateType: FirestoreActionType = FirestoreActionType.Update,
  ): Observable<boolean> {
    const { id, ...visit } = virtualVisit;
    let oldDoc: RawVirtualVisit;

    return this.actionToObservable((collection: AngularFirestoreCollection<RawVirtualVisit>) =>
      this.ngFirestore.firestore
        .runTransaction((transaction: Transaction) => {
          const ref = collection.doc(id).ref;
          return transaction.get(ref).then((document: DocumentSnapshot<RawVirtualVisit>) => {
            oldDoc = document.data();
            transaction.update(ref, visit as RawVirtualVisit);
          });
        })
        .then(_ => {
          const newDoc: RawVirtualVisit = this.formatFieldValues({
            ...oldDoc,
            ...virtualVisit,
          });
          const changes = this.stateChangePresenter(oldDoc, newDoc);
          this.logger.logAction({ action: updateType, id, changes, document: newDoc });
        })
        .catch(error => this.logger.logAction({ action: updateType, id, error })),
    );
  }

  delete(id: ID): Observable<boolean> {
    return this.actionToObservable((collection: AngularFirestoreCollection<RawVirtualVisit>) =>
      collection
        .doc(id)
        .delete()
        .then(_ => this.logger.logAction({ action: FirestoreActionType.Delete, id }))
        .catch(error => this.logger.logAction({ action: FirestoreActionType.Delete, id, error })),
    );
  }

  claimCall(virtualVisit: VirtualVisit, claimedBy: ID): Observable<VirtualVisit> {
    const claimedVirtualVisit = cloneDeep(virtualVisit);

    return this.virtualVisits$().pipe(
      this.getVisitFromServer(virtualVisit.id),
      this.validateVisitStateIsClaimable(),
      switchMap((document: DocumentSnapshot<RawVirtualVisit>) => {
        const visit: RawVirtualVisit = document.data();
        const visitState = visit.callbackRequested ? VirtualVisitState.InProgress : VirtualVisitState.Claimed;

        const visitForUpdate: VirtualVisitForUpdate<firebase.firestore.FieldValue> = {
          id: visit.id,
          visitState,
          claimedAt: this.serverTimeNow(),
          claimedBy: claimedBy,
        };

        let visitForUpdate$: Observable<VirtualVisitForUpdate<firebase.firestore.FieldValue>>;
        if (this.isOpenTokRawVisit(visit) || visit.callbackRequested) {
          visitForUpdate$ = observableOf(visitForUpdate);
        } else {
          visitForUpdate$ = this.zoomService.createMeeting().pipe(
            tap(meetingId => (claimedVirtualVisit.sessionId = meetingId)),
            map(meetingId => {
              visitForUpdate.zoomMeetingId = meetingId;
              visitForUpdate.callbackRequested = false;
              return visitForUpdate;
            }),
          );
        }

        return combineLatest([visitForUpdate$, observableOf(document)]);
      }),
      switchMap(
        ([visit, document]: [
          VirtualVisitForUpdate<firebase.firestore.FieldValue>,
          DocumentSnapshot<RawVirtualVisit>,
        ]) => {
          const { id, ...updates } = visit;
          const oldDoc: RawVirtualVisit = document.data();
          return from(
            document.ref
              .update(updates)
              .then(_ => {
                const newDoc: RawVirtualVisit = this.formatFieldValues({ ...oldDoc, ...visit });
                this.logger.logAction({
                  action: FirestoreActionType.ClaimCall,
                  id: virtualVisit.id,
                  changes: this.stateChangePresenter(oldDoc, newDoc),
                  document: newDoc,
                });
              })
              .catch(error => {
                this.logger.logAction({
                  action: FirestoreActionType.ClaimCall,
                  id: virtualVisit.id,
                  document: this.formatFieldValues(oldDoc),
                  error,
                });
              }),
          );
        },
      ),
      map(_ => claimedVirtualVisit),
      this.clarifyFirebasePermissionErrors('claim call', virtualVisit.id),
    );
  }

  endCall(id: ID, reason?: EndCallReason): Observable<boolean> {
    let oldDoc: RawVirtualVisit;
    const visitState = reason === EndCallReason.PatientMissedCall ? VirtualVisitState.Failed : VirtualVisitState.Ended;

    const endedState = {
      visitState,
      endedAt: this.serverTimeNow(),
      endedBy: VirtualVisitEndedBy.InternalUser,
    };

    return this.actionToObservable((collection: AngularFirestoreCollection<RawVirtualVisit>) =>
      this.ngFirestore.firestore
        .runTransaction((transaction: Transaction) => {
          const ref = collection.doc(id).ref;
          return transaction.get(ref).then((document: DocumentSnapshot<RawVirtualVisit>) => {
            oldDoc = document.data();
            if (document.data().visitState === VirtualVisitState.InProgress) {
              transaction.update(ref, endedState);
            }
          });
        })
        .then(_ => {
          const newDoc: RawVirtualVisit = this.formatFieldValues({
            ...oldDoc,
            ...endedState,
          });
          this.logger.logAction({
            action: FirestoreActionType.EndCall,
            id,
            changes: this.stateChangePresenter(oldDoc, newDoc),
            document: newDoc,
          });
        }),
    ).pipe(
      catchError(error =>
        combineLatest([
          this.checkCallEndedSuccessfullyByPatient(id),
          observableOf(FirebasePermissionsError.isFirebasePermissionsRelatedError(error)),
        ]).pipe(
          switchMap(([isSuccessfullyEnded, isFirebasePermissionError]) => {
            if (isSuccessfullyEnded && isFirebasePermissionError) {
              this.logger.logAction({
                action: FirestoreActionType.EndCall,
                id,
                document: oldDoc,
              });
              return observableOf(true);
            } else {
              this.logger.logAction({
                action: FirestoreActionType.EndCall,
                id,
                document: oldDoc,
                error,
              });
              return observableThrowError(error);
            }
          }),
        ),
      ),
      this.clarifyFirebasePermissionErrors('end call', id),
    );
  }

  startCall(id: ID): Observable<boolean> {
    let oldDoc: RawVirtualVisit;
    const startedState = {
      visitState: VirtualVisitState.InProgress,
      startedAt: this.serverTimeNow(),
    };

    return this.actionToObservable((collection: AngularFirestoreCollection<RawVirtualVisit>) =>
      this.ngFirestore.firestore.runTransaction((transaction: Transaction) => {
        const ref = collection.doc(id).ref;
        return new Promise<void>((resolve, reject) =>
          transaction.get(ref).then((document: DocumentSnapshot<RawVirtualVisit>) => {
            oldDoc = document.data();
            if (oldDoc.visitState === VirtualVisitState.Claimed) {
              transaction.update(ref, startedState);
              const newDoc: RawVirtualVisit = this.formatFieldValues({
                ...oldDoc,
                visitState: startedState.visitState,
                startedAt: Timestamp.now(),
              });
              this.logger.logAction({
                action: FirestoreActionType.StartCall,
                id,
                changes: this.stateChangePresenter(oldDoc, newDoc),
                document: newDoc,
              });
              resolve();
            } else {
              this.logger.logAction({
                action: FirestoreActionType.StartCall,
                id,
                document: this.formatFieldValues(oldDoc),
                error: 'Call cannot be started because it is not claimed',
              });
              reject();
            }
          }),
        );
      }),
    ).pipe(
      this.clarifyFirebasePermissionErrors('start call', id),
      catchError(error => {
        this.logger.logAction({
          action: FirestoreActionType.StartCall,
          id,
          error,
          document: oldDoc,
        });
        return observableThrowError(error);
      }),
    );
  }

  unstartCall(id: ID): Observable<boolean> {
    return this.update(
      {
        id: id,
        visitState: VirtualVisitState.Claimed,
        startedAt: this.deleteFieldType(),
      },
      FirestoreActionType.UnstartCall,
    ).pipe(this.clarifyFirebasePermissionErrors('unstart call', id));
  }

  serverTimeNow(): firebase.firestore.FieldValue {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  timeFieldFromDate(date: Date): firebase.firestore.FieldValue {
    return firebase.firestore.Timestamp.fromDate(date);
  }

  deleteFieldType(): firebase.firestore.FieldValue {
    return firebase.firestore.FieldValue.delete();
  }

  unclaimCall(id: ID): Observable<boolean> {
    return this.update(
      {
        id: id,
        visitState: VirtualVisitState.Queued,
        claimedAt: this.deleteFieldType(),
        claimedBy: this.deleteFieldType(),
        zoomMeetingId: this.deleteFieldType(),
      },
      FirestoreActionType.UnclaimCall,
    ).pipe(
      switchMap(_ => this.updateMetadata(id, { hostId: undefined, meetingUuid: undefined })),
      this.clarifyFirebasePermissionErrors('unclaim call', id),
    );
  }

  callEnded$(visitId: string): Observable<void> {
    return this.callStateChangedTo$(visitId, [VirtualVisitState.Ended, VirtualVisitState.Cancelled]);
  }

  callFailed$(visitId: ID): Observable<void> {
    return this.callStateChangedTo$(visitId, [VirtualVisitState.Failed]);
  }

  callTerminatedUnexpectedly$(visitId: ID): Observable<void> {
    return this.callStateChangedTo$(visitId, [VirtualVisitState.Failed, VirtualVisitState.Cancelled]);
  }

  callChangedFromClaimed$(visitId: ID): Observable<void> {
    return this.callStateChangedTo$(visitId, [VirtualVisitState.Queued, VirtualVisitState.InProgress]);
  }

  updateMetadata(id: ID, meta: FirestoreMeta): Observable<boolean> {
    return this.actionToObservable((collection: AngularFirestoreCollection<RawVirtualVisit>) =>
      this.ngFirestore.firestore.runTransaction((transaction: Transaction) => {
        const ref = collection.doc(id).ref;
        return transaction.get(ref).then((document: DocumentSnapshot<RawVirtualVisit>) => {
          const mergedMeta = {
            ...document.data().meta,
            ...meta,
          };

          for (const key in mergedMeta) {
            if (mergedMeta[key] === undefined) {
              delete mergedMeta[key];
            }
          }

          transaction.update(ref, { meta: mergedMeta });
        });
      }),
    ).pipe(this.clarifyFirebasePermissionErrors('update metadata', id));
  }

  private stateChangePresenter(originalVisit: RawVirtualVisit, visitUpdates: RawVirtualVisit): VisitChanges {
    const changes: VisitChanges = {};

    potentialChanges.forEach(prop => {
      let prev = originalVisit[prop];
      let next = visitUpdates[prop];

      if (TIME_FIELDS.includes(prop)) {
        prev = this.formatTimeType(prev);
        next = this.formatTimeType(next);
      }

      if (prev !== next) {
        changes[prop] = { previous: prev, next: next };
      }
    });
    return changes;
  }

  private formatTimeType(timeField) {
    if (timeField?.seconds && timeField?.nanoseconds) {
      return new Timestamp(timeField.seconds, timeField.nanoseconds).toDate().toLocaleString();
    } else if (this.isServerTimeStamp(timeField)) {
      return new Date().toLocaleString();
    } else {
      return timeField;
    }
  }

  private formatFieldValues(visit: VirtualVisitForUpdate<firebase.firestore.FieldValue>): RawVirtualVisit {
    Object.keys(visit)
      .filter(key => TIME_FIELDS.includes(key))
      .forEach(key => (visit[key] = this.formatTimeType(visit[key])));
    return visit as RawVirtualVisit;
  }

  private isServerTimeStamp(value): boolean {
    return value?._delegate?._methodName === 'FieldValue.serverTimestamp';
  }

  private callStateChangedTo$(visitId: ID, visitStates: VirtualVisitState[]): Observable<void> {
    return this.virtualVisits$().pipe(
      switchMap((collection: AngularFirestoreCollection<RawVirtualVisit>) => collection.doc(visitId).valueChanges()),
      filter((visit: RawVirtualVisit) => visitStates.includes(visit.visitState)),
      map(_ => undefined),
      take(1),
    );
  }

  private checkCallEndedSuccessfullyByPatient(visitId: ID): Observable<boolean> {
    return this.virtualVisits$().pipe(
      switchMap((collection: AngularFirestoreCollection<RawVirtualVisit>) => collection.doc(visitId).get()),
      map((doc: DocumentSnapshot<RawVirtualVisit>) => this.transformRawVisit(doc.data())),
      map((visit: UnhydratedVirtualVisit) => {
        return visit.visitState === VirtualVisitState.Ended && visit.endedBy === VirtualVisitEndedBy.Patient;
      }),
    );
  }

  private transformRawVisit(rawVisit): UnhydratedVirtualVisit {
    const visit = {
      ...pick(rawVisit, [
        'id',
        'visitState',
        'licensingBody',
        'reasonForVisit',
        'queuedBy',
        'claimedBy',
        'endedBy',
        'meta',
        'callbackRequested',
      ]),
    } as UnhydratedVirtualVisit;

    TIME_FIELDS.forEach(field => {
      if (rawVisit[field]) {
        visit[field] = rawVisit[field].toDate();
      }
    });

    if (this.isOpenTokRawVisit(rawVisit)) {
      visit.videoCallType = VideoCallType.OpenTok;
      visit.sessionId = rawVisit.openTokSessionId;
    } else {
      visit.videoCallType = VideoCallType.Zoom;
      visit.sessionId = rawVisit.zoomMeetingId;
    }

    return visit;
  }

  private actionToObservable(action: (collection) => Promise<void>, queryFn?: QueryFn): Observable<boolean> {
    const observable = this.virtualVisits$(queryFn).pipe(
      switchMap(collection => from(action(collection))),
      map(_ => true),
      share(),
    );

    observable.subscribe({ error: noop });

    return observable;
  }

  private virtualVisits$(queryFn?: QueryFn): Observable<AngularFirestoreCollection<RawVirtualVisit>> {
    return this.apollo.query({ query: GET_FIREBASE_NAMESPACE }).pipe(
      map((result: ApolloQueryResult<FirebaseNamespace>) => {
        const path = `${result.data.firebase.namespace}/virtualVisitsService/virtualVisits`;
        return this.ngFirestore.collection<RawVirtualVisit>(path, queryFn);
      }),
    );
  }

  private clarifyFirebasePermissionErrors<T>(
    operationName: string,
    docId: string,
  ): OperatorFunction<T, ObservedValueOf<Observable<never>> | T> {
    return catchError((error: Error) => {
      if (FirebasePermissionsError.isFirebasePermissionsRelatedError(error)) {
        const enhancedError = new FirebasePermissionsError(`Denied operation - ${operationName}`, { docId });
        return observableThrowError(enhancedError);
      } else {
        return observableThrowError(error);
      }
    });
  }

  private isOpenTokRawVisit(rawVisit: RawVirtualVisit): boolean {
    return !!rawVisit.openTokSessionId;
  }

  private getVisitFromServer(
    docId: string,
  ): OperatorFunction<
    AngularFirestoreCollection<RawVirtualVisit>,
    ObservedValueOf<Observable<firebase.firestore.DocumentSnapshot<RawVirtualVisit>>>
  > {
    return switchMap((collection: AngularFirestoreCollection<RawVirtualVisit>) =>
      collection.doc(docId).get({ source: 'server' }),
    );
  }

  private validateVisitStateIsClaimable(): MonoTypeOperatorFunction<DocumentSnapshot<RawVirtualVisit>> {
    return tap((document: DocumentSnapshot<RawVirtualVisit>) => {
      if (document.data().visitState !== VirtualVisitState.Queued) {
        throw new CallNotClaimableError();
      }
    });
  }
}
