import { Injectable } from '@angular/core';

import { CookieStorage } from '@app/core/storage';
import { StorageDuration, StorageService } from '@app/core/storage-service.interface';

@Injectable({
  providedIn: 'root',
})
export class CookieStorageService implements StorageService {
  storage: CookieStorage;

  constructor() {
    this.storage = new CookieStorage();
  }

  getItem(key) {
    return this.storage.getItem(key);
  }

  setItem(key, value, storageDuration?: StorageDuration) {
    this.storage.setItem(key, value, storageDuration);
  }

  removeItem(key) {
    this.storage.removeItem(key);
  }

  clear() {
    this.storage.clear();
  }
}
