import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ErrorService } from '@app/core/error.service';
import { RealtimeDbService } from '@app/core/realtime-db.service';

@Injectable({
  providedIn: 'root',
})
export class RealtimeDbAuthGuard implements CanActivate {
  constructor(
    private realtimeDbService: RealtimeDbService,
    private errorService: ErrorService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return new Observable(subscriber => {
      this.realtimeDbService.auth.isAuthenticated.subscribe(isAuthenticated => {
        if (isAuthenticated) {
          subscriber.next(true);
        } else {
          this.realtimeDbService.auth
            .login()
            .pipe(
              catchError(error => {
                this.errorService.error(error);
                return observableOf(this.router.parseUrl('/errors/unknown'));
              }),
            )
            .subscribe(subscriber);
        }
      });
    });
  }
}
