// Adapted from https://stackoverflow.com/a/42755876/855201

export class WrappedError extends Error {
  original: Error;
  newStack: string;

  constructor(name: string, message: string, error: Error) {
    super(message);

    this.name = name;
    this.message = message;

    Error.captureStackTrace(this, this.constructor);

    this.original = error;
    this.newStack = this.stack;

    const messageLines = (this.message.match(/\n/g) || []).length + 1;
    this.stack = this.stack.split('\n').slice(0, messageLines).join('\n') + '\n' + error.stack;
  }
}
