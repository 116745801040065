import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';

import { WindowProperties } from '@app/core/window-properties.service';

export interface RedirectOptions {
  newTab?: boolean;
  extras?: string;
}

declare global {
  interface Window {
    redirect: (location: string) => void;
  }
}

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  crypto = window.crypto;

  redirect(location: string, options: RedirectOptions = { extras: '' }) {
    // Adding a method on window that redirects allows us to stub the redirect functionality in
    // tests, allowing us to test external redirects
    if (!window.redirect) {
      window.redirect = loc => {
        window.location.href = loc;
      };
    }

    const { newTab, extras } = options;
    if (newTab) {
      window.open(location, '_blank', extras);
    } else {
      window.redirect(location);
    }
  }

  reload() {
    window.location.reload();
  }

  close(): void {
    window.close();
  }

  binaryToBase64(input: string): string {
    return window.btoa(input);
  }

  getCurrentWindowProperties(): WindowProperties {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
      left: window.screenLeft,
      top: window.screenTop,
    };
  }

  getEvent$(event: string): Observable<unknown> {
    return fromEvent(window, event);
  }

  dispatchEvent(event: CustomEvent) {
    window.dispatchEvent(event);
  }

  notificationsSupported(): boolean {
    return 'Notification' in window;
  }

  get Notification(): { permission: NotificationPermission; requestPermission: () => Promise<NotificationPermission> } {
    return Notification;
  }
}
