import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { combineLatest } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { BroadcastableChannel, BroadcastChannelService } from '@app/core/broadcast-channel.service';
import { CallState, VideoContainerDirective, VideoState } from '@app/core/models/video-container.directive';
import { WindowService } from '@app/core/window.service';
import { MeetingConfig, ZoomService } from '@app/core/zoom/zoom.service';
import { PageState } from '@app/ui/pipes/page-state.pipe';

export interface CleanupZoomMessage {
  meetingId: string;
}

@Component({
  selector: 'om-zoom',
  templateUrl: './zoom.component.html',
  styleUrls: ['./zoom.component.scss'],
})
export class ZoomComponent extends VideoContainerDirective {
  static readonly CLEANUP_ZOOM_CHANNEL = 'cleanup-zoom-channel';

  @ViewChild('zoom') zoomFrame: ElementRef;

  readonly PageState = PageState;
  readonly CallState = CallState;
  readonly VideoState = VideoState;

  private gracefullyEnded = false;
  private cleanupZoomChannel: BroadcastableChannel;
  private meetingId: string;

  constructor(
    public provider: ZoomService,
    private windowService: WindowService,
    private broadcastChannelService: BroadcastChannelService,
  ) {
    super();

    this.cleanupZoomChannel = broadcastChannelService.createBroadcastChannel(ZoomComponent.CLEANUP_ZOOM_CHANNEL);
  }

  init(meetingId: string): void {
    this.meetingId = meetingId;

    this.windowService
      .getEvent$('zoom-ended')
      .pipe(
        tap(() => (this.gracefullyEnded = true)),
        take(1),
      )
      .subscribe({
        next: () => {
          this.intentToEnd.emit();
        },
      });

    combineLatest([this.provider.init(meetingId), this.windowService.getEvent$('zoom-loaded').pipe(take(1))]).subscribe(
      {
        next: ([meetingConfig, _]) => {
          this.changeCallState(CallState.Started);
          this.changePageState(PageState.SUCCESS);
          this.changeVideoState(VideoState.Started);

          const event = new CustomEvent<MeetingConfig>('zoom-initialize', { detail: meetingConfig });
          this.windowService.dispatchEvent(event);
        },
      },
    );
  }

  endCall(): void {
    this.provider.endCall$().subscribe({
      next: () => this.windowService.close(),
      complete: () => this.windowService.close(),
    });
  }

  @HostListener('window:unload')
  checkGracefullyEnded(): void {
    if (this.gracefullyEnded || !this.meetingId) {
      return;
    }

    const message: CleanupZoomMessage = { meetingId: this.meetingId };
    this.cleanupZoomChannel.postMessage(message);
  }
}
